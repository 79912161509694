var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":_vm.isMobile,"max-width":!_vm.isMobile ? '600px' : '',"transition":"dialog-bottom-transition","scrollable":!_vm.isMobile,"persistent":!_vm.isMobile},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":_vm.isMobile}},[(_vm.isMobile)?_c('v-toolbar',{staticStyle:{"position":"sticky","top":"0px","z-index":"20"},attrs:{"color":"#e0a677","tile":""}},[(_vm.edit)?_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Редактировние категории")]):_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Новая категории")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_c('v-card-title',[(_vm.edit)?_c('span',{staticClass:"headline"},[_vm._v("Редактировние категории")]):_c('span',{staticClass:"headline"},[_vm._v("Новая категории")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"#e0a677"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',{class:{ 'pa-0': _vm.isMobile }},[_c('v-form',{ref:"formCategory"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"color":"#e0a677","label":"Название категории*","rules":_vm.$fieldRules.field,"required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.userRoles,"label":"Доступна для","multiple":"","color":"#e0a677","item-color":"#e0a677"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 3)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.roleText[item]))]),(_vm.form.available && index < _vm.form.available.length - 1)?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()]):_vm._e(),(index === 3)?_c('span',[_vm._v(" (+"+_vm._s(_vm.form.available.length - 3)+") ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"#e0a677","ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(_vm.roleText[item])+" ")])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.form.available),callback:function ($$v) {_vm.$set(_vm.form, "available", $$v)},expression:"form.available"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.save()}}},[(_vm.edit)?_c('span',[_vm._v("Сохранить")]):_c('span',[_vm._v("Создать")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }