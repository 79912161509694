<template>
  <div>
    <MobPage v-if="isMobile" />
    <PcPage v-else />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
const MobPage = () => import('./MobPage.vue')
const PcPage = () => import('./PcPage.vue')

export default {
  name: 'ManufactoryArchiveTasksForMaster',
  components: {
    MobPage,
    PcPage
  },
  data: () => ({
    isMobile: isMobile
  }),
  created() {
    if (!this.tasks || this.tasks.length === 0) {
      this.$store.dispatch('manufactory/loadTasksArchiveForMaster', { uid: this.user.uid })
    }
  },
  computed: {
    tasks() {
      return this.$store.getters['manufactory/getTasksArchiveForMaster']
    },
    user() {
      return this.$store.getters['user/getUser']
    }
  }
}
</script>
