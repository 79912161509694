import { tasksCollection, usersCollection } from '@/plugins/firebase'

const snapshots = {
  loadTasks: null,
}

export default {
  namespaced: true,
  state: {
    tasks: []
  },
  getters: {
    getTasks: state => {
      const sortedTasks = state.tasks.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedTasks
    },
  },
  mutations: {
    setTasks(state, payload) {
      state.tasks = payload
    }
  },
  actions: {
    async loadTasks({ commit, rootState }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadTasks) {
        snapshots.loadTasks()
        snapshots.loadTasks = null
      }

      let user = rootState.user.user

      let queryStore = tasksCollection.where('del', '!=', true).where('archive', '==', false)
        .where('whoTo', 'array-contains', user.uid)

      if (user.role === 'designer') {
        queryStore = queryStore.where('ready', '==', false)
      }
			
			if (user.role === 'prodDir') {
        queryStore = queryStore.where('ready', '==', false)
      }

      snapshots.loadTasks = queryStore.onSnapshot(async snapshot => {
        let tasksArr = []

        for (let doc of snapshot.docs) {
          let task = { ...doc.data(), id: doc.id }
          tasksArr.push(task)
        }
      
        commit('setTasks', tasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    async createTask({ commit, rootState, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
      let user = rootState.user.user
      let fromRef = await usersCollection.doc(user.uid)

      const obj = { 
        ...data,
        from: fromRef,
        accepted: true,
        archive: false,
        del: false,
        meTask: true,
        ready: false,
				date: new Date(),
      }

			if (!obj.client) {
				obj.client = null
			}

			if (!obj.numberOrder) {
				let number = await dispatch('tasks/getNewNumberTask', null, { root: true })
				obj.numberOrder = `z${number}`
			}

      let itemRef = await tasksCollection.add(obj)

			if (itemRef && itemRef.id) {
				let obj = {
					text: 'Создал(а) эту задачу.',
					files: null,
					date: new Date,
					from: usersCollection.doc(user.uid),
					program: true
				}
	
				await tasksCollection.doc(itemRef.id).collection('comments').add(obj)
			}

      commit('templ/setLoading', false, { root: true })
    }
  }
}