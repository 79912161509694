<template>
  <v-hover v-slot="{ hover }">
    <v-card flat class="mx-auto">
      <v-img
        :src="image"
        aspect-ratio="1.2"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
              size="22"
              width="3"
            />
          </v-row>
        </template>
        <div
          v-if="hover"
          class="d-flex previewImage"
        >
          <v-btn icon color="grey" @click="isMobile ? $showImageMob(image) : $showImage(image)">
            <v-icon size="20" color="grey" class="pr-1">mdi-magnify-plus-outline</v-icon>
          </v-btn>
					<v-btn v-if="canDownload" icon color="grey" @click="downloadImg(image)">
            <v-icon size="20" color="grey" class="pl-1">mdi-download</v-icon>
          </v-btn>
          <v-btn v-if="!disTrach" icon color="#bf4037" @click="$emit('delete', path)">
            <v-icon size="20" color="#bf4037" class="pl-1">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </v-img>
    </v-card>
  </v-hover>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'PreviewImage',
  props: {
    fbPath: {
      default: ''
    },
    disTrach: {
      type: Boolean,
      default: false
    },
		canDownload: {
			type: Boolean,
      default: false
		}
  },
  data: () => ({
    isMobile: isMobile,
    image: ''
  }),
  created() {
    if (this.path && !this.path.uploading) {
      this.loadingImage(this.path)
    }
  },
  methods: {
		downloadImg(image) {
			window.open(image, '_blank').focus()
		},
    async loadingImage(path) {
      this.image = await this.$getFbFileUrl(path)
    }
  },
  computed: {
    path() {
      let path = this.fbPath
      return path ? path : ''
    }
  },
  watch: {
    path(val) {
      if (!val.uploading) {
        this.loadingImage(val)
      }
    }
  }
}
</script>