import { sortList } from '@/helpers'
import {
	metaInfoCollection,
	companyActivitysCollection,
	companyWhereFromCollection
} from '@/plugins/firebase'

const snapshots = {
  settings: null,
	companyActivitys: null,
	companyWhereFrom: null,
}

export default {
    namespaced: true,
    state: {
      settings: {},
			companyActivitys: [],
			companyWhereFrom: []
    },
    getters: {
      getSettings: state => {
        return state.settings
      },
      getHourPayWorkMasters: (state, getters, rootState, rootGetters) => {
        let settings = state.settings
        let user = rootGetters['user/getUser']
        return (user && ['master', 'admin', 'prodDir'].includes(user.role)) && settings.hourPayWorkMasters || false
      },
      stngHourPayWorkMasters: state => {
        return state.settings.hourPayWorkMasters || false
      },
			getCompanyActivitys: state => {
				return sortList(state.companyActivitys, 'title')
			},
			getWhereFromCompany: state => {
				return sortList(state.companyWhereFrom, 'title')
			}
    },
    mutations: {
      setSettings(state, payload) {
        state.settings = payload;
      },
			setCompanyActivitys(state, payload) {
        state.companyActivitys = payload;
      },
			setCompanyWhereFrom(state, payload) {
        state.companyWhereFrom = payload;
      }
    },
    actions: {
			loadCompanyActivitys({ commit, dispatch }) {
        commit('templ/setLoading', true, { root: true })
				snapshots.companyActivitys = companyActivitysCollection.onSnapshot(async snapshot => {
					let arr = []
					for (let doc of snapshot.docs) {
						arr.push({ ...doc.data(), id: doc.id })
					}
					commit('setCompanyActivitys', arr)
					commit('templ/setLoading', false, { root: true })
				})
      },
			loadCompanyWhereFrom({ commit, dispatch }) {
        commit('templ/setLoading', true, { root: true })
				snapshots.companyWhereFrom = companyWhereFromCollection.onSnapshot(async snapshot => {
					let arr = []
					for (let doc of snapshot.docs) {
						arr.push({ ...doc.data(), id: doc.id })
					}
					commit('setCompanyWhereFrom', arr)
					commit('templ/setLoading', false, { root: true })
				})
      },
			async updateCompanyActivity({}, value) {
				await companyActivitysCollection.doc(value.id).update({
					title: value.title
				})
			},
			async updateCompanyWhereFrom({}, value) {
				await companyWhereFromCollection.doc(value.id).update({
					title: value.title
				})
			},
			async checkCompanyActivity({ getters,  }, value) {
				const companyActivitys = getters.getCompanyActivitys
				let findItem = companyActivitys.find(x => x.title === value)
				if (!findItem) {
					companyActivitysCollection.add({ title: value })
				}
			},
			async checkCompanyWhereFrom({ getters }, value) {
				const whereFromCompany = getters.getWhereFromCompany
				let findItem = whereFromCompany.find(x => x.title === value)
				if (!findItem) {
					companyWhereFromCollection.add({ title: value })
				}
			},
			async delCompanyActivity({ getters }, value) {
				const companyActivitys = getters.getCompanyActivitys
				let findItem = companyActivitys.find(x => x.id === value)
				if (findItem) {
					companyActivitysCollection.doc(value).delete()
				}
			},
			async delCompanyWhereFrom({ getters }, value) {
				const companyActivitys = getters.getCompanyActivitys
				let findItem = companyActivitys.find(x => x.id === value)
				if (findItem) {
					companyActivitysCollection.doc(value).delete()
				}
			},
      loadSettings({ commit, dispatch }) {
        commit('templ/setLoading', true, { root: true })
        snapshots.settings = metaInfoCollection.doc('settings').onSnapshot(async snapshot => {
          let settings = snapshot.data()
          commit('setSettings', settings)
          commit('templ/setLoading', false, { root: true })
        })
				dispatch('loadCompanyActivitys')
				dispatch('loadCompanyWhereFrom')
      },
      async updSettings({ commit }, data) {
        commit('templ/setLoading', true, { root: true })
        await metaInfoCollection.doc('settings').update(data)
        commit('templ/setLoading', false, { root: true })
      }
    }
}