export default {
  state: {
    filterTasksByClient: null,
    filterTasksByManager: null,
    filterTasksByDate: null,
    // filter by process tasks
    filterWorkTasksByDate: null,
    filterWorkTasksByClient: null,
    filterWorkTasksByManager: null,
  },
  getters: {
    getFilterTasksByClient: state => {
      return state.filterTasksByClient
    },
    getFilterTasksByManager: state => {
      return state.filterTasksByManager
    },
    getFilterTasksByDate: state => {
      return state.filterTasksByDate
    },
    // filter by process tasks
    getFilterWorkTasksByClient: state => {
      return state.filterWorkTasksByClient
    },
    getFilterWorkTasksByManager: state => {
      return state.filterWorkTasksByManager
    },
    getFilterWorkTasksByDate: state => {
      return state.filterWorkTasksByDate
    },
  },
  mutations: {
    resetAllFilterTasks(state) {
      state.filterTasksByClient = null
      state.filterTasksByManager = null
      state.filterTasksByDate = null
      // filter by process tasks
      state.filterWorkTasksByClient = null
      state.filterWorkTasksByManager = null
      state.filterWorkTasksByDate = null
    },
    setFilterTasksByClient(state, payload) {
      state.filterTasksByClient = payload;
    },
    setFilterTasksByManager(state, payload) {
      state.filterTasksByManager = payload;
    },
    setFilterTasksByDate(state, payload) {
      state.filterTasksByDate = payload;
    },
    // filter by process tasks
    setFilterWorkTasksByClient(state, payload) {
      state.filterWorkTasksByClient = payload;
    },
    setFilterWorkTasksByManager(state, payload) {
      state.filterWorkTasksByManager = payload;
    },
    setFilterWorkTasksByDate(state, payload) {
      state.filterWorkTasksByDate = payload;
    },
  }
}