import { hourlyWorksCollection, usersCollection } from '@/plugins/firebase'

export default {
  namespaced: true,
  state: {
    tasks: [],
    filterHourlyWorksByMonth: new Date().toISOString().substr(0, 7)
  },
  getters: {
    getFilterHourlyWorksByMonth: state => {
      return state.filterHourlyWorksByMonth
    },
    getTasks: state => {
      const sortedTasks = state.tasks.sort(function(a,b){
        return a.date.toDate() - b.date.toDate();
      })
      return sortedTasks
    },
  },
  mutations: {
    setTasks(state, payload) {
      state.tasks = payload
    },
    setFilterHourlyWorksByMonth(state, payload) {
      state.filterHourlyWorksByMonth = payload;
    },
    removeTask(state, payload) {
      if (payload.id) {
        let index = state.tasks.findIndex(item => item.id === payload.id)
        if (index != -1) {
          state.tasks.splice(index, 1)
        }
      }
    },
    updTask(state, payload) {
      if (payload.doc && payload.doc.id) {
        const item = state.tasks.find(item => item.id === payload.doc.id)
        Object.assign(item, payload.doc)
      }
    },
    pushTask(state, payload) {
      if (payload.doc && payload.doc.id) {
        if (!state.tasks.find(item => item.id === payload.doc.id)) {
          state.tasks.push(payload.doc)
        }
      }
    },
  },
  actions: {
    async loadTasks({ commit, dispatch, getters }, data) {
      commit('templ/setLoading', true, { root: true })
      let queryStore = hourlyWorksCollection

      if (data.uid) {
        let userRef = await usersCollection.doc(data.uid)
        queryStore = queryStore.where('user', '==', userRef)
      }

      if (data.month) {
        let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)
        queryStore = queryStore.orderBy('date').startAt(firstDay).endAt(lastDay);
      }

      await queryStore.onSnapshot(async snapshot => {
        let tasksArr = []
        for (let doc of snapshot.docs) {
          tasksArr.push({ ...doc.data(), id: doc.id })
        }
        tasksArr = tasksArr.filter(x => !x.del)
      
        commit('setTasks', tasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    checkDateTask({ commit }, data) {
      commit('templ/setLoading', true, { root: true })
      let queryStore = hourlyWorksCollection

      if (data.date) {
        queryStore = queryStore.where('date', '==', data.date)
      }

      if (data.user) {
        let userRef = null
        if (data.user.firestore) {
          userRef = data.user
        } else {
          userRef = usersCollection.doc(data.user.uid)
        }
        queryStore = queryStore.where('user', '==', userRef)
      }

      return queryStore.get().then(async snapshot => {
        let tasksArr = []
        for (let doc of snapshot.docs) {
          tasksArr.push({ ...doc.data(), id: doc.id })
        }
        tasksArr = tasksArr.filter(x => !x.del)
        commit('templ/setLoading', false, { root: true })
        return tasksArr.length > 0
      })
    },
    async updateTask({ commit, dispatch }, obj) {
      commit('templ/setLoading', true, { root: true })
      let doc = hourlyWorksCollection.doc(obj.id)
      await doc.update(obj.data)

      if (obj.del) {
        commit('removeTask', { id: obj.id })
      }

      if (obj.updState) {
        doc = await doc.get()
        doc = { ...doc.data(), id: doc.id }

        commit('updTask', { doc: doc })
      }
      commit('templ/setLoading', false, { root: true })
    },
    async createTask({ commit, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
      const obj = { 
        ...data
      }

      if (data.user) {
        let userRef = await usersCollection.doc(data.user)
        obj.user = userRef
      }
      
      await hourlyWorksCollection.add(obj)

      commit('templ/setLoading', false, { root: true })
    }
  }
}