import { usersCollection } from '@/plugins/firebase'

export default {
    namespaced: true,
    state: {
        user: null
    },
    getters: {
        getUser: state => {
            return state.user
        },
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        }
    },
    actions: {
        async loadUser({ commit, dispatch }, data) {
            if (data && data.uid) {
                commit('templ/setLoading', true, { root: true })
                usersCollection.doc(data.uid).onSnapshot(async snapshot => {
                    let fullUser = await dispatch('users/userSerializer', snapshot.data(), { root: true })

                    commit('setUser', fullUser)
                    commit('templ/setLoading', false, { root: true })
                })
            }
        },
    }
}