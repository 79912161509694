<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <NewComment
				:task_id="task_id"
				:task="task"
			/>
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-center py-0 comment"
			:class="{ 'comment--del': comment.del }"
      v-for="comment in filtredComments"
      :key="comment.id"
    >
      <v-row>
        <v-col class="py-0">
          <v-row>
            <v-col cols="2">
              <my-mini-avatar :user="comment.from" />
            </v-col>
            <v-col cols="10" style="margin-left: -4.5%;">
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-subheader class="pa-0" style="height: 23px;">
                    <span style="font-weight: 500;">{{ comment.from.displayName }}</span>
										<span v-if="comment.program" class="ml-2">Авто. создан</span>
                    <span class="ml-2">{{ $formatDateTime(comment.date.toDate()) }}</span>
										<span 
											v-if="user.uid === comment.from.uid && !comment.program && !comment.del"
											class="ml-1"
										>
											<v-btn icon small @click="deleteComment(comment)">
												<v-icon size="20" color="red">
													mdi-trash-can-outline
												</v-icon>
											</v-btn>
										</span>
                  </v-subheader>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-row>
                    <v-col 
											v-if="comment.text"
											class="py-0 comment__text"
											v-html="comment.text"
										/>
                    <v-col 
                      v-if="!comment.text && (!comment.files || comment.files.length === 0)"
                      class="py-0 comment__text"
                    >
                      Пустой комментарий
                    </v-col>
                    <v-col v-if="comment.files && comment.files.length > 0" cols="12" class="py-0">
                      <v-container fluid class="pa-0">
                        <v-row>
                          <v-col
                            v-for="(image, index_image) in comment.files"
                            :key="index_image"
                            class="d-flex child-flex"
                            cols="3"
                          >
                            <my-preview-image :canDownload="true" :fbPath="image" :disTrach="true" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import NewComment from './NewComment.vue'
import { tasksCollection } from '@/plugins/firebase'

const snapshots = {
  comments: null
}

export default {
  components: {
    NewComment
  },
  props: ['task_id', 'task', 'showProgramComment'],
  data: () => ({
    comments: [],
  }),
  created() {
    this.loadComments()
  },
  methods: {
		async deleteComment(comment) {
			let queryStore = tasksCollection.doc(this.task_id).collection('comments').doc(comment.id)
			await queryStore.update({ del: true })
		}, 
    loadComments() {
      if (snapshots.comments) {
        snapshots.comments()
        snapshots.comments = null
      }

      if (this.task_id) {
        let queryStore = tasksCollection.doc(this.task_id).collection('comments')
        snapshots.comments = queryStore.onSnapshot(async snapshot => {
          let comments = []
          for (let doc of snapshot.docs) {
            let comment = { ...doc.data(), id: doc.id }
            let commentFrom = await comment.from.get()
            comment.from = commentFrom.data()
            comments.push(comment)
          }
          comments = comments.sort(function(a,b){
            return b.date.toDate() - a.date.toDate();
          })

          this.comments = comments
        })
      }
    },
  },
	computed: {
		user() {
      return this.$store.getters['user/getUser']
    },
		filtredComments() {
			let comments = this.comments
			if (!this.showProgramComment) {
				comments = comments.filter(x => !x.program)
				comments = comments.filter(x => !x.del)
			}
			return comments
		}
	},
  beforeDestroy() {
    if (snapshots.comments) {
      snapshots.comments()
      snapshots.comments = null
    }
  }
}
</script>

<style lang="scss">
.comment {
	&--del {
		.comment__text {
			text-decoration: line-through;
		}
	}
}
</style>