import { Role } from '@/helpers'
import { func, storage, usersCollection, brigsCollection } from '@/plugins/firebase'
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        users: [],
        managers: [],
        filterUserDataByMonth: new Date().toISOString().substr(0, 7),
        searchUsers: '',
        filterUserByRole: ''
    },
    getters: {
        getManagers: state => {
            const sortedUsers = state.managers.sort(function(a,b){
                return new Date(b.created) - new Date(a.created);
            })
            return sortedUsers
        },
        getUser: (state) => (ident, by) => {
						let user = null
						if (by === 'email') {
							user = state.users.find(x => x.email === ident)
						} else {
							user = state.users.find(x => x.uid === ident)
						}
            return user
        },
        getUsers: state => {
            let users = state.users
            let search = state.searchUsers.toLowerCase()
            let byRole = state.filterUserByRole

            if (byRole) {
                users = users.filter(x => {
                    if (x.role && x.role.includes(byRole)) { return true }
                    return false
                })
            }

            if (search) {
                users = users.filter(x => {
                    if (x.displayName) {
                        let displayName = x.displayName.toLowerCase()
                        if (displayName.includes(search)) { return true }
                    }
                    if (x.email) {
                        let email = x.email.toLowerCase()
                        if (email.includes(search)) { return true }
                    }
                    return false
                })
            }
            const sortedUsers = users.sort(function(a,b){
                return new Date(b.created) - new Date(a.created);
            })
            return sortedUsers
        },
        getWorkers: (state, getters) => {
            const users = getters.getUsers
            const workers = users.filter(x => x.role === 'master' || x.role === 'prodDir')
            return workers
        },
				getProdDirs: (state, getters) => {
            const users = getters.getUsers
            const workers = users.filter(x => x.role === 'prodDir')
            return workers
        },
        getOfficeUsers: (state, getters, rootState, rootGetters) => {
						const user = rootGetters['user/getUser']
            const users = getters.getUsers
            let officeUsers = users
							.filter(x => x.role != 'master')
							.filter(x => x.role != 'designer')
							.filter(x => x.uid != user.uid)
            return officeUsers
        },
        getDesignerUsers: (state, getters) => {
            const users = getters.getUsers
            const designerUsers = users.filter(x => x.role === 'designer')
            return designerUsers
        },
        getFilterUserDataByMonth: state => {
            return state.filterUserDataByMonth
        },
        getSearchUsers: state => {
            return state.searchUsers
        },
        getFilterUserByRole: state => {
            return state.filterUserByRole
        }
    },
    mutations: {
        setManagers(state, payload) {
            state.managers = payload;
        },
        setUsers(state, payload) {
            state.users = payload;
        },
        pushUser(state, user) {
            state.users.push(user)
        },
        updateUser(state, user) {
            const userIndex = state.users.findIndex(x => x.uid === user.uid)
            if (userIndex != -1) {
                Vue.set(state.users, userIndex, user)
            }
        },
        deleteUser(state, uid) {
            const indexUser = state.users.findIndex(x => x.uid === uid)
            if (indexUser != -1) {
                state.users.splice(indexUser, 1)
            }
        },
        setFilterUserDataByMonth(state, payload) {
            state.filterUserDataByMonth = payload;
        },
        setSearchUsers(state, payload) {
            state.searchUsers = payload;
        },
        setFilterUserByRole(state, payload) {
            state.filterUserByRole = payload;
        },
        resetAllFilterUser(state, payload) {
            state.searchUsers = '';
            state.filterUserByRole = '';
        }
    },
    actions: {
        loadManagers({ commit, dispatch }) {
            commit('templ/setLoading', true, { root: true })
            usersCollection.where('del', '!=', true).where('role', 'in', ['manager', 'admin']).onSnapshot(async snapshot => {
                let usersArr = []

                for (let userDoc of snapshot.docs) {
                    let user = await dispatch('userSerializer', userDoc.data())
                    usersArr.push(user)
                }

                commit('setManagers', usersArr)
                commit('templ/setLoading', false, { root: true })
            })
        },
        async loadUsers({ commit, dispatch }) {
            commit('templ/setLoading', true, { root: true })
            return await usersCollection.where('del', '!=', true).onSnapshot(async snapshot => {
                let usersArr = []

                for (let userDoc of snapshot.docs) {
                    let user = await dispatch('userSerializer', userDoc.data())
                    usersArr.push(user)
                }

                commit('setUsers', usersArr)
                commit('templ/setLoading', false, { root: true })
            })
        },
        createUser({ commit, dispatch }, payload) {
            commit('templ/setLoading', true, { root: true })
            var createUser = func.httpsCallable('createUser');
            let userData = {
              avatar: payload.avatar,
              email: payload.email,
              password: payload.password,
              displayName: payload.displayName,
              location: payload.location,
              rate: payload.rate,
              rateOnManufactory: payload.rateOnManufactory,
              rateOnMontage: payload.rateOnMontage,
              rateOverwork: payload.rateOverwork,
							monthlyPayments: payload.monthlyPayments,
              customClaims: {
                role: payload.role
              }
            }
            console.log(userData)
            createUser(userData)
              .then(async result => {
                // commit('pushUser', result.data)
                console.log('Профиль создан')
              }).catch((err) => {
                commit('templ/setError', 'Ошибка создания профиля, попробуйте позже.', { root: true })
                console.log(err)
              }).finally(() => {
                commit('templ/setLoading', false, { root: true })
              })
        },
        async updateUser({ commit }, payload) {
            commit('templ/setLoading', true, { root: true })
            console.log(payload)
            if (Object.keys(payload).length > 0) {
                let data = { ...payload.data }
                var updateUser = func.httpsCallable('updateUser');
                updateUser({ uid: payload.uid, data: data })
                    .then(async result => {
                        console.log('Профиль обновлён')
                    }).catch((err) => {
                        commit('templ/setError', 'Ошибка обновления профиля, попробуйте позже.', { root: true })
                        console.log(err)
                    }).finally(() => {
                        commit('templ/setLoading', false, { root: true })
                    })
            }
        },
        async deleteUser({ commit }, payload) {
            commit('templ/setLoading', true, { root: true })
            var deleteUser = func.httpsCallable('deleteUser');
            deleteUser({ uid: payload.uid })
                .then((result) => {
                    if (result) {
                        commit('deleteUser', payload.uid)
                    }
                }).catch((err) => {
                    commit('templ/setError', 'Ошибка удаления профиля, попробуйте позже.')
                    console.log(err)
                }).finally(() => {
                    commit('templ/setLoading', false, { root: true })
                })
        },
        checkExistsEmail({ state }, email) {
            let users = state.users
            let user = users.find(x => x.email === email)
            return user ? false : true;
        },
        async userSerializer({ state }, ref) {
            let user = {}
            if (ref.uid) {
              user = { ...ref }
            } else {
              let res = await ref.get()
              user = { ...res.data() }
            }

            if (!user.location) {
              user = { ...user, location: 'Не указан' }
            }

						if (user.avatar && !user.avatar.includes('https')) {
							let avatar = await storage.ref(user.avatar).getDownloadURL()
							user = { ...user, avatar: avatar }
						}

            return user
        }
    }
}