import { premiumsCollection, usersCollection } from '@/plugins/firebase'

export default {
    namespaced: true,
    state: {
      premiums: []
    },
    getters: {
        getPremiums: state => {
          const sortedDeductions = state.premiums.sort(function(a,b){
            return new Date(b.date) - new Date(a.date);
          })
          return sortedDeductions
        }
    },
    mutations: {
      setPremiums(state, payload) {
        state.premiums = payload;
      }
    },
    actions: {
      async deletePremiums({ commit, rootState }, data) {
        commit('templ/setLoading', true, { root: true })
        await premiumsCollection.doc(data.id).update({ del: true })
          .then(() => {
              console.log('Премия удалена')
          }).catch((err) => {
              commit('templ/setError', 'Ошибка удаления премии, попробуйте позже.')
              console.log(err)
          }).finally(() => {
              commit('templ/setLoading', false, { root: true })
          })
      },
      async createPremiums({ commit, dispatch, rootGetters }, data) {
        commit('templ/setLoading', true, { root: true })
        let filterDate = rootGetters['users/getFilterUserDataByMonth']
        let date = new Date(filterDate), y = date.getFullYear(), m = date.getMonth()
        filterDate = new Date(y, m + 1, 0, 23, 59)
        let toDay = new Date()

        if (filterDate.getMonth() != toDay.getMonth()) {
            date = new Date(filterDate.toISOString().substr(0, 10))
        } else {
            date = new Date(toDay.toISOString().substr(0, 10))
        }

        const obj = { 
            ...data,
            date: date,
            del: false
        }
        await premiumsCollection.add(obj)
        commit('templ/setLoading', false, { root: true })
      },
      async loadPremiums({ commit, dispatch }, data) {
        commit('templ/setLoading', true, { root: true })

        let userRef = await usersCollection.doc(data.uid)

        let queryStore = premiumsCollection.where('user', '==', userRef)

        if (data.month) {
          let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
          let firstDay = new Date(y, m, 1, 0)
          let lastDay = new Date(y, m + 1, 0, 23, 59)
          queryStore = queryStore.orderBy('date').startAt(firstDay).endAt(lastDay);
        }

        queryStore.onSnapshot(async snapshot => {
          let premiumsArr = []
          for (let premiumDoc of snapshot.docs) {
              let premium = { ...premiumDoc.data(), id: premiumDoc.id }
              if (premium.user) {
                  let user = await dispatch('users/userSerializer', premium.user, { root: true })
                  premium.user = user
              }
              premiumsArr.push(premium)
          }
          premiumsArr = premiumsArr.filter(x => !x.del)

          commit('setPremiums', premiumsArr)
          commit('templ/setLoading', false, { root: true })
        })
      },
    }
}