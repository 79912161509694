import { locationsCollection } from '@/plugins/firebase'

export default {
  namespaced: true,
  state: {
    locations: []
  },
  getters: {
    getLocations: state => {
      const sortedLocations = state.locations.sort(function(a,b){
        return a.order- b.order
      })
      return sortedLocations
    },
  },
  mutations: {
    setLocation(state, payload) {
      state.locations = payload
    }
  },
  actions: {
    loadLocations({ commit }) {
      commit('templ/setLoading', true, { root: true })
      locationsCollection.where('del', '!=', true).get().then(snapshot => {
        let locationsArr = []
      
        snapshot.forEach(doc => {
          let location = { ...doc.data(), id: doc.id }
          locationsArr.push(location)
        })
      
        commit('setLocation', locationsArr)
        commit('templ/setLoading', false, { root: true })
      })
    }
  }
}