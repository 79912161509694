<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '600px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
    >
      <v-card :tile="isMobile">
        <v-toolbar v-if="isMobile" color="#e0a677" tile style="position: sticky; top: 0px; z-index: 20;">
          <v-toolbar-title v-if="edit" class="white--text">Редактировние категории</v-toolbar-title>
          <v-toolbar-title v-else class="white--text">Новая категории</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title v-else>
          <span v-if="edit" class="headline">Редактировние категории</span>
          <span v-else class="headline">Новая категории</span>
          <v-spacer />
          <v-btn icon color="#e0a677" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container :class="{ 'pa-0': isMobile }">
            <v-form ref="formCategory">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    color="#e0a677"
                    v-model="form.title"
                    label="Название категории*"
                    :rules="$fieldRules.field"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="form.available"
                    :items="userRoles"
                    label="Доступна для"
                    multiple
                    color="#e0a677"
                    item-color="#e0a677"
                  >
										<template v-slot:selection="{ item, index }">
											<span v-if="index < 3">
												<span>{{ roleText[item] }}</span>
												<span 
													v-if="form.available && index < form.available.length - 1"
													class="mr-1"
												>,</span>
											</span>
											<span v-if="index === 3">
												(+{{ form.available.length - 3 }})
											</span>
										</template>
										<template v-slot:item="{item, attrs, on}">
											<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
												<v-list-item-action>
													<v-checkbox color="#e0a677" :ripple="false" :input-value="active" />
												</v-list-item-action>
												<v-list-item-content>
													<v-list-item-title>
														<v-row no-gutters align="center">
															{{ roleText[item] }}
														</v-row>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</template>
									</v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" text @click="save()">
            <span v-if="edit">Сохранить</span>
            <span v-else>Создать</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { Role, RoleText } from '@/helpers'

export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    isMobile: isMobile,
    role: Role,
    roleText: RoleText,
    dialog: true,
    errors: {},
    form: {
      title: '',
			available: []
    },
  }),
  computed: {
    userRoles() {
			let items = Object.values(this.role)
			let indexAdmin = items.findIndex(x => x === 'admin')
			if (indexAdmin != -1) {
				items.splice(indexAdmin, 1)
			}
      return items
    }
  },
  created() {
    if (this.data && this.data.id) {
      this.form.title = this.data.title
      this.form.available = this.data.available
    } else if (this.data && this.data.available) {
			this.form.available = this.data.available
		}
  },
  methods: {
    async save() {
      const errors = []
      const validate = await this.$refs.formCategory.validate()
      errors.push(validate)

      if (!errors.includes(false)) {
				this.$emit('close', this.form)
      }
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    }
  }
}
</script>
