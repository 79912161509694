import { notificationsCollection, usersCollection } from '@/plugins/firebase'

const snapshots = {
  loadNotifications: null
}

export default {
  namespaced: true,
  state: {
    notifications: [],
		isOpenNotifList: false
  },
  getters: {
    getNotifications: state => {
      const sortedItems = state.notifications.sort(function(a,b){
        return b.date - a.date;
      })
      return sortedItems
    },
		getShowAlertNotif: state => {
			let notifications = state.notifications.filter(x => x.showAlert)
      const sortedItems = notifications.sort(function(a,b){
        return b.date - a.date;
      })
      return sortedItems
    },
		getIsOpenNotifList: state => {
			return state.isOpenNotifList
		}
  },
  mutations: {
    setNotifications(state, payload) {
      state.notifications = payload
    },
		setIsOpenNotifList(state, payload) {
			state.isOpenNotifList = payload
		}
  },
  actions: {
		async acceptedNotification (context, id) {
			await notificationsCollection.doc(id).update({
				accepted: true
			})
		},
		async showAlertNotification (context, obj) {
			await notificationsCollection.doc(obj.id).update(obj.data)
		},
    async loadNotifications({ commit, rootState, dispatch }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadNotifications) {
        snapshots.loadNotifications()
        snapshots.loadNotifications = null
      }

      let user = rootState.user.user
			let userRef = usersCollection.doc(user.uid)

      let queryStore = notificationsCollection.where('to', '==', userRef).where('accepted', '==', false)

      snapshots.loadNotifications = queryStore.onSnapshot(async snapshot => {
        let itemsArr = []

        for (let doc of snapshot.docs) {
					let item = await dispatch('notificationSerializer', { ...doc.data(), id: doc.id })
          itemsArr.push(item)
        }
      
        commit('setNotifications', itemsArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
		async notificationSerializer({ state, dispatch }, ref) {
      let notification = {}
      if (ref.id) {
        notification = ref
      } else {
        let res = await ref.get()
        notification = { ...res.data(), id: res.id }
      }

			if (notification.from) {
				let from = await notification.from.get()
				from = from.data()
				notification = { ...notification, from: from }
			}

			if (notification.to) {
				let to = await notification.to.get()
				to = to.data()
				notification = { ...notification, to: to }
			}
			
			if (notification.date) {
				notification = { ...notification, date: notification.date.toDate() }
			}

			// для логики с всплывающими уведомлениями
			if (notification.showAlert) {
				let time = new Date()
				let timeDiff = time - notification.date
				timeDiff /= 1000 // sec
				timeDiff /= 60 // min
				if (timeDiff > 2) {
					notification = { ...notification, showAlert: false }
					await dispatch('showAlertNotification', { id: notification.id, data: { showAlert: false } })
				}
			}

      return notification
    },
  }
}