<template>
	<v-container class="pa-0 materials">
		<template>
			<v-card-title class="py-2 pr-2">
				<v-list-item-title
					:style="`max-width: calc(100% - ${selectMaterial ? 40 : 32}px);`"
				>
					<span v-if="selectedCategory">{{ selectedCategory.title }}</span>
					<span v-else >Выберите категорию</span>
				</v-list-item-title>
				<v-spacer />
				<template v-if="currentUser.role === 'admin'">
					<v-btn
						small icon
						@click="editMaterial()"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
			</v-card-title>
			<v-divider />
		</template>
		<Material 
			v-if="selectMaterial"
			:material="selectMaterial"
		/>
	</v-container>
</template>

<script>
import { faqMaterialsCollection } from '@/plugins/firebase'
import ModalMaterial from './modals/Material.vue'
import Material from './Material.vue'

export default {
	components: {
		Material
	},
	props: ['selectedCategory'],
	data: () => ({
		load: true,
		materials: [],
		selectMaterial: null
	}),
	async created() {
		this.$emit('newMessage', 'Загрузка...')
		if (this.selectedCategory && this.selectedCategory.id) {
			this.materials = await this.getMaterials(this.selectedCategory)
			if (this.materials && this.materials.length > 0) {
				this.selectMaterial = this.materials[0]
			}
			this.load = false
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters['user/getUser']
		},
		sortedMaterials() {
			let sortedItems = this.materials.sort(function(a,b){
				return a.order - b.order;
			})
			return sortedItems
		}
	},
	methods: {
		async editMaterial() {
			const res = await this.$showModal({
        component: ModalMaterial,
        isPersistent: true,
        props: {
          edit: false,
          data: this.selectMaterial,
					available: this.selectedCategory ? this.selectedCategory.available : []
        }
      })
			if (res) {
				let id = 0
				if (this.selectMaterial && this.selectMaterial.id) {
					id = this.selectMaterial.id
				} else {
					id = String(Date.now())
				}
				let queryStore = faqMaterialsCollection.doc(id)
				await queryStore.set({
					...res,
					title: this.selectedCategory.title,
					category: this.selectedCategory.id,
					del: false
				})
				this.selectMaterial = {
					id: id,
					...this.selectMaterial,
					...res
				}
				let indexMaterial = this.materials.findIndex(x => x.id === this.selectMaterial.id)
				if (indexMaterial != -1) {
					this.$set(this.materials, indexMaterial, this.selectMaterial)
				} else {
					this.materials.push(this.selectMaterial)
				}
			}
		},
		async getMaterials(category) {
			let queryStore = faqMaterialsCollection.where('del', '==', false).where('category', '==', category.id)
			let res = await queryStore.get()
			let items = []
			for(let ref of res.docs) {
				let doc = await this.serializeItem(ref)
				items.push(doc)
			}
			items = await this.sortItems(items)
			return items.length > 0 ? items : []
		},
		async serializeItem(ref) {
			let doc = { 
				...ref.data(),
				id: ref.id,
			}
			return doc
		},
		async sortItems(items) {
			let sortedItems = await items.sort(function(a,b){
				return a.order - b.order;
			})
			return sortedItems
		}
	},
	watch: {
		load(val) {
			if (val) {
				this.$emit('newMessage', 'Загрузка...')
			} else if (!val && this.materials.length === 0) {
				this.$emit('newMessage', 'Нет материала')
			} else {
				this.$emit('newMessage', '')
			}
		},
		materials(val) {
			if (val && val.length > 0 && !this.load) {
				this.$emit('newMessage', '')
			}
		}
	}
}
</script>

<style lang="scss">
.materials {}
</style>