<template>
  <div class="page pageManufactoryTasks" :class="{ 'pa-3': !isMobile }">
    <v-card :tile="isMobile" class="pa-3">
			<v-row>
				<v-col cols="12" sm="3" md="2" xl="2">
					<v-card>
						<v-card-subtitle class="pb-0">Тип оплаты: сдельная / почасовая</v-card-subtitle>
						<div class="d-flex align-center flex-wrap px-4">
							<v-switch
								class="mx-2"
								:input-value="hourPayWorkMasters"
								@change="updateSettings($event, 'hourPayWorkMasters')"
								color="#e0a677"
							/>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" sm="3" md="4" xl="2">
					<v-card>
						<CompanyActivitys />
					</v-card>
				</v-col>
				<v-col cols="12" sm="3" md="4" xl="2">
					<v-card>
						<CompanyWhereFrom />
					</v-card>
				</v-col>
			</v-row>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

import CompanyActivitys from '@/components/Settings/components/CompanyActivitys.vue'
import CompanyWhereFrom from '@/components/Settings/components/CompanyWhereFrom.vue'

export default {
	components: {
		CompanyActivitys,
		CompanyWhereFrom
	},
  data() {
    return {
      isMobile: isMobile
    }
  },
  computed: {
    hourPayWorkMasters() {
      return this.$store.getters['settings/stngHourPayWorkMasters']
    }
  },
  methods: {
    updateSettings(val, param) {
      let obj = {
        [param]: val || false
      }
      this.$store.dispatch('settings/updSettings', obj)
    }
  },
}
</script>