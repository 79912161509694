<template>
  <v-row class="orderModal" justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '880px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
    >
      <v-card :tile="isMobile">
        <v-card-title>
					Заказ: {{ order.number }}. Дата создания: {{ $formatDate(order.date.toDate()) }}
          <v-spacer />
          <div class="d-flex align-start" style="height: 100%;">
            <v-btn icon color="#e0a677" @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8" class="pt-0" style="overflow: scroll; height: 69vh;">
              <v-container class="pa-0">
                <v-list class="pa-0">
									<!-- КЛИЕНТ -->
									<v-list-item class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-account-cash-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="px-2">
                      <!-- <v-list-item-title style="font-size: 1.3em; line-height: 1.9em;">Клиент</v-list-item-title> -->
											<v-row style="margin-top: -5px;">
												<v-col cols="5" class="d-flex pt-0">
													<v-autocomplete
														ref="clientName"
														:dense="!isMobile"
														:disabled="order.archive && user.role != 'admin'"
														color="#e0a677"
														v-model="form.client"
														placeholder="Клиент"
														:items="clients"
														item-text="name"
														return-object
														@change="setClient()"
													>
														<template v-slot:no-data>
															<v-row no-gutters class="px-2">
																<v-col cols="12" class="d-flex align-center justify-center">
																	<v-btn @click="newClient()" color="#e0a677" text small>
																		Добавить клиента
																	</v-btn>
																</v-col>
															</v-row>
														</template>
													</v-autocomplete>
												</v-col>
												<v-col cols="7" class="d-flex pt-0">
													<v-select
														class="mr-2"
														:dense="!isMobile"
														:disabled="!form.client || !form.client.contacts"
														color="#e0a677"
														item-color="#e0a677"
														v-model="form.contact"
														placeholder="Контакт клиента"
														item-text="name"
														:items="clientContacts"
														return-object
														@change="changeClientContact($event)"
													>
														<template v-slot:selection="{ item }">
															<span>{{ `${item.name}${item.main ? '*' : ''}: ${item.phonenumber}` }}</span>
														</template>
														<template v-slot:item="{ item, attrs, on }">
															<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
																<v-list-item-action>
																	<v-checkbox color="#e0a677" :input-value="active"></v-checkbox>
																</v-list-item-action>
																<v-list-item-content>
																	<v-list-item-title>
																		{{ `${item.name}${item.main ? '*' : ''}: ${item.phonenumber}` }}
																	</v-list-item-title>
																</v-list-item-content>
															</v-list-item>
														</template>
													</v-select>
													<v-btn
														:disabled="!form.client || !form.client.name === undefined"
														@click="showClientInfo()"
														icon
													>
														<v-icon>mdi-account-eye-outline</v-icon>
													</v-btn>
												</v-col>
											</v-row>
                    </v-list-item-content>
                  </v-list-item>
									<!-- РАБОТЫ -->
									<v-list-item class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-gantry-crane</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="px-2">
                      <!-- <v-list-item-title style="font-size: 1.3em; line-height: 1.9em;">Работы</v-list-item-title> -->
											<v-card tile flat style="margin-top: -3px;">
												<v-form ref="formNewWork" class="d-flex align-center" style="width: 100%;">
													<v-row no-gutters class="pa-0">
														<v-col class="pa-0 pr-2" cols="9">
															<v-text-field
																:dense="!isMobile"
																:disabled="order.archive"
																color="#e0a677"
																v-model="newWork.title"
																placeholder="Название работы"
																:rules="$fieldRules.field"
																required
															/>
														</v-col>
														<v-col class="pa-0 pl-2" cols="3">
															<v-text-field
																:dense="!isMobile"
																:disabled="order.archive"
																color="#e0a677"
																v-model="newWork.cost"
																placeholder="Стоимость"
																:rules="$fieldRules.cost"
																required
																type="number"
															/>
														</v-col>
													</v-row>
													<v-btn :disabled="order.archive" icon small class="ml-3" @click="addWork()">
														<v-icon size="22">mdi-keyboard-return</v-icon>
													</v-btn>
												</v-form>
												<v-divider v-if="form.works && form.works.length > 0" />
												<v-simple-table v-if="form.works && form.works.length > 0" :dense="!isMobile">
													<template v-slot:default>
														<tbody>
															<tr
																v-for="work in form.works"
																:key="work.id"
															>
																<td v-if="work.id != editWorkId" class="px-1 text-left" style="font-size: 1em !important; width: 70%;">{{ work.title }}</td>
																<td v-if="editWorkId === work.id" class="px-1" style="width: 70%;">
																	<v-text-field
																		dense
																		:disabled="order.archive"
																		color="#e0a677"
																		v-model="editWorkData.title"
																		placeholder="Название работы"
																		:rules="$fieldRules.field"
																		required
																	/>
																</td>

																<td v-if="work.id != editWorkId" class="px-1 text-center" style="font-size: 1em !important;">
																	{{ work.cost }}р.
																</td>
																<td v-if="editWorkId === work.id" class="px-1">
																	<v-text-field
																		dense
																		:disabled="order.archive"
																		color="#e0a677"
																		v-model="editWorkData.cost"
																		placeholder="Стоимость"
																		:rules="$fieldRules.cost"
																		required
																		type="number"
																	/>
																</td>

																<td class="px-1 text-right" :style="`${!editWorkId ? 'min-width: 6.2vw;' : ''}`">
																	<v-btn v-if="work.id != editWorkId" :disabled="order.archive" @click.stop="deleteWork(work.id)" icon small color="#bf4037">
																		<v-icon size="18">mdi-trash-can-outline</v-icon>
																	</v-btn>
																	<v-btn v-if="work.id != editWorkId" :disabled="order.archive" @click.stop="editWork(work)" icon small>
																		<v-icon size="18">mdi-pencil</v-icon>
																	</v-btn>
																	<v-btn v-if="editWorkId === work.id" :disabled="order.archive" @click.stop="saveEditWork()" icon small>
																		<v-icon size="18">mdi-check</v-icon>
																	</v-btn>
																</td>
															</tr>
														</tbody>
													</template>
												</v-simple-table>
											</v-card>
                    </v-list-item-content>
                  </v-list-item>
									<!-- ОПИСАНИЕ -->
                  <v-list-item class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="mt-2 px-2">
                      <!-- <v-list-item-title style="font-size: 1.3em; line-height: 1.9em;">Описание</v-list-item-title> -->
											<textarea
												:disabled="order.archive"
												:value="form.description"
												rows="1"
												class="headline orderModal__field orderModal__description"
												ref="orderModal__description"
												placeholder='Описание заказа'
												@input="textAreaAutosize($event.target)"
												@change="changeDescription(form.description, $event.target.value)"
											/>
                    </v-list-item-content>
                  </v-list-item>
									<!-- ДЕЙСТВИЯ -->
									<v-list-item class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="px-2">
                      <!-- <v-list-item-title style="font-size: 1.3em; line-height: 1.9em;">Действия</v-list-item-title> -->
											<v-row 
												class="
													ma-0
													pa-0
													d-flex
												"
											>
												<v-card-subtitle class="pa-0 pl-1">Задача</v-card-subtitle>
												<v-col cols="12" class="pa-0">
													<v-btn
														:disabled="order.archive || form.status === 'draft'"
														class="ma-1"
														small
														outlined
														@click="newTask('meTask')"
													>
														<v-icon left>
															mdi-account
														</v-icon>
														Мне
													</v-btn>
													<v-btn
														:disabled="order.archive || form.status === 'draft'"
														class="ma-1"
														small
														outlined
														@click="newTask('all')"
													>
														<v-icon left>
															mdi-account-multiple
														</v-icon>
														Кому то
													</v-btn>
													<v-btn
														:disabled="order.archive || form.status === 'draft'"
														class="ma-1"
														small
														outlined
														@click="newTask('design')"
													>
														<v-icon left>
															mdi-account-edit
														</v-icon>
														Дизайнеру
													</v-btn>
												</v-col>
												<v-card-subtitle class="pa-0 pl-1 mt-1">Производство</v-card-subtitle>
												<v-col cols="12" class="pa-0">
													<v-btn
														:disabled="order.archive || form.status === 'draft'"
														class="ma-1"
														small
														outlined
														@click="newTaskManufactory()"
													>
														<v-icon left>
															mdi-archive-cog
														</v-icon>
														Производство
													</v-btn>
													<v-btn
														:disabled="order.archive || form.status === 'draft'"
														class="ma-1"
														small
														outlined
														@click="newMontage()"
													>
														<v-icon left>
															mdi-truck-minus
														</v-icon>
														Монтаж
													</v-btn>
												</v-col>
											</v-row>
                    </v-list-item-content>
                  </v-list-item>
									<!-- СВЯЗИ -->
									<v-list-item class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-link</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="px-2">
                      <!-- <v-list-item-title style="font-size: 1.3em; line-height: 1.9em;">Связи</v-list-item-title> -->
											<v-card tile flat>
												<v-simple-table v-if="orderLink && orderLink.length > 0" :dense="!isMobile">
													<template v-slot:default>
														<tbody>
															<tr
																v-for="link in orderLink"
																:key="link.id"
															>
																<td
																	@click="openLink(link)"
																	class="rl-link px-1 text-left"
																	style="font-size: 1em !important; width: 67%;"
																>
																	<span v-if="link.type === 'montage'">Монтаж:</span>
																	<span v-if="link.type === 'manufactory'">Производство:</span>
																	<span v-if="link.type === 'task'">Задача:</span>
																	{{ link.title }}
																</td>

																<td class="px-1 text-left"
																	:style="`font-size: 1em !important; ${form.status === 'ready' && !link.ready ? 'color: red;' : link.ready ? 'color: green;' : ''}`"
																>
																	<span v-if="link.type === 'montage'">{{ montagesStatus[link.status] }}</span>
																	<span v-if="link.type === 'manufactory'">{{ manufactoryTaskStatus[link.status] }}</span>
																	<span v-if="link.type === 'task'">{{ link.status }}</span>
																</td>
															</tr>
														</tbody>
													</template>
												</v-simple-table>
												<v-card-subtitle v-else class="pa-0">Нет связей</v-card-subtitle>
											</v-card>
                    </v-list-item-content>
                  </v-list-item>
									<!-- КОММЕНТАРИИ -->
                  <v-list-item v-if="edit" class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-comment-text-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="px-2">
                      <v-list-item-title class="d-flex align-center" style="font-size: 1.3em; line-height: 1.9em;">
												Комментарии
												<v-spacer />
												<v-btn
													x-small
													outlined
													@click="showProgramComments = !showProgramComments"
												>
													<span v-if="showProgramComments">Скрыть подробности</span>
													<span v-else>Показать подробности</span>
												</v-btn>
											</v-list-item-title>
                      <Comments
												:order_id="order.id"
												:order="order"
												:showProgramComment="showProgramComments"
											/>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-col>
            <v-col cols="4" class="py-0" style="border-left: solid 2px grey;">
							<RightBar
								:order="order"
								:form="form"
								:user="user"
								:fullCost="fullCost"
								:paid="paid"
								:deadlineText="deadlineText"
								:percentOfOrder="form.percentOfOrder"
								:entityReadiness="entityReadiness"
								@update="update($event)"
								@selectDeadline="selectDeadline()"
								@newPay="newPay($event)"
								@deletePay="deletePay($event)"
								@updStatus="updStatus($event)"
								@updPercentOfOrder="updPercentOfOrder($event)"
							/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import Comments from './components/Comments/Index.vue'
import RightBar from './components/RightBar/Index.vue'
import SelectDate from '@/components/Modals/SelectDate'

import TaskModal from  '@/components/Modals/Task/Index'
import ManufactoryTaskModal from  '@/components/Modals/Manufactory/Task'
import Client from  '@/components/Modals/Clients/Index'
import MontageModal from  '@/components/Modals/Montage/Index'

import { ordersCollection, usersCollection } from '@/plugins/firebase'
import { 
	fieldKeysOrder,
	orderTypePay,
	orderStatus,
	montagesStatus,
	manufactoryTaskStatus
} from '@/helpers'

export default {
  components: {
    Comments,
		RightBar
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    isMobile: isMobile,
    fieldKeysOrder: fieldKeysOrder,
    orderTypePay: orderTypePay,
    orderStatus: orderStatus,
    montagesStatus: montagesStatus,
    manufactoryTaskStatus: manufactoryTaskStatus,
    dialog: true,
    errors: {},
    order: null,
    form: {
      deadline: new Date(),
			client: null,
			contact: null,
			description: '',
			works: [],
			paid: [],
			status: '',
			percentOfOrder: null
    },
		newWork: {
			title: '',
			cost: ''
		},
		showProgramComments: false,
		editWorkId: null,
		editWorkData: {
			title: '',
			cost: ''
		},
		orderLink: []
  }),
  computed: {
		clientContacts() {
			const client = this.form.client
			if (client && client.contacts) {
				return client.contacts
			}
			return []
		},
		clients() {
      return this.$store.getters['clients/getClients']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
		fullCost() {
			let works = this.form.works
			let summ = 0
			works.forEach(work => {
				summ = summ + Number(work.cost)
			});
			return summ
		},
		paid() {
			let paid = this.form.paid
			let summ = 0
			paid.forEach(pay => {
				summ = summ + Number(pay.amount)
			});
			return summ
		},
		deadlineText() {
      return new Date(this.form.deadline).toLocaleString('ru', {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' })
    },
		entityReadiness() {
			let links = this.orderLink
			let readyArr = links.map(item => {
				return item.ready
			})
			return !readyArr.includes(false)
		}
  },
  async created() {
		if (!this.clients || this.clients.length === 0) {
      this.$store.dispatch('clients/loadClients')
    }

		this.setDefVal(this.data)
  },
  methods: {
		setDefVal(data) {
			console.log(data)
			if (data && data.id) {
				this.order = data
				this.loadOrderLink(this.order.number)
				this.form.deadline = data.deadline ? data.deadline.toDate() : data.date.toDate()
				this.form.client = data.client ? data.client : null
				this.form.description = data.description || ''
				this.form.status = data.status || 'draft'
				this.form.works = data.works || []
				this.form.paid = data.paid || []
				this.form.percentOfOrder = data.percentOfOrder || null

				if (data.contact) {
					this.form.contact = data.contact
				} else if (data.client && data.client.length > 0) {
					const main = this.form.client.contacts.find(x => x.main)
					if (main) {
						this.form.contact = main
					}
				}
			}
		},
		changeClientContact(val) {
			if (val) {
				this.update({ contact: val })
				let text = `Изменён контакт на: '${val.name}: ${val.phonenumber}'`
				this.programNewComment('contact', {}, text)	
			}
		},
		setMainClientContact() {
			if (this.form.client && this.form.client.contacts) {
				const main = this.form.client.contacts.find(x => x.main)
				if (main) {
					this.form.contact = main

					this.update({ contact: main })
					let text = ''
					if (!this.order.contact) {
						text = `Указан контакт: '${main.name}: ${main.phonenumber}'`
					} else if (this.order.contact) {
						text = `Обновлён контакт: '${main.name}: ${main.phonenumber}'`
					}
					this.programNewComment('contact', {}, text)
				}
			}
		},
		async showClientInfo() {
			const client = await this.$showModal({
        component: Client,
        isPersistent: true,
				props: {
					edit: true,
					data: this.form.client
				}
      })
			if (client) {
				this.form.client = { ...this.form.client, id: client.id, ...client.data }
			}
		},
		async setClient() {
			this.changeCient(this.form.client)
			this.setMainClientContact()
		},
		async newClient() {
			let clientNameRef = this.$refs.clientName.internalSearch

			const createClient = await this.$showModal({
        component: Client,
        isPersistent: true,
				props: {
					data: {
						name: clientNameRef || ''
					}
				}
      })
			if (createClient) {
				let obj = { id: createClient.id, ...createClient.data }
				this.changeCient(obj)
				this.form.client = obj
				this.setMainClientContact()
			}
		},
		async openLink(link) {
			console.log(link)
			switch (link.type) {
				case 'montage':
					let montage = await this.$store.dispatch('montages/loadMontageById', link.id)
					await this.$showModal({
						component: MontageModal,
						isPersistent: true,
						props: {
							edit: true,
							data: montage
						}
					})
					break;
				case 'manufactory':
					let manufactoryTask = await this.$store.dispatch('manufactory/loadTaskById', link.id)
					await this.$showModal({
						component: ManufactoryTaskModal,
						isPersistent: true,
						props: {
							edit: true,
							data: manufactoryTask
						}
					})
					break;
				case 'task':
					let task = await this.$store.dispatch('tasks/loadTaskById', link.id)
					await this.$showModal({
						component: TaskModal,
						isPersistent: true,
						props: {
							edit: true,
							design: task.design,
							data: task
						}
					})
					break;
			}
			setTimeout(() => {
				this.loadOrderLink(this.order.number)
			}, 400)
		},
		async loadOrderLink(number) {
			let orderLink = await this.$store.dispatch('orders/loadOrderLink', number)
			orderLink = orderLink.map(item => {
				let ready = false

				switch (item.type) {
					case 'montage':
						if (['ready', 'no_work'].includes(item.status)) {
							ready = true
						}
						break;
					case 'manufactory':
						if (['ready', 'archive'].includes(item.status)) {
							ready = true
						}
						break;
					case 'task':
						if (['Готово', 'В архиве'].includes(item.status)) {
							ready = true
						}
						break;
				}

				return { ...item, ready: ready }
			})
			this.orderLink = orderLink
		},
		saveEditWork() {
			let indexItem = this.form.works.findIndex(x => x.id === this.editWorkId)
			if (indexItem != -1) {
				let save = { ...this.form.works[indexItem] }
				this.form.works[indexItem] = { ...this.form.works[indexItem], ...this.editWorkData }
				this.update({ works: this.form.works })
				let text = `Изменёна работа с '${save.title} - ${save.cost}р.' на '${this.form.works[indexItem].title} - ${this.form.works[indexItem].cost}р.'`
				this.programNewComment('works', {}, text)	
				this.editWorkId = null
				this.editWorkData = { title: '', cost: '' }
			}
		},
		editWork(work) {
			this.editWorkId = work.id
			this.editWorkData = { title: work.title, cost: work.cost }
		},
		updPercentOfOrder(val) {
			let save = this.form.percentOfOrder
			this.form.percentOfOrder = val
			this.update({ percentOfOrder: this.form.percentOfOrder })
			let text = `Изменён процент менеджера с '${!save ? 0 : save}%' на '${!this.form.percentOfOrder ? 0 : this.form.percentOfOrder}%'`
			this.programNewComment('percentOfOrder', {}, text)
		},
		async newTask(forTask) {
			let newTask = await this.$showModal({
        component: TaskModal,
        isPersistent: true,
        props: {
          edit: false,
          design: forTask === 'design',
          meTask: forTask === 'meTask',
          data: null,
					nmbrOrder: this.order.number,
					clientOrder: this.form.client
        }
      })
			setTimeout(() => {
				if (newTask === 'newTask') {
					let text = `Создана новая задача`
					this.programNewComment('newTask', {}, text)	
				}
				this.loadOrderLink(this.order.number)
			}, 400)
		},
		async newTaskManufactory() {
			let newTaskManufactory = await this.$showModal({
				component: ManufactoryTaskModal,
				isPersistent: true,
				props: {
					edit: false,
					nmbrOrder: this.order.number,
					clientOrder: this.form.client,
					clientContact: this.form.contact
				}
			})
			setTimeout(() => {
				if (newTaskManufactory === 'newTask') {
					let text = `Новая задача на производство`
					this.programNewComment('newManufactoryTask', {}, text)	
				}
				this.loadOrderLink(this.order.number)
			}, 400)
		},
		async newMontage() {
      let newMontage = await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: false,
					nmbrOrder: this.order.number,
					clientOrder: this.form.client,
					clientContact: this.form.contact
        }
      })
			setTimeout(() => {
				if (newMontage === 'newMontage') {
					let text = `Создан новый монтаж`
					this.programNewComment('newMontage', {}, text)	
				}
				this.loadOrderLink(this.order.number)
			}, 400)
    },
		async selectDeadline() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дедлайн',
          withTime: true,
          selectDate: this.form.deadline.toISOString().substr(0, 10)
        }
      })
      if (modalDate) {
        if (modalDate.date && modalDate.time) {
					let save = this.form.deadline
					this.form.deadline = new Date(`${modalDate.date}T${modalDate.time}`)
					this.update({ deadline: this.form.deadline })
					let text = `Дедлайн изменён с ${this.$formatDateTime(save)} на ${this.$formatDateTime(this.form.deadline)}`
					this.programNewComment('deadline', {}, text)
        }
      }
    },
		updStatus(status) {
			let save = this.form.status
			this.form.status = status
			this.update({ status: this.form.status })
			let text = `Статус изменён с '${this.orderStatus[save]}' на '${this.orderStatus[this.form.status]}'`
			this.programNewComment('status', {}, text)
		},
		deletePay(pay) {
			let indexPay = this.form.paid.findIndex(x => x.id === pay.id)
			if (indexPay != -1) {
				this.form.paid.splice(indexPay, 1)
				this.update({ paid: this.form.paid })
				let text = `Удалена оплата: '${this.$formatDate(pay.date.toDate())} - ${pay.amount}р.'`
				this.programNewComment('paid', {}, text)
			}
		},
		newPay(pay) {
			let obj = {
				...pay,
				date: new Date(),
				id: Date.now()
			}
			this.form.paid.push(obj)
			this.update({ paid: this.form.paid })
			let text = `Новая оплата: '${this.orderTypePay[obj.type]} ${obj.amount}р. ${this.$formatDate(obj.date)}'`
			this.programNewComment('pay', {}, text)
		},
		addWork() {
			let validate = this.$refs.formNewWork.validate()
			if (validate) {
				let save = { ...this.newWork }
				this.form.works.push({
					...this.newWork,
					id: Date.now()
				})
				this.newWork = {
					title: '',
					cost: ''
				}
				this.$refs.formNewWork.resetValidation()
				this.update({ works: this.form.works })
				let text = `Новая работа: '${save.title} - ${save.cost}р.'`
				this.programNewComment('works', {}, text)
			}
		},
		deleteWork(id) {
			let indexWork = this.form.works.findIndex(x => x.id === id)
			if (indexWork != -1) {
				let save = { ...this.form.works[indexWork] }
				this.form.works.splice(indexWork, 1)
				this.update({ works: this.form.works })
				let text = `Удалена работа: '${save.title} - ${save.cost}р.'`
				this.programNewComment('works', {}, text)
			}
		},
		async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дедлайн',
          withTime: true,
          selectDate: new Date().toISOString().substr(0, 10)
        }
      })
      if (modalDate) {
        if (modalDate.date && modalDate.time) {
          return new Date(`${modalDate.date}T${modalDate.time}`)
        } else {
          return new Date(modalDate)
        }
      }
    },
		changeCient(client) {
			this.update({ client: client })
			let text = ''
			if (!this.order.client && client.id) {
				text = `Был выбран клиент '${client.name}'`
			} else if (this.order.client && client.id) {
				text = `Клиент '${client.name}' был обновлён`
			} else if (!client.id) {
				text = `Был создан и выбран новый клиент '${client.name}'`
			}
			this.programNewComment('client', {}, text)
		},
		changeDescription(oldVal, newVal) {
			if (this.edit) {
				this.update({ description: newVal })
				this.programNewComment('description', { oldVal, newVal })
			}
		},
    async update(data) {
			console.log(data)
			let obj = {
				...data
			}

			let updatedOrder = await this.$store.dispatch('orders/updateOrder', { id: this.order.id, data: obj })
			this.setDefVal(updatedOrder)

			if (data.archive) {
				this.programNewComment(null, null, '<div class="comment_program_text">Отправил заказ в архив</div>')
			}
    },
		async programNewComment(key, data, text) {
      let obj = {
        text: text ? text : `
					Поле "${this.fieldKeysOrder[key]}" было: <br> 
					<div class="comment_program_text">${data.oldVal}</div> 
					<br> Стало: <br> 
					<div class="comment_program_text">${data.newVal}</div>`,
        files: null,
        date: new Date,
        from: usersCollection.doc(this.user.uid),
        program: true
      }

      await ordersCollection.doc(this.data.id).collection('comments').add(obj)
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    },
		textAreaAutosize(el) {
			el.style.cssText = 'height: 32px'
			el.style.cssText = 'height:' + el.scrollHeight + 'px';
		}
  },
	mounted() {
		let el_des = this.$refs.orderModal__description
		if (el_des) {
			this.textAreaAutosize(el_des)
		}
	}
}
</script>

<style lang="scss">
.orderModal {
	&__field {
		display: block;
		overflow: hidden;
		overflow-wrap: break-word;
		margin-left: -5px;
		padding: 0 5px;
		min-height: 32px;
		resize: none;

		&:hover, &:focus {
			background-color: rgba(236, 236, 236, 0.829);
			border-radius: 4px;
		}
		&:focus {
			outline-color: #2196F3;
		}
	}
	
	&__description {
		background-color: rgba(236, 236, 236, 0.541);
		border-radius: 4px;
		min-height: 100px !important;
		width: 100%;
		padding: 5px 5px;
		line-height: 1.4 !important;
		color: rgb(77, 75, 75);

		&:disabled {
			background-color: white;
			min-height: auto !important;
		}
	}
}
</style>
