<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable width="290" persistent>
        <v-card class="selectPerformers" width="290">
          <v-card-title>
            <span>Выберите исполнителей</span>
          </v-card-title>

          <v-card-text style="max-height: 300px;">
            <v-radio-group
              :error-messages="errorBrig"
              label="Бригады:"
              class="selectPerformers_radio ma-0 pa-0"
              column
            >
              <template v-for="(brigItem, index_brig) in brigs">
                <div class="mb-2" :key="index_brig">
                  <v-checkbox
                    v-model="brig"
                    @change="changeBrig(brigItem)"
                    color="#e0a677"
                    :label="brigItem.displayName"
                    :value="brigItem.id"
                    class="pa-0 ma-0"
                  ></v-checkbox>
                  <v-text-field
                    v-if="brig === brigItem.id && !getHourPayWorkMasters"
                    :key="`cost_${index_brig}`"
                    v-model="priceForBrig"
                    :placeholder="`Стоимость для ${brigItem.displayName}`"
                    @change="changePriceBrig(brigItem, priceForBrig)"
                    type="number"
                    suffix="р."
                    class="pa-0"
                    color="#e0a677"
                  />
                </div>
              </template>
            </v-radio-group>

            <v-radio-group :key="updateKey" column label="Монтажники:" class="selectPerformers_radio ma-0 mt-4 pa-0">
              <template v-for="(montager, index_montager) in users">
                <div class="mb-2" :key="index_montager">
                  <v-checkbox
                    v-model="montagers"
                    @change="changeMontagers(montager)"
                    :key="`check_${index_montager}`"
                    color="#e0a677"
                    :label="`${montager.displayName}${montager.brig ? `(${montager.brig.displayName})` : ''}`"
                    :value="montager.uid"
                    class="pa-0 ma-0"
                  />
                  <!-- :disabled="montager.brig && montager.brig.id === brig" -->
                  <v-text-field
                    v-if="montagers.includes(montager.uid) && !getHourPayWorkMasters"
                    :key="`cost_${index_montager}`"
                    v-model="montagersInfo[montager.uid].costMontage"
                    :placeholder="`Стоимость для ${montager.displayName}`"
                    type="number"
                    suffix="р."
                    class="pa-0"
                    color="#e0a677"
                  />
                </div>
              </template>
            </v-radio-group>
          </v-card-text>

          <v-card-actions>
            <v-btn color="orange" text @click="close()">Отмена</v-btn>
            <v-spacer />
            <v-btn color="blue" text @click="go()">Продолжить</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { brigsCollection } from '@/plugins/firebase'
import { RoleText } from '@/helpers'
var cloneDeep = require('lodash.clonedeep');

export default {
  props: {
    data: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    roleText: RoleText,
    dialog: true,
    brig: null,
    montagers: [],
    montagersInfo: {},
    priceForBrig: 0,
    updateKey: 0,
    errorBrig: []
  }),
  created() {
    if (this.data) {
      this.montagers = cloneDeep(this.data.montagers)
      this.montagersInfo = cloneDeep(this.data.montagersInfo)
      this.brig = this.data.brig.id
      this.priceForBrig = this.data.priceForBrig
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getWorkers']
    },
    brigs() {
      return this.$store.getters['brigs/getBrigs']
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    }
  },
  methods: {
    changePriceBrig(brig, price) {
      if (brig.users) {
        let priceForMontager = price / brig.users.length
        brig.users.forEach(user => {
          this.montagersInfo[user.uid].costMontage = priceForMontager
        })
        this.updateKey = this.updateKey + 1
      }
    },
    changeBrig(brig) {
      if (this.brig === brig.id) {
        this.errorBrig = []
        if (brig.users) {
          brig.users.forEach(user => {
            if (!this.montagers.includes(user.uid)) {
              this.montagers.push(user.uid)
            }
            if (this.montagersInfo[user.uid]) {
              this.montagersInfo[user.uid] = { ...this.montagersInfo[user.uid], name: user.displayName,  costMontage: 0 }
            } else {
              this.montagersInfo[user.uid] = { costMontage: 0, name: user.displayName }
            }
          })
        }
      } else {
        if (brig.users) {
          this.priceForBrig = 0
          brig.users.forEach(user => {
            if (this.montagers.includes(user.uid)) {
              let index = this.montagers.findIndex(x => x === user.uid)
              if (index != -1) {
                this.montagers.splice(index, 1)
              }
            }
            if (this.montagersInfo[user.uid]) {
              delete this.montagersInfo[user.uid]
            }
          })
        }
      }
    },
    changeMontagers(montager) {
      let uid = montager.uid
      let name = montager.displayName
      if (!this.montagers.includes(uid) && this.montagersInfo[uid]) {
        delete this.montagersInfo[uid]
      } else if (this.montagers.includes(uid)) {
        if (this.montagersInfo[uid]) {
          this.montagersInfo[uid] = { ...this.montagersInfo[uid], name: name, costMontage: 0 }
        } else {
          this.montagersInfo[uid] = { name: name, costMontage: 0 }
        }
      }
    },
    go() {
      setTimeout(() => {
        let brig = null
        if (this.brig) {
          brig = brigsCollection.doc(this.brig)
          this.$emit('close', { 
            brig: brig,
            priceForBrig: this.priceForBrig,
            montagers: this.montagers,
            montagersInfo: this.montagersInfo,
            hourPayWorkMasters: this.getHourPayWorkMasters
          })
          this.dialog = false
        } else {
          this.errorBrig = 'Выберите бригаду'
        }
      }, 200)
    },
    close() {
      setTimeout(() => {
        this.$emit('close')
        this.dialog = false
      }, 200)
    }
  }
}
</script>

<style lang="scss">
.selectPerformers {
  &_radio {
    .v-input--checkbox {
      .v-messages {
        display: none;
      }
    }
    .v-input__slot {
      margin: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>