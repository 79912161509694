<template>
  <span :class="imageViewClass">
    <div
      :class="{
        'image-view__body--focused': selected,
        'image-view__body--resizing': resizing,
      }"
      class="image-view__body"
    >
			<div 
				:style="`
					width: ${width}px;
					height: ${height}px;
					min-width: 230px;
					min-height: 170px;
				`"
				class="
					pa-3
					d-flex
					justify-center
					align-center
				"
			>
				<v-img
					v-if="srcUrl"
					:src="srcUrl"
					:title="node.attrs.title"
					:alt="node.attrs.alt"
					:aspect-ratio="width / height"
					:contain="editableMode"
					:width="width - 15"
					:height="height - 15"
					class="image-view__body__image"
					@click="selectImage"
				/>
				<div
					v-else
					:style="`
						width: 100%;
						height: 100%;
						border: 1px solid grey;
						border-radius: 5px;
					`"
					class="d-flex justify-center align-center"
				>
					<span>Загрузка...</span>
				</div>
			</div>

      <div
        v-if="view.editable"
        v-show="selected || resizing"
        class="image-resizer"
      >
        <span
          v-for="direction in resizeDirections"
          :key="direction"
          :class="`image-resizer__handler--${direction}`"
          class="image-resizer__handler"
          @mousedown="onMouseDown($event, direction)"
        />
      </div>

      <!-- <el-popover
        :value="selected"
        :visible-arrow="false"
        placement="top"
        trigger="manual"
        popper-class="el-tiptap-image-popper"
      >
        <image-bubble-menu
          :node="node"
          :view="view"
          :update-attrs="updateAttrs"
        />

        <div
          slot="reference"
          class="image-view__body__placeholder"
        />
      </el-popover> -->
    </div>
  </span>
</template>

<script>
import { resolveImg, clamp } from './imageUtils.js'
import { ResizeObserver } from '@juggle/resize-observer';
import { NodeSelection } from 'prosemirror-state';

const ResizeDirection = {
  TOP_LEFT: 'tl',
  TOP_RIGHT: 'tr',
  BOTTOM_LEFT: 'bl',
  BOTTOM_RIGHT: 'br',
};
const MIN_SIZE = 20;
const MAX_SIZE = 100000;

export default {
	props: [
		'node',
		'view',
		'getPos',
		'updateAttrs',
		'selected'
	],
	data: () => ({
		maxSize: {
			width: MAX_SIZE,
    	height: MAX_SIZE,
		},
		originalSize: {
			width: 0,
			height: 0
		},
		resizeDirections: [
			ResizeDirection.TOP_LEFT,
			ResizeDirection.TOP_RIGHT,
			ResizeDirection.BOTTOM_LEFT,
			ResizeDirection.BOTTOM_RIGHT,
		],
		resizing: false,
		resizerState: {
			x: 0,
			y: 0,
			w: 0,
			h: 0,
			dir: ''
		},
		srcUrl: ''
	}),
	async created() {
		let src = this.node.attrs.src
		if (src && !src.includes('http')) {
			this.srcUrl = await this.$getFbFileUrl(src)
		} else if (src && src.includes('http')) {
			this.srcUrl = src
		}

		const result = await resolveImg(this.srcUrl);
    if (!result.complete) {
      result.width = MIN_SIZE;
      result.height = MIN_SIZE;
    }
    this.originalSize = {
      width: result.width,
      height: result.height,
    };
		if (!this.node.attrs.height || !this.node.attrs.width) {
			this.updateAttrs({
				width: clamp(this.originalSize.width, 100, 300),
				height: clamp(this.originalSize.height, 100, 300)
			});
		}
	},
	computed: {
		editableMode() {
			return this.$parent.editor.view.editable
		},
		resizeOb() {
			let _this = this
			return new ResizeObserver(() => {
				const { width } = getComputedStyle(this.view.dom);
				_this.maxSize.width = parseInt(width, 10);
			})
		},
		width() {
			if (!this.node.attrs.height || !this.node.attrs.width) {
				return this.originalSize.width
			}
			return this.node.attrs.width
		},
		height() {
			if (!this.node.attrs.height || !this.node.attrs.width) {
				return this.originalSize.height
			}
			return this.node.attrs.height
		},
		display() {
			return this.node.attrs.display
		},
		imageViewClass() {
			return [
				'image-view',
				`image-view--${this.display}`,
			];
		}
	},
	methods: {
		selectImage() {
			const { state } = this.view;
			let { tr } = state;
			const selection = NodeSelection.create(state.doc, this.getPos());
			tr = tr.setSelection(selection);
			this.view.dispatch(tr);
		},
		onMouseDown(e, dir) {
			e.preventDefault();
			e.stopPropagation();
			this.resizerState.x = e.clientX;
			this.resizerState.y = e.clientY;
			const originalWidth = this.originalSize.width;
			const originalHeight = this.originalSize.height;
			const aspectRatio = originalWidth / originalHeight;
			let { width, height } = this.node.attrs;
			const maxWidth = this.maxSize.width;
			if (width && !height) {
				width = width > maxWidth ? maxWidth : width;
				height = Math.round(width / aspectRatio);
			} else if (height && !width) {
				width = Math.round(height * aspectRatio);
				width = width > maxWidth ? maxWidth : width;
			} else if (!width && !height) {
				width = originalWidth > maxWidth ? maxWidth : originalWidth;
				height = Math.round(width / aspectRatio);
			} else {
				width = width > maxWidth ? maxWidth : width;
			}
			this.resizerState.w = width;
			this.resizerState.h = height;
			this.resizerState.dir = dir;
			this.resizing = true;
			this.onEvents();
		},
		onMouseMove(e) {
			e.preventDefault();
			e.stopPropagation();
			if (!this.resizing) return;
			const { x, y, w, h, dir } = this.resizerState;
			const dx = (e.clientX - x) * (/l/.test(dir) ? -1 : 1);
			const dy = (e.clientY - y) * (/t/.test(dir) ? -1 : 1);
			this.updateAttrs({
				width: clamp(w + dx, MIN_SIZE, this.maxSize.width),
				height: Math.max(h + dy, MIN_SIZE),
			});
		},
		onMouseUp(e) {
			e.preventDefault();
			e.stopPropagation();
			if (!this.resizing) return;
			this.resizing = false;
			this.resizerState = {
				x: 0,
				y: 0,
				w: 0,
				h: 0,
				dir: '',
			};
			this.offEvents();
			this.selectImage();
		},
		onEvents() {
			document.addEventListener('mousemove', this.onMouseMove, true);
			document.addEventListener('mouseup', this.onMouseUp, true);
		},
  	offEvents() {
			document.removeEventListener('mousemove', this.onMouseMove, true);
			document.removeEventListener('mouseup', this.onMouseUp, true);
		}
	},
	mounted() {
		this.resizeOb.observe(this.view.dom)
	},
	beforeDestroy() {
		this.resizeOb.disconnect()
	}
}
</script>