import { deductionsCollection, usersCollection } from '@/plugins/firebase'

export default {
    namespaced: true,
    state: {
      deductions: []
    },
    getters: {
        getDeductions: state => {
            const sortedDeductions = state.deductions.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            })
            return sortedDeductions
        }
    },
    mutations: {
        setDeductions(state, payload) {
            state.deductions = payload;
        }
    },
    actions: {
        async deleteDeductions({ commit, rootState }, data) {
            commit('templ/setLoading', true, { root: true })
            await deductionsCollection.doc(data.id).update({ del: true })
                .then(() => {
                    console.log('Удержание удалено')
                }).catch((err) => {
                    commit('templ/setError', 'Ошибка удаления удержания, попробуйте позже.')
                    console.log(err)
                }).finally(() => {
                    commit('templ/setLoading', false, { root: true })
                })
        },
        async createDeductions({ commit, dispatch, rootGetters }, data) {
            commit('templ/setLoading', true, { root: true })
            let filterDate = rootGetters['users/getFilterUserDataByMonth']
            let date = new Date(filterDate), y = date.getFullYear(), m = date.getMonth()
            filterDate = new Date(y, m + 1, 0, 23, 59)
            let toDay = new Date()

            if (filterDate.getMonth() != toDay.getMonth()) {
                date = new Date(filterDate.toISOString().substr(0, 10))
            } else {
                date = new Date(toDay.toISOString().substr(0, 10))
            }

            const obj = { 
                ...data,
                date: date
            }
            await deductionsCollection.add(obj)
            commit('templ/setLoading', false, { root: true })
        },
        async loadDeductions({ commit, dispatch }, data) {
            commit('templ/setLoading', true, { root: true })

            let userRef = await usersCollection.doc(data.uid)

            let queryStore = deductionsCollection.where('user', '==', userRef)

            if (data.month) {
                let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
                let firstDay = new Date(y, m, 1, 0)
                let lastDay = new Date(y, m + 1, 0, 23, 59)
                queryStore = queryStore.orderBy('date').startAt(firstDay).endAt(lastDay);
            }

            queryStore.onSnapshot(async snapshot => {
                let deductionsArr = []
                for (let deductionDoc of snapshot.docs) {
                    let deduction = { ...deductionDoc.data(), id: deductionDoc.id }
                    if (deduction.user) {
                        let user = await dispatch('users/userSerializer', deduction.user, { root: true })
                        deduction.user = user
                    }
                    deductionsArr.push(deduction)
                }
                deductionsArr = deductionsArr.filter(x => !x.del)
                
                commit('setDeductions', deductionsArr)
                commit('templ/setLoading', false, { root: true })
            })
        },
    }
}