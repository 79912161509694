<template>
  <v-row class="taskModal" justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '800px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
    >
      <v-card :tile="isMobile">
        <v-card-title>
					<textarea
						:disabled="task && task.archive || (edit && taskFrom && taskFrom.uid != user.uid)"
						:value="form.title"
						rows="1"
						class="headline taskModal__field taskModal__title"
						ref="taskModal__title"
						placeholder='Название задачи'
						@input="textAreaAutosize($event.target)"
						@change="changeTitle(form.title, $event.target.value)"
					/>
          <v-spacer />
          <div class="d-flex align-start" style="height: 100%;">
            <v-btn icon color="#e0a677" @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8" class="pt-0" style="overflow: scroll; height: 69vh;">
              <v-container class="pa-0">
                <v-list class="pa-0">
                  <v-list-item class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="px-2">
                      <v-list-item-title style="font-size: 1.3em; line-height: 1.9em;">Описание</v-list-item-title>
											<textarea
												:disabled="task && task.archive || (edit && taskFrom && taskFrom.uid != user.uid)"
												:value="form.description"
												rows="1"
												class="headline taskModal__field taskModal__description"
												ref="taskModal__description"
												placeholder='Описание задачи'
												@input="textAreaAutosize($event.target)"
												@change="changeDescription(form.description, $event.target.value)"
											/>
                    </v-list-item-content>
                  </v-list-item>
									<v-list-item class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="px-2">
                      <v-list-item-title style="font-size: 1.3em; line-height: 1.9em;">Файлы</v-list-item-title>
											<v-row no-gutters>
												<v-col cols="12" class="py-0">
													<v-btn :disabled="task && task.archive || (edit && taskFrom && taskFrom.uid != user.uid)" @click="uploadImage()" :loading="uploadingImage" width="100%">
														<v-icon>mdi-cloud-upload</v-icon>
														<span class="pl-2">Загрузить фото</span>
													</v-btn>
												</v-col>
												<v-col cols="12" class="py-0">
													<v-container fluid class="pa-0">
														<v-row>
															<v-col
																v-for="(image, index_image) in form.images"
																:key="index_image"
																class="d-flex child-flex"
																cols="4"
															>
																<my-preview-image :disTrach="edit && taskFrom && taskFrom.uid != user.uid" :fbPath="image" @delete="deleteImage" />
															</v-col>
														</v-row>
													</v-container>
												</v-col>
											</v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="edit" class="px-0">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="26">mdi-comment-text-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="px-2">
                      <v-list-item-title class="d-flex align-center" style="font-size: 1.3em; line-height: 1.9em;">
												Комментарии
												<v-spacer />
												<v-btn
													x-small
													outlined
													@click="showProgramComments = !showProgramComments"
												>
													<span v-if="showProgramComments">Скрыть подробности</span>
													<span v-else>Показать подробности</span>
												</v-btn>
											</v-list-item-title>
                      <Comments
												:task_id="data.id"
												:task="task"
												:showProgramComment="showProgramComments"
											/>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-col>
            <v-col cols="4" class="py-0" style="border-left: solid 2px grey;">
							<RightBar
								:edit="edit"
								:task="task"
								:user="user"
								:taskFrom="taskFrom"
								:form="form"
								:users="users"
								:numberOrder="numberOrder"
								:design="design || task && task.design || false"
								:meTask="meTask || task && task.meTask || false"
								@update="update($event)"
								@changeWhoTo="changeWhoTo($event)"
							/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import Comments from './components/Comments/Index.vue'
import RightBar from './components/RightBar/Index.vue'
import SelectDate from '@/components/Modals/SelectDate'
import imageCompression from 'browser-image-compression'
import Text from '@/components/Modals/Text.vue'

import { tasksCollection, usersCollection, clientsCollection } from '@/plugins/firebase'
import { fieldKeysTasks } from '@/helpers'
import { DatePicker } from 'element-ui';

export default {
  components: {
    Comments,
		RightBar,
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
		design: {
			type: Boolean,
      default: false
		},
		meTask: {
			type: Boolean,
      default: false
		},
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
		nmbrOrder: {
      type: String,
      default: null
    },
		clientOrder: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isMobile: isMobile,
    fieldKeysTasks: fieldKeysTasks,
    dialog: true,
    errors: {},
    task: null,
    form: {
      deadline: new Date(),
      title: '',
			whoTo: [],
    	whoToData: {},
			description: '',
			images: [],
			client: null
    },
		numberOrder: null,
    taskFrom: null,
		showProgramComments: false,
		uploadingImage: false
  }),
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
		users() {
      if (this.design || (this.task && this.task.design)) {
        return this.$store.getters['users/getDesignerUsers']
      }
      return this.$store.getters['users/getOfficeUsers']
    },
  },
  async created() {
		if (this.nmbrOrder) {
			this.numberOrder = this.nmbrOrder
		}

		if (this.clientOrder && this.clientOrder.name) {
			this.form.title = this.clientOrder.name
			console.log(this.clientOrder)
			if (this.clientOrder.id) {
				this.form.client = clientsCollection.doc(this.clientOrder.id)
			}
		}

    this.setData(this.data)
		this.autoUpdate()
  },
  methods: {
		autoUpdate() {
			if (this.edit) {
				tasksCollection.doc(this.data.id).onSnapshot(async (doc) => {
					await this.setData({ ...doc.data(), id: doc.id  })
				});
			}
		},
		async setData(data) {
			console.log(data)
			if (data && data.id) {
				this.task = data
				this.numberOrder = data.numberOrder || null
				this.form.deadline = data.deadline.toDate()
				this.form.title = data.title
				this.form.description = data.description || ''
				this.form.whoTo = data.whoTo
				this.form.whoToData = data.whoToData
				this.form.images = data.images || []

				if (data.from && data.from.firestore) {
					let getUser = await data.from.get()
					this.taskFrom = getUser.data()
				}
				this.form = this.form
			}
		},
		async deleteImage(path) {
      let fileIndex = this.form.images.findIndex(x => x === path)
      if (fileIndex != -1) {
        let delFile = await this.$delFbFile(path)
        if (delFile) {
          this.form.images.splice(fileIndex, 1)

					this.$store.dispatch('tasks/updateTask', { id: this.task.id, data: { images: this.form.images } })
					this.programNewComment(null, null, '<div class="comment_program_text">Удалено(ы) изображение</div>')
        }
      }
    },
		async uploadImage() {
			this.uploadingImage = true

      const input = document.createElement("input")
      input.type = "file";
      input.accept='image/*'
      input.multiple='multiple'

      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1520,
        useWebWorker: true
      }

      input.onchange = async (e) => {
          var files = await e.target.files

          for(let key in files) {
            let file = files[key]
            if (file && file instanceof File) {
              let compressedFile = await imageCompression(file, options)

              let filePath = `task/${new Date().getTime()}_${key}.${/[^.]+$/.exec(file.name)}`

              this.form.images.push({
								uploading: true,
								filePath: filePath
							})

              let ref = this.$storage.ref().child(filePath).put(compressedFile)
              ref.on(`state_changed`, snapshot => {}, error => {
                console.log(error)
              }, () => {
                let fileIndex = this.form.images.findIndex(x => x.uploading && x.filePath === filePath)
                if (fileIndex != -1) {
                  this.$set(this.form.images, fileIndex, filePath)
                }
								this.uploadingImage = false
								if (this.edit) {
									this.$store.dispatch('tasks/updateTask', { id: this.task.id, data: { images: this.form.images } })
									this.programNewComment(null, null, '<div class="comment_program_text">Загружено(ы) новое изображение</div>')
								}
              })
            }
          }
      };

      input.click();
    },
		async selectDate(date) {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дедлайн',
          withTime: true,
          selectDate: date ? date.toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
        }
      })
      if (modalDate) {
        if (modalDate.date && modalDate.time) {
          return new Date(`${modalDate.date}T${modalDate.time}`)
        } else {
          return new Date(modalDate)
        }
      }
    },
		changeTitle(oldVal, newVal) {
			if (this.edit) {
				this.update({ title: newVal })
				this.programNewComment('title', { oldVal, newVal })
			}
			this.form.title = newVal
		},
		changeDescription(oldVal, newVal) {
			if (this.edit) {
				this.update({ description: newVal })
				this.programNewComment('description', { oldVal, newVal })
			}
			this.form.description = newVal
		},
		changeWhoTo(uid) {
			if (!this.form.whoTo.includes(uid)) {
				this.form.whoTo.push(uid)
				let user = this.users.find(x => x.uid === uid)
				this.form.whoToData[uid] = {
					displayName: user.displayName,
          email: user.email,
          avatar: user.avatar || null,
          del: user.del,
          role: user.role
				}
			} else if (this.form.whoToData[uid] && this.form.whoTo.includes(uid)) {
				let indexWhoTo = this.form.whoTo.findIndex(x => x === uid)
				this.form.whoTo.splice(indexWhoTo, 1)
				delete this.form.whoToData[uid]
			}

			if (this.edit) {
				this.update({ whoTo: this.form.whoTo, whoToData: this.form.whoToData })
			}
		},
		// let reqData = { ...data }
		// 	if (!reqData.ready && !this.edit) {
		// 		reqData = {
		// 			...reqData,
		// 			...this.form,
		// 			design: Boolean(this.design)
		// 		}
		// 	}
		async newTask(data) {
			let deadline = await this.selectDate()
			if (deadline) {
				let obj = {
          // from добавляется в экшене в сторе
          // accepted добавляется в экшене в сторе
          // del добавляется в экшене в сторе
          deadline: deadline,
          title: this.form.title,
          description: this.form.description,
          whoTo: this.form.whoTo,
          whoToData: this.form.whoToData,
          images: this.form.images,
          design: this.design,
          meTask: this.meTask,
					numberOrder: this.numberOrder,
					client: this.form.client,
					...data
        }

				if (obj.meTask) {
					if (this.user) {
						obj.whoTo.push(this.user.uid)
						obj.whoToData[this.user.uid] = {
							displayName: this.user.displayName,
							email: this.user.email,
							avatar: this.user.avatar || null,
							del: this.user.del,
							role: this.user.role
						}
					}

					this.$store.dispatch('meTasks/createTask', obj)
				} else {
					this.$store.dispatch('fromMeTasks/createTask', obj)
				}

				this.close('newTask')
			}
		},
    async update(data) {
			if (!this.edit && !data.ready) {
				console.log(this.form)
				if ((this.design || !this.design && !this.meTask) && this.form.whoTo.length === 0) {
					this.$showModal({
						component: Text,
						isPersistent: true,
						props: {
							text: 'Укажите участников!'
						}
					})
				} else {
					this.newTask(data)
				}
			} else {
				let deadline = null
				if ((data.ready != undefined && data.ready === false) || data.deadline) {
					deadline = await this.selectDate(data.deadline)
					if (!deadline) {
						return
					}
				}
				let obj = {
					...data
				}
				if (deadline != null) {
					obj.deadline = deadline
				}

				this.$store.dispatch('tasks/updateTask', { id: this.task.id, data: obj })
				this.task = { ...this.task, ...obj }
				this.form = { ...this.form, ...obj }

				if (data.ready) {
					this.programNewComment(null, null, '<div class="comment_program_text">Изменил статус на "Готово"</div>')
				}
				if (data.ready != undefined && data.ready === false && data.print) {
					this.programNewComment(null, null, '<div class="comment_program_text">Изменил статус на "В печать"</div>')
				}
				if (data.ready != undefined && data.ready === false) {
					this.programNewComment(null, null, '<div class="comment_program_text">Изменил статус на "В работе"</div>')
				}
				if (data.deadline) {
					this.programNewComment(null, null, '<div class="comment_program_text">Обновил дедлайн</div>')
				}
				if (data.archive) {
					this.programNewComment(null, null, '<div class="comment_program_text">Отправил задачу в архив</div>')
				}
				// if (this.user.role === 'designer' && data.ready || data.archive) {
				//   this.close()
				// }
			}
    },
		async programNewComment(key, data, text) {
			if (this.edit) {
				let obj = {
					text: text ? text : `
						Поле "${this.fieldKeysTasks[key]}" было: <br> 
						<div class="comment_program_text">${data.oldVal}</div> 
						<br> Стало: <br> 
						<div class="comment_program_text">${data.newVal}</div>`,
					files: null,
					date: new Date,
					from: usersCollection.doc(this.user.uid),
					program: true
				}

				await tasksCollection.doc(this.data.id).collection('comments').add(obj)
			}
    },
    close(info) {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close', info)
      }, 200)
    },
		textAreaAutosize(el, plus = 0) {
			el.style.cssText = 'height: 32px'
			el.style.cssText = `height: ${Number(el.scrollHeight) + plus}px`;
		}
  },
	mounted() {
		let taskModalTitle = this.$refs['taskModal__title']
		this.textAreaAutosize(taskModalTitle)

		let taskModalDescr = this.$refs['taskModal__description']
		this.textAreaAutosize(taskModalDescr)
	}
}
</script>

<style lang="scss">
.taskModal {
	&__field {
		display: block;
		overflow: hidden;
		overflow-wrap: break-word;
		margin-left: -5px;
		padding: 0 5px;
		min-height: 32px;
		resize: none;

		&:hover, &:focus {
			background-color: rgba(236, 236, 236, 0.829);
			border-radius: 4px;
		}
		&:focus {
			outline-color: #2196F3;
		}
	}

	&__title {
		width: 95%;

		&:disabled {
			background-color: white;
		}
	}
	
	&__description {
		background-color: rgba(236, 236, 236, 0.541);
		border-radius: 4px;
		min-height: 100px !important;
		width: 100%;
		padding: 5px 5px;
		line-height: 1.4 !important;
		color: rgb(77, 75, 75);

		&:disabled {
			background-color: white;
			// min-height: auto !important;
		}
	}
}
</style>
