import { sortList } from '@/helpers'
import { 
	clientsCollection,
	usersCollection
} from '@/plugins/firebase'

import filterClients from './filter'

export default {
    namespaced: true,
    state: {
			clients: [],
			searchClients: '',
			// filters
			...filterClients.state
    },
    getters: {
			getClients: state => {
				let clients = state.clients
				let search = state.searchClients.toLowerCase()
				if (search) {
					clients = clients.filter(x => {
						if (x.name) {
							let name = x.name.toLowerCase()
							if (name.includes(search)) { return true }
						}

						if (x.contacts) {
							for(let contact of x.contacts) {
								let contactName = contact.name.toLowerCase()
								if (contactName.includes(search)) { return true }
								
								let phonenumber = String(contact.phonenumber)
								if (phonenumber.length > 0) {
									phonenumber = phonenumber.replace('(', '')
									phonenumber = phonenumber.replace(')', '')
									phonenumber = phonenumber.replace('-', '')
									phonenumber = phonenumber.replace('-', '')
									phonenumber = phonenumber.replace(/\s/g, '')
									if (phonenumber.includes(search)) { return true }
								}
							}
						}
						return false
					})
				}
				
				return sortList(clients, 'name')
			},
			getSearchClients: state => {
					return state.searchClients
			},
			// filters
			...filterClients.getters
    },
    mutations: {
			setClients(state, payload) {
				state.clients = payload;
			},
			setSearchClients(state, payload) {
				state.searchClients = payload;
			},
			// filters
			...filterClients.mutations
    },
    actions: {
        loadClients({ commit, getters }) {
            commit('templ/setLoading', true, { root: true })
            let queryStore = clientsCollection.where('del', '!=', true)

						let filterByManager = getters.getFilterClientByManager
						if (filterByManager && filterByManager.uid) {
							let managerRef = usersCollection.doc(filterByManager.uid)
							queryStore = queryStore.where('creator', '==', managerRef)
						}
						
						let filterByActivitys = getters.getFilterClientByActivitys
						if (filterByActivitys && filterByActivitys.length > 0) {
							queryStore = queryStore.where('companyActivity', 'in', filterByActivitys)
						}

						let filterByWhereFrom = getters.getFilterClientByWhereFrom
						if (filterByWhereFrom && filterByWhereFrom.length > 0) {
							queryStore = queryStore.where('whereFrom', '==', filterByWhereFrom)
						}

            queryStore.onSnapshot(async snapshot => {
                let clientsArr = []

                for (let clientDoc of snapshot.docs) {
                    let client = { ...clientDoc.data(), id: clientDoc.id }
                    clientsArr.push(client)
                }

                commit('setClients', clientsArr)
                commit('templ/setLoading', false, { root: true })
            })
        },
        async createClient({ commit, dispatch, rootGetters }, payload) {
            commit('templ/setLoading', true, { root: true })

						let user = rootGetters['user/getUser']
						let userRef = usersCollection.doc(user.uid)

						let data = {
							...payload,
							created: new Date(),
							del: false
						}

						if (data.creator) {
							let creator = usersCollection.doc(data.creator.uid)
							data = {
								...data,
								creator: creator,
								creatorData: {
									uid: data.creator.uid,
									del: data.creator.del,
									displayName: data.creator.displayName,
									email: data.creator.email,
									location: data.creator.location
								},
							}
						} else {
							data = {
								...data,
								creator: userRef,
								creatorData: {
									uid: user.uid,
									del: user.del,
									displayName: user.displayName,
									email: user.email,
									location: user.location
								}
							}
						}

						// Проверяем есть ли такой вид деятельности и откуда пришёл. Если нет создаём
						// if (data.companyActivity) {
						// 	await dispatch('settings/checkCompanyActivity', data.companyActivity, { root: true })
						// }
						// if (data.whereFrom) {
						// 	await dispatch('settings/checkCompanyWhereFrom', data.whereFrom, { root: true })
						// }

            let newClient = await clientsCollection.add(data);
            commit('templ/setLoading', false, { root: true })
						return newClient
        },
        async updateClient({ commit, dispatch, rootGetters }, payload) {
            commit('templ/setLoading', true, { root: true })
						let user = rootGetters['user/getUser']
						let userRef = usersCollection.doc(user.uid)

						console.log(payload)

            let data = { ...payload.data }

						if (data.creator) {
							let creator = usersCollection.doc(data.creator.uid)
							data = {
								...data,
								creator: creator,
								creatorData: {
									uid: data.creator.uid,
									del: data.creator.del,
									displayName: data.creator.displayName,
									email: data.creator.email,
									location: data.creator.location
								},
							}
						} else {
							data = {
								...data,
								creator: userRef,
								creatorData: {
									uid: user.uid,
									del: user.del,
									displayName: user.displayName,
									email: user.email,
									location: user.location
								}
							}
						}

						if (data.companyActivity) {
							await dispatch('settings/checkCompanyActivity', data.companyActivity, { root: true })
						}
						if (data.whereFrom) {
							await dispatch('settings/checkCompanyWhereFrom', data.whereFrom, { root: true })
						}

            if (Object.keys(data).length > 0) {
                await clientsCollection.doc(payload.id).update(data)
                    .then(() => {
                        console.log('Клиент обновлён')
                    }).catch((err) => {
                        commit('templ/setError', 'Ошибка обнолвения клиента, попробуйте позже.')
                        console.log(err)
                    }).finally(() => {
                        commit('templ/setLoading', false, { root: true })
                    })
            }
            commit('templ/setLoading', false, { root: true })
        },
        async deleteClient({ commit, rootState }, data) {
            commit('templ/setLoading', true, { root: true })
            await clientsCollection.doc(data.id).update({ del: true })
              .then(() => {
                  console.log('Клиент удалён')
              }).catch((err) => {
                  commit('templ/setError', 'Ошибка удаления клиента, попробуйте позже.')
                  console.log(err)
              }).finally(() => {
                  commit('templ/setLoading', false, { root: true })
              })
        },
    }
}