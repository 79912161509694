<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="850px">
        <v-card style="position: relative;">
          <v-card-text v-if="image || images" class="pa-0">
            <template v-if="image && !images">
              <v-img
                :src="image"
                class="grey lighten-2"
                style="min-height: 300px; max-height: 90vh;"
								contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </template>
            <template v-if="images">
              <v-carousel
                v-model="activImg"
                :show-arrows="images.length > 1"
                hide-delimiters
              >
                <v-carousel-item
                  v-for="(img, index_img) in images"
                  :key="index_img"
                >
                  <template v-slot:default>
                    <div style="height: 100%;" class="d-flex align-center justify-center">
                      <v-img
                        :src="img"
                        max-height="500"
                        class="grey lighten-2"
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </template>
                </v-carousel-item>
                <!-- <v-carousel-item
                  v-for="(item,i) in images"
                  :key="i"
                  :src="item"
                /> -->
              </v-carousel>
            </template>
          </v-card-text>
					<v-btn style="position: absolute; right: 5px; top: 5px;" icon color="#bf4037" large @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    dialog: true,
    activImg: 0
  }),
  created() {
    if (this.images && this.images.length > 0 && this.image) {
      let indexImg = this.images.findIndex(x => x === this.image)
      this.activImg = indexImg
    }
    let html = document.getElementsByTagName('html')[0]
    html.classList.add('overflow-y-hidden')
  },
  beforeDestroy() {
    let html = document.getElementsByTagName('html')[0]
    html.classList.remove('overflow-y-hidden')
  },
  methods: {
    close() {
      this.$emit('close', false)
    }
  }
}
</script>
