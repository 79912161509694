<template>
  <v-row class="ma-0 px-1 py-2">
    <v-col cols="9" class="pa-0 px-4">
      <v-row>
				<v-col cols="12" class="mb-2 pa-0">
          <WidgetTabsTask />
        </v-col>
				<v-col cols="12" class="mb-2 pa-0">
          <WidgetMyOrders />
        </v-col>
				<v-col cols="12" class="mb-2 pa-0">
          <ManagerTasks />
        </v-col>
				<v-col cols="12" class="mb-2 pa-0">
          <ManagerUnassignedMontages />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="3" class="pa-0 px-4">
      <v-row>
				<v-col cols="12" class="pa-0">
          <WidgetAssignedMontagesInBrig />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import WidgetMyOrders from '@/components/Orders/Widgets/MyOrders.vue'
import WidgetTabsTask from '@/components/Tasks/Widgets/TabsTask.vue'
import ManagerUnassignedMontages from '@/components/Montages/ManagerUnassignedMontages.vue'
import WidgetAssignedMontagesInBrig from '@/components/Montages/WidgetAssignedMontagesInBrig.vue'
import ManagerTasks from '@/components/Manufactory/ManagerTasks.vue'

export default {
  name: 'AdminHome',
  components: {
		WidgetMyOrders,
		WidgetTabsTask,
    ManagerUnassignedMontages,
    WidgetAssignedMontagesInBrig,
		ManagerTasks
  }
}
</script>
