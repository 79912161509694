<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '600px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
    >
      <v-card :tile="isMobile" class="rl-modal">
        <v-toolbar v-if="isMobile" color="#e0a677" tile style="position: sticky; top: 0px; z-index: 20;">
          <v-toolbar-title v-if="edit" class="white--text">Редактировние задания</v-toolbar-title>
          <v-toolbar-title v-else class="white--text">Новый задание</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title v-else class="rl-modal_header">
          <span v-if="edit" class="headline">Редактирование задания</span>
          <span v-else class="headline">Новое задание</span>
          <v-spacer />
          <v-btn icon color="#e0a677" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0" :class="{ 'px-2 pt-3': isMobile }">
					<div v-if="form.numberOrder" style="font-size: 1.1em; color: black;">
						<span style="font-weight: 500;">Номер заказа: </span>
						<span v-if="!/[a-zA-Z]/g.test(form.numberOrder) && (edit && (user.role === 'admin' || data && data.manager.uid === user.uid))" @click="$openOrder(form.numberOrder)" class="rl-link">{{ form.numberOrder }}</span>
						<span v-else>{{ form.numberOrder }}</span>
					</div>
          <div v-if="edit" class="pb-3" style="font-size: 1.1em; color: black;">
            <span style="font-weight: 500;">Менеджер: </span> {{ data.manager.displayName }}
            <span>
              <span style="font-weight: 500;">Дата: </span>
              <span>{{ $formatDate(form.date.seconds ? form.date.toDate() : new Date(form.date)) }}</span>
              <v-btn v-if="canEdit" icon color="black" small @click="editDate()" class="ml-1">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </span>
            <span v-if="data.status">
              <br>
              <span style="font-weight: 500;">Статус: </span> {{  manufactoryTaskStatus[data.status] }}
            </span>
            <span v-if="form.montagers && (form.montagersInfo && Object.keys(form.montagersInfo).length > 0)">
              <br>
              <span style="font-weight: 500;">Исполнители:</span>
              <span 
                v-for="(montager, index_montager) in form.montagersInfo"
                :key="index_montager"
              >
                <span :style="montager.boss ? 'font-weight: 500;' : ''">
                  {{ montager.name }}
                </span>
                <span v-if="user.role != 'manager'">({{ montager.costWork }}р.)</span>
                <span v-if="index_montager != Object.keys(form.montagersInfo)[Object.keys(form.montagersInfo).length - 1]">,</span>
              </span>
              <v-btn v-if="['admin', 'prodDir'].includes(user.role)" icon color="black" small @click="editPerformers()" class="ml-1">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </span>
            <span v-if="data.readyDate">
              <br>
              <span style="font-weight: 500;">Готово:</span>
              {{ $formatDateTime(data.readyDate.toDate()) }}
            </span>
            <span v-if="data.archiveDate">
              <br>
              <span style="font-weight: 500;">Архив:</span>
              {{ $formatDateTime(data.archiveDate.toDate()) }}
            </span>
          </div>
          <v-container class="py-0">
            <v-form ref="formCreateTask">
              <v-row>
								<v-col cols="12" class="d-flex pt-0">
									<v-autocomplete
										:class="{ 'mr-2': ['admin', 'manager'].includes(user.role) }"
										:disabled="user.role === 'prodDir' || (user.role === 'manager' && Boolean(data.status)) && ['produced', 'ready', 'archive'].includes(data.status)"
										ref="clientName"
										:dense="!isMobile"
										color="#e0a677"
										v-model="form.client"
										placeholder="Клиент"
										:items="clients"
										item-text="name"
										return-object
										@change="setMainClientContact()"
									>
										<template v-slot:no-data>
											<v-row no-gutters class="px-2">
												<v-col cols="12" class="d-flex align-center justify-center">
													<v-btn @click="newClient()" color="#e0a677" text small>
														Добавить клиента
													</v-btn>
												</v-col>
											</v-row>
										</template>
									</v-autocomplete>
									<v-btn
										v-if="['admin', 'manager'].includes(user.role)"
										:disabled="!form.client || (form.client && !form.client.name === undefined)"
										@click="showClientInfo()"
										icon
									>
										<v-icon>mdi-account-eye-outline</v-icon>
									</v-btn>
								</v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    :disabled="(!form.techTask && edit && (data.status != 'confirmationProd' && data.status != 'confirmation'))"
                    :readonly="user.role === 'prodDir' || (user.role === 'manager' && Boolean(data.status)) && ['produced', 'ready', 'archive'].includes(data.status)"
                    color="#e0a677"
                    v-model="form.techTask"
                    :rules="$fieldRules.isUrl"
                    label="Ссылка на ТЗ"
                    :append-outer-icon="form.techTask ? 'mdi-open-in-new' : null"
                    @click:append-outer="goUrl(form.techTask)"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    :readonly="user.role === 'prodDir' || (user.role === 'manager' && Boolean(data.status)) && ['produced', 'ready', 'archive'].includes(data.status)"
                    color="#e0a677"
                    v-model="form.comment"
                    label="Комментарий"
                    rows="2"
                  />
                </v-col>
                <v-col v-if="!getHourPayWorkMasters && (user.role === 'admin' || user.role === 'prodDir')" cols="12" class="py-0">
                  <v-text-field
                    :disabled="disCostField"
                    color="#e0a677"
                    type="number"
                    suffix="руб."
                    v-model="form.costForProd"
                    label="Стоимость для производства"
                  />
                </v-col>
                <!-- <v-col v-if="['prodDir', 'master', 'admin'].includes(user.role)" cols="12" class="py-0">
                  <v-textarea
                    color="#e0a677"
                    v-model="form.commentProd"
                    label="Комментарий производства"
                    rows="2"
                  />
                </v-col> -->
                <v-col cols="12" class="py-0">
                  <v-btn :disabled="!canEdit" @click="uploadImage()" width="100%" :loading="uploadingImage">
                    <v-icon>mdi-cloud-upload</v-icon>
                    <span class="pl-2">Загрузить фото</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-container fluid class="pa-0">
                    <v-row>
                      <v-col
                        v-for="(image, index_image) in form.images"
                        :key="index_image"
                        class="d-flex child-flex"
                        cols="4"
                      >
                        <ImageView :key="index_image + form.images.length" :disTrach="!canEdit" :path="images[index_image]" :images="images" @delete="deleteImage(image, index_image)"/>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
						<v-row no-gutters class="mt-5">
							<v-col cols="12">
								<v-list-item-title class="d-flex align-center" style="font-size: 1.3em; line-height: 1.9em;">
									Комментарии
									<v-spacer />
									<v-btn
										x-small
										outlined
										@click="showProgramComments = !showProgramComments"
									>
										<span v-if="showProgramComments">Скрыть подробности</span>
										<span v-else>Показать подробности</span>
									</v-btn>
								</v-list-item-title>
							</v-col>
							<v-col cols="12">
								<Comments
									v-if="edit"
									:item_id="data.id"
									:item="data"
									:showProgramComment="showProgramComments"
								/>
							</v-col>
						</v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-7">
          <v-btn v-if="taskBoss && ['master'].includes(user.role) && (data.status && !['ready', 'archive'].includes(data.status))" color="orange" text @click="addAssistant()">Помошники</v-btn>
          <v-btn v-if="edit && ((['advance'].includes(data.status) && user.role === 'manager') || user.role === 'admin')" :disabled="user.role === 'admin' ? false : !canEdit" color="red" text @click="deleteTask()">{{ isMobile ? 'Удал.' :  'Удалить' }}</v-btn>
					<v-btn v-if="edit && data.status === 'confirmationProd' && ['admin', 'prodDir'].includes(user.role)" color="black" text @click="save('confirmation', 'revision')">{{ isMobile ? 'На. доработ.' :  'На доработку' }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="edit && data.status === 'confirmationProd' && ['admin', 'prodDir'].includes(user.role)" color="orange" text @click="save('work')">{{ isMobile ? 'Наз. мастера' :  'Назначить мастера' }}</v-btn>
          <v-btn v-if="['confirmation'].includes(data.status) || !data.status" color="blue" text @click="save('confirmationProd')">На производство</v-btn>
          <v-btn v-if="user.role === 'admin' && ['produced', 'work'].includes(data.status) || taskBoss && ['master', 'prodDir'].includes(user.role) && (data.status && data.status === 'produced')" color="green" text @click="save('ready')">Готово</v-btn>
          <v-btn v-if="taskBoss && ['master', 'prodDir'].includes(user.role) && (data.status && data.status === 'work')" color="blue" text @click="save('produced')">В работу</v-btn>
          <v-btn v-if="['admin', 'manager'].includes(user.role) && (data.manager && data.manager.uid === user.uid || user.role === 'admin') && (data.status && data.status === 'ready')" color="blue" text @click="save('archive')">В архив</v-btn>
          <v-btn v-if="['manager', 'admin'].includes(user.role) && !data.status" :disabled="!canEdit" color="green" text @click="save('confirmation')">Сохранить</v-btn>
          <v-btn v-if="user.role === 'admin' && (data.status && data.status != 'archive') || (user.role === 'manager' && edit && canEdit) || (user.role === 'prodDir' && data.status != 'confirmationProd')" color="blue" text @click="save()">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import ImageView from '@/components/Image.vue'
import SelectDate from '@/components/Modals/SelectDate'
import SelectMasterForProd from '@/components/Modals/SelectMasterForProd'
import SelectAssistantForMaster from '@/components/Modals/SelectAssistantForMaster'
import Confirm from  '@/components/Modals/Confirm'
import Client from  '@/components/Modals/Clients/Index'
import Comments from './components/Comments/Index.vue'
import { Role } from '@/helpers'
import { usersCollection, clientsCollection } from '@/plugins/firebase'
import { manufactoryTaskStatus, manufactoryTaskStatusArr } from '@/helpers'
var cloneDeep = require('lodash.clonedeep');
import imageCompression from 'browser-image-compression'

export default {
  components: {
    ImageView,
		Comments
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
		nmbrOrder: {
      type: String,
      default: null
    },
		clientOrder: {
      type: Object,
      default: null
    },
		clientContact: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isMobile: isMobile,
    roles: Role,
    manufactoryTaskStatus: manufactoryTaskStatus,
    manufactoryTaskStatusArr: manufactoryTaskStatusArr,
    dialog: true,
    form: {
      client: '',
			contact: null,
      comment: '',
      techTask: '',
      commentProd: '',
      costForProd: 0,
      montagers: null,
      montagersInfo: null,
      images: [],
      date: new Date().toISOString().substr(0, 10),
			numberOrder: null
    },
    images: [],
    uploadingImage: false,
		showProgramComments: false
  }),
  computed: {
		clientContacts() {
			const client = this.form.client
			console.log(client)
			if (client && client.contacts) {
				return client.contacts
			}
			return []
		},
    disCostField() {
      if (this.user.role === 'admin') {
        return false
      }
      if (this.user.role === 'prodDir') {
        return this.data.status === 'archive' || !['admin', 'prodDir'].includes(this.user.role) || (this.user.role === 'prodDir' && !this.user.canConfirmTask) || ['work', 'produced', 'ready'].includes(this.data.status)
      }
      return true
    },
    canEdit() {
      if (this.data.status === 'archive') {
        return false
      } else {
        if (!this.edit) {
          return true
        } else if (this.user.role === 'admin') {
          return true
        } else if (this.user.role === 'prodDir') {
          return false
        } else if (this.user.uid === this.data.manager.uid && (!this.data.status || ['confirmation', 'confirmationProd', 'work'].includes(this.data.status))) {
          return true
        }
      }
      return false
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    users() {
      return this.$store.getters['users/getWorkers']
    },
    clients() {
      return this.$store.getters['clients/getClients']
    },
    taskBoss() {
      let task = { ...this.data }

			if (this.user.role === 'master') {
				if (task.montagersInfo && task.montagersInfo[this.user.uid] && task.montagersInfo[this.user.uid].boss) {
					return true
				}
				return false
			}
			if (this.user.role === 'prodDir') {
				return true
			}

      return false
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    }
  },
  async created() {
    if (!this.clients || this.clients.length === 0) {
      this.$store.dispatch('clients/loadClients')
    }

    if (!this.users || this.users.length === 0) {
      this.$store.dispatch('users/loadUsers')
    }

		if (this.nmbrOrder) {
			this.form.numberOrder = this.nmbrOrder
		}

		if (this.clientOrder) {
			this.form.client = this.clientOrder
		}
		if (this.clientContact) {
			this.form.contact = this.clientContact
		}


    if (this.data && this.data.id) {
      let data = {
        // client: await cloneDeep(this.data.client),
				client: { ...this.data.client },
				contact: this.data.contact ? await cloneDeep(this.data.contact) : null,
        montagers: this.data.montagers ? await cloneDeep(this.data.montagers) : [],
        montagersInfo: this.data.montagersInfo ? await cloneDeep(this.data.montagersInfo) : [],
        comment: this.data.comment,
        techTask: this.data.techTask,
        date: this.data.date,
        commentProd: this.data.commentProd ? this.data.commentProd : '',
        costForProd: this.data.costForProd ? this.data.costForProd : '',
        images: this.data.images ? await cloneDeep(this.data.images) : [],
				numberOrder: this.data.numberOrder || null
      }
      this.form = data
    }
  },
  watch: {
    form: {
      deep: true,
      async handler(newValue) {
        if (newValue.images.length > this.images.length) {
          let images = []
          if (this.form.images && this.form.images.length > 0) {
            for (let key in this.form.images) {
              let imgUrl = await this.$getFbFileUrl(this.form.images[key])
              images.push(imgUrl)
            }
          }
          this.images = images
        }
      }
    }
  },
  methods: {
		async showClientInfo() {
			const client = await this.$showModal({
        component: Client,
        isPersistent: true,
				props: {
					edit: true,
					data: this.form.client
				}
      })
			if (client) {
				this.form.client = { ...this.form.client, id: client.id, ...client.data }
			}
		},
		async newClient() {
			let clientNameRef = this.$refs.clientName.internalSearch

			const createClient = await this.$showModal({
        component: Client,
        isPersistent: true,
				props: {
					data: {
						name: clientNameRef || ''
					}
				}
      })
			if (createClient) {
				let obj = { id: createClient.id, ...createClient.data }
				this.form.client = obj
				this.setMainClientContact()
			}
		},
		setMainClientContact() {
			if (this.form.client && this.form.client.contacts) {
				const main = this.form.client.contacts.find(x => x.main)
				if (main) {
					this.form.contact = main
				}
			}
		},
    async editDate() {
      let date = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Укажите дату',
          selectDate: this.form.date.seconds ? this.form.date.toDate().toISOString().substr(0, 10) : form.date
        }
      })

      if (date) {
        this.form.date = date
      }
    },
    async editPerformers() {
      let performers = await this.$showModal({
        component: SelectAssistantForMaster,
        isPersistent: true,
        props: {
          task: this.form
        }
      })
      
      if (performers) {
        this.form = { ...this.form, ...performers }
      }
    },
    deleteImage(path, index) {
      this.form.images.splice(index, 1)
    },
    async uploadImage() {
      const input = document.createElement("input")
      input.type = "file";
      input.accept='image/*'
      input.multiple='multiple'

      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1520,
        useWebWorker: true
      }

      input.onchange = async (e) => {
          var files = await e.target.files

          for(let key in files) {
            let file = files[key]
            if (file && file instanceof File) {
              let compressedFile = await imageCompression(file, options)

              let filePath = `manufactory/${new Date().getTime()}_${key}.${/[^.]+$/.exec(file.name)}`
              let ref = this.$storage.ref().child(filePath).put(compressedFile)
              ref.on(`state_changed`, snapshot => {
                this.uploadingImage = true
              }, error => {
                console.log(error)
              }, () => {
                ref.snapshot.ref.getDownloadURL().then((url)=>{
                  this.uploadingImage = false
                  this.form.images.push(filePath)
                })
              })
            }
          }

          this.uploadingImage = false
      };

      input.click();
    },
    goUrl(url) {
      window.open(url, '_blank').focus();
    },
    async deleteTask() {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление заказа`,
          text: `Действительно хотите удалить заказ для "${this.data.client.name}"?`
        }
      })

      if (res) {
        await this.$store.dispatch('manufactory/deleteTask', this.data)
        this.close()
      }
    },
    async addAssistant() {
      let select = await this.$showModal({
        component: SelectAssistantForMaster,
        isPersistent: true,
        props: {
          task: this.form
        }
      })
      if (select) {
        this.form = { ...this.form, ...select }
        await this.$store.dispatch('manufactory/updateTask', {
          id: this.data.id,
          data: select
        }) 
      }
    },
    async save(status, action = null) {
      const errors = []
      const validate = await this.$refs.formCreateTask.validate()
      errors.push(validate)

      if (!errors.includes(false)) {
        let form = { ...this.form }
        let date = null
        let montagers = null

        if (['confirmation', 'confirmationProd'].includes(status) && action != 'revision') {
          date = await this.$showModal({
            component: SelectDate,
            isPersistent: true,
            props: {
              title: 'Дата',
              selectDate: form.date.seconds ? form.date.toDate().toISOString().substr(0, 10) : form.date
            }
          })
        } else {
          let oldDate = form.date.seconds ? form.date.toDate().toISOString().substr(0, 10) : form.date
          date = new Date(oldDate)
        }

        if (status === 'work') {
          let select = await this.$showModal({
            component: SelectMasterForProd,
            isPersistent: true,
            props: {
              data: this.form
            }
          })
          if (select) {
            montagers = select
          }
        }

        if (status === 'work' && montagers != null || (status != undefined && status != 'work') && date) {
          if (this.edit) {
            let data = {
              ...form,
              ...montagers,
              date: new Date(date),
              status: status
            }
            if (status === 'ready') {
              data = {
                ...data,
                readyDate: new Date()
              }
            }
            if (status === 'archive') {
              data = {
                ...data,
                archiveDate: new Date()
              }
            }
            await this.$store.dispatch('manufactory/updateTask', {
              id: this.data.id,
              data: {
								...data,
								action: action === 'revision' ? action : null
							},
							manager: this.data.manager,
              hourPayWorkMasters: this.getHourPayWorkMasters,
            })
          } else {
						console.log(form)
            let user = usersCollection.doc(this.user.uid)
            await this.$store.dispatch('manufactory/createTask', {
              ...form,
              ...montagers,
              date: new Date(date),
              status: status,
              manager: user,
              hourPayWorkMasters: this.getHourPayWorkMasters,
              created: new Date()
            })
          }
          this.close('newTask')
        }

        if (form.date && form.date.length === 10) {
          form = { ...form, date: new Date(form.date) }
        }

        if (status === undefined) {
          await this.$store.dispatch('manufactory/updateTask', {
            id: this.data.id,
            data: form
          })
          this.close()
        }
      }
    },
    close(info) {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close', info)
      }, 200)
    }
  }
}
</script>
