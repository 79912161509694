<template>
  <v-app style="background:#424242;">
    <Navbar/>
    <NavigationDrawer/>
    <v-main>
      <router-view/>
    </v-main>
		<ShowAlert v-if="!$isMobile" />
  </v-app>
</template>

<script>
import Navbar from "@/components/Templ/Navbar"
import NavigationDrawer from "@/components/Templ/NavigationDrawer"
import ShowAlert from "@/components/Notifications/ShowAlert.vue"

import ShowImage from '@/components/Modals/ShowImage.vue'
import ShowImageMob from '@/components/Modals/ShowImageMob.vue'
import Vue from 'vue'

export default {
  name: 'App',
  components: {
    Navbar,
    NavigationDrawer,
		ShowAlert
  },

  data: () => ({
    //
  }),
  created() {
    Vue.prototype.$showImage = function (imgUrl, images) {
      this.$showModal({
        component: ShowImage,
        isPersistent: false,
        props: {
          image: imgUrl,
          images: images ? images : null
        }
      })
    }
    Vue.prototype.$showImageMob = function (imgUrl, images) {
      this.$showModal({
        component: ShowImageMob,
        isPersistent: false,
        props: {
          image: imgUrl,
          images: images ? images : null
        }
      })
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('settings/loadSettings')
      this.$store.dispatch('users/loadUsers')
      // if (this.user.role != 'master') {
				this.$store.dispatch('notifications/loadNotifications')
        this.$store.dispatch('locations/loadLocations')
        this.$store.dispatch('brigs/loadBrigs')
        this.$store.dispatch('clients/loadClients')
      // }
    }
  },
  mounted() {
    if (this.user) {
      this.loadData()
    }
  },
  computed: {
    clients() {
      return this.$store.getters['clients/getClients']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    brigs() {
      return this.$store.getters['brigs/getBrigs']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    users() {
      return this.$store.getters['users/getWorkers']
    },
  },
  watch: {
    user(newVal, oldVal) {
      if (oldVal === null && newVal) {
        this.loadData()
      }
    }
  }
};
</script>

<style lang="scss">
.vmp__app {
  display: none;
}
</style>
