<template>
		<v-container class="fill-height" fluid>
				<v-row justify="center" align="center">
						<v-col cols="12" sm="9" md="5" lg="4" xl="3">
								<v-card class="elevation-12" style="background:#424242;">
										<v-toolbar color="#e0a677" dark flat>
												<v-toolbar-title style="color:#424242;">Авторизация</v-toolbar-title>
										</v-toolbar>
										<ProgressBar
											:loading="loading"
											color="#ace2fc"
											top="-4px"
										/>
										<v-card-text>
												<v-form v-model="isValid">
													<v-text-field
														v-model="value.email"
														label="E-mail"
														name="e-mail"
														prepend-icon="mdi-email"
														suffix="@m4r.ru"
														type="text"
														color="#e0a677"
														required
														:rules="$fieldRules.field"
														@keyup.enter="isValid ? login() : false"
													/>

													<v-text-field
														v-model="value.password"
														label="Пароль"
														name="password"
														prepend-icon="mdi-lock"
														:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
														:type="showPass ? 'text' : 'password'"
														@click:append="showPass = !showPass"
														@keyup.enter="isValid ? login() : false"
														color="#e0a677"
														required
														:rules="$fieldRules.field"
													/>
												</v-form>
										</v-card-text>
										<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn
														color="#e0a677"
														:disabled="!isValid"
														@click="isValid ? login() : false"
												>Войти</v-btn>
										</v-card-actions>
								</v-card>
						</v-col>
				</v-row>
		</v-container>
</template>

<script>
import ProgressBar from '@/components/Templ/ProgressBar.vue'
import { AddUserToStore } from '@/helpers'

export default {
	name: 'Login',
	components: {
		ProgressBar
	},
	data: () => ({
			isValid: false,
			showPass: false,
			value: {
					email: '',
					password: ''
			},
			error: null,
			loading: false
	}),
	methods: {
			login () {
				this.loading = true
				this.$auth.signInWithEmailAndPassword(`${this.value.email}@m4r.ru`, this.value.password)
					.then(async (user) => {
						if (user && user.user) {
							await AddUserToStore(user.user)
							this.$router.replace('/')
						}
					})
					.catch((err) => {
						if (err.code === 'auth/wrong-password') {
							alert("Не верный пароль.")
						} else {
							alert(err.message)
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
	}
}
</script>
