import { Image } from 'element-tiptap';
import MenuButton from './MenuButton.vue'
import ImageView from './ImageView.vue'

export default class CustomImage extends Image {
	get view() {
    return ImageView;
  }

  menuBtnView (editorContext) {
    return {
      component: MenuButton,
      componentProps: {
				editorContext
			}
    }
  }
}