<template>
  <div class="pageTasks" :class="{ 'pa-3': !$isMobile }">
    <v-card :tile="$isMobile">
      <div class="d-flex align-center px-4">
        <v-row class="page_filterTable">
          <v-col cols="2">
            <v-text-field
              dense
              color="#e0a677"
              v-model="filterTasksByMonthText"
              @click="selectMonth()"
              label="Месяц"
							clearable
            />
          </v-col>
					<v-col cols="2">
            <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterTasksByStatus"
              @change="$store.dispatch('tasks/loadAllTasks', currentTasks)"
              label="Статус"
              :items="tasksStatusArr"
              clearable
            />
          </v-col>
					<v-col 
						v-if="
							(currentUser.role === 'admin' && ['TasksMe', 'TasksFromMe', 'TasksDesigner'].includes(currentTasks)) ||
							(currentUser.role === 'manager' && ['TasksDesigner'].includes(currentTasks))
						"
						cols="2"
					>
            <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterTasksByManager"
              @change="$store.dispatch('tasks/loadAllTasks', currentTasks)"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
					<v-col v-if="['TasksDesigner'].includes(currentTasks)" cols="2">
            <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterTasksByDesigner"
              @change="$store.dispatch('tasks/loadAllTasks', currentTasks)"
              label="Дизайнер"
              :items="designers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
					<v-col cols="1">
            <v-text-field
              dense
              color="#e0a677"
              v-model="filterTasksByNumberOrder"
							@change="$store.dispatch('tasks/loadAllTasks', currentTasks)"
              label="Номер заказа"
            />
          </v-col>
					<v-col cols="2">
            <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterTasksByClient"
              @change="$store.dispatch('tasks/loadAllTasks', currentTasks)"
              label="Клиент"
              :items="clients"
              item-text="name"
              return-object
              clearable
            />
          </v-col>
        </v-row>
				<v-spacer />
				<v-btn icon small class="ml-3" @click="newTask()">
					<v-icon size="22">mdi-plus</v-icon>
				</v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!tasksLoaded && (!tasks || tasks.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="tasksLoaded && (!tasks || tasks.length === 0)"
        class="pa-3 d-flex justify-center"
        :class="{ 'infoNoItems': !$isMobile }"
      >
        Нет задач
      </div>
			<v-data-table
				v-if="tasks && tasks.length > 0"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				}"
				:headers="tableHeadres"
				:items="tasks"
				:items-per-page="30"
 				:dense="!$isMobile"
			>
				<template v-slot:item="{ item }">
					<tr
						:style="
							item.color && item.color[currentUser.uid] ?
								 `background-color: ${item.color[currentUser.uid]}` : ''"
						@click="openTask(item)"
					>
						<td style="width: 47%;">
							{{ $sliceStr(item.title || item.description, 60) }}
						</td>
						<td>
							<span v-if="item.numberOrder">{{ item.numberOrder }}</span>
							<span v-else>Не указан</span>
						</td>
						<td>
							<div class="d-flex align-center">
								<my-mini-avatar :key="item.from.id || item.from.uid" :user="item.from"/>
							</div>
						</td>
						<td>
							<div class="d-flex align-center">
								<div v-for="(whoTo, whoTo_index) in item.whoTo" :key="whoTo_index" class="mr-1">
									<my-mini-avatar
										v-if="whoTo_index < 3"
										:key="whoTo"
										:user="item.whoToData[whoTo]"
									/>
									<span
										v-if="whoTo_index === 3 && item.whoTo.length > 3"
										class="grey--text text-caption"
									>
										(+{{ item.whoTo.length - 3 }})
									</span>
								</div>
							</div>
						</td>
						<td>
							<span
								:style="
									`${$colorDeadline(item.deadline.toDate())}`
								"
							>
								{{ $formatDateTime(item.deadline.toDate()) }}
							</span>
						</td>
						<td>
							<span>
								<template v-if="item && item.archive">В архиве</template>
								<template v-else-if="item && item.print">В печать</template>
								<template v-else-if="!item || !item.accepted">Ожидает</template>
								<template v-else-if="item && item.ready">Готово</template>
								<template v-else>В работе</template>
							</span>
						</td>
						<td class="text-center">
							<v-menu left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										icon
										:disabled="disabledColorBtn(item)"
									>
										<v-icon>mdi-format-color-fill</v-icon>
									</v-btn>
								</template>

								<div style="background-color: white;">
									<div class="pt-2 px-5">
										<v-btn 
											v-if="item.color && item.color[currentUser.uid]"
											:color="item.color[currentUser.uid] || null"
											block
											@click="setColorItem('', item)"
										>
											Сбросить цвет
										</v-btn>
									</div>
									<v-color-picker
										@input="setColorItem($event, item)"
										:value="item.color && item.color[currentUser.uid] || null"
										hide-canvas
										hide-inputs
										show-swatches
										swatches-max-height="230"
										mode="hexa"
										class="rl-table-color-picker"
									/>
								</div>
							</v-menu>
						</td>
					</tr>
				</template>
			</v-data-table>
    </v-card>
  </div>
</template>

<script>
import { tasksStatusArr } from '@/helpers'
import TaskModal from  '@/components/Modals/Task/Index'
import SelectMonth from '@/components/Modals/SelectMonth'

export default {
  name: 'AllTasks',
  data: () => ({
		tasksStatusArr: tasksStatusArr,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    openFilters: false
  }),
  async beforeRouteLeave(to, from, next) {
    this.$store.commit('tasks/resetAllFilterTasks')
		if (to.name.includes('Tasks') && to.name != 'TasksArchive') {
			if (this.currentUser.role != 'designer') {
				this.filterTasksByManager = this.currentUser
			}
			this.$store.dispatch('tasks/loadAllTasks', to.name)
		} else {
			this.$store.dispatch('tasks/loadAllTasks', 'TasksMe')
		}
    next()
  },
  created() {
		if (this.currentUser.role != 'designer') {
			this.filterTasksByManager = this.currentUser
		}
    this.$store.dispatch('tasks/loadAllTasks', this.currentTasks)
		if (!this.managers || this.managers.length === 0) {
      this.$store.dispatch('users/loadManagers')
    }
		if (!this.clients || this.clients.length === 0) {
      this.$store.dispatch('clients/loadClients')
    }
  },
  computed: {
		tableHeadres() {
			return [
				{
					text: 'Задача',
					align: 'start',
					sortable: false,
					value: 'title',
					width: '47%'
				},
				{
					text: 'Номер заказа',
					align: 'start',
					sortable: false,
					value: 'numberOrder',
				},
				{
					text: 'От кого',
					align: 'start',
					sortable: false,
					value: 'from',
				},
				{
					text: 'Кому',
					align: 'start',
					sortable: false,
					value: 'whoTo',
				},
				{
					text: 'Дедлайн',
					align: 'start',
					sortable: false,
					value: 'deadline',
				},
				{
					text: 'Статус',
					align: 'start',
					sortable: false,
					value: 'status',
				},
				{
					text: 'Цвет',
					align: 'center',
					sortable: false,
					value: 'color',
				},
			]
		},
		currentTasks() {
			return this.$route.name
		},
		currentUser() {
			return this.$store.getters['user/getUser']
		},
		managers() {
      return this.$store.getters['users/getManagers']
    },
		clients() {
      return this.$store.getters['clients/getClients']
    },
		designers() {
			return this.$store.getters['users/getDesignerUsers']
    },
		filterTasksByMonthText: {
			get() {
				let month = this.filterTasksByMonth
				if (month) {
					return String(new Date(month).toLocaleString('ru', {month: 'long', year: 'numeric'}))
				}
				return null
			},
			set(val) {
				this.filterTasksByMonth = val
				this.$store.dispatch('tasks/loadAllTasks', this.currentTasks)
			}
    },
    filterTasksByMonth: {
      get() { return this.$store.getters['tasks/getFilterTasksByMonth'] },
      set(val) { this.$store.commit('tasks/setFilterTasksByMonth', val) }
    },
		filterTasksByStatus: {
      get() { return this.$store.getters['tasks/getFilterTasksByStatus'] },
      set(val) { this.$store.commit('tasks/setFilterTasksByStatus', val) }
    },
		filterTasksByManager: {
      get() { return this.$store.getters['tasks/getFilterTasksByManager'] },
      set(val) { this.$store.commit('tasks/setFilterTasksByManager', val) }
    },
		filterTasksByDesigner: {
      get() { return this.$store.getters['tasks/getFilterTasksByDesigner'] },
      set(val) { this.$store.commit('tasks/setFilterTasksByDesigner', val) }
    },
		filterTasksByNumberOrder: {
      get() { return this.$store.getters['tasks/getFilterTasksByNumberOrder'] },
      set(val) { this.$store.commit('tasks/setFilterTasksByNumberOrder', val) }
    },
		filterTasksByClient: {
      get() { return this.$store.getters['tasks/getFilterTasksByClient'] },
      set(val) { this.$store.commit('tasks/setFilterTasksByClient', val) }
    },
		tasks() {
			return this.$store.getters['tasks/getAllTasks']
		},
    tasksLoaded() {
      // return this.$store.getters['montages/getUnassignedMontagesLoaded']
			return true
    }
  },
  methods: {
		async setColorItem(color, item) {
			if (color === '#FF0000FF' || (color && color.hexa === '#FF0000FF')) {
				color = null
			}
			color = color && color.hexa ? color.hexa : color
			let colorObj = {}
			if (item.color) {
				colorObj = { ...item.color }
			}
			colorObj[this.currentUser.uid] = color
			await this.$store.dispatch('tasks/updateTask', {
				id: item.id,
				data: {
					color: colorObj
				}
			})
		},
		disabledColorBtn(item) {
			let currentUserUid = this.currentUser.uid
			let arr = [ ...item.whoTo ]
			arr.push(item.from.id)
			if (arr.includes(currentUserUid)) {
				return false
			}
			return true
		},
		async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterTasksByMonth
        }
      })
      if (modalMonth) {
        this.filterTasksByMonth = modalMonth
				this.$store.dispatch('tasks/loadAllTasks', this.currentTasks)
      }
    },
		newTask() {
			let props = {}

			switch (this.currentTasks) {
				case "TasksMe":
					props = { ...props, meTask: true }
					break;
				case "TasksFromMe":
					props = { ...props, meTask: false }
					break;
				case "TasksDesigner":
					props = { ...props, design: true }
					break;
			}

			this.$showModal({
        component: TaskModal,
        isPersistent: true,
        props: {
					...props,
          edit: false,
          data: null
        }
      })
		},
		async openTask(task) {
      await this.$showModal({
        component: TaskModal,
        isPersistent: true,
        props: {
          edit: true,
          data: task
        }
      })
    }
	}
}
</script>
