<template>
	<div class="showAlert" v-if="!isOpenNotifList">
		<template v-for="item in items">
			<ItemAlert :key="item.id" :item="item" />
		</template>
	</div>
</template>

<script>
import ItemAlert from './ItemAlert.vue'

export default {
	components: {
		ItemAlert
	},
	data: () => ({
		actionAvailable: ['newTask']
	}),
	methods: {
		getLocalDate(date) {
			return new Date(date).toLocaleString('ru', {
				day: 'numeric',
				month: 'short',
				hour: 'numeric',
				minute: 'numeric'
			})
		}
	},
	computed: {
		isOpenNotifList() {
			return this.$store.getters['notifications/getIsOpenNotifList']
		},
		items() {
			return this.$store.getters['notifications/getShowAlertNotif']
		}
	}
}
</script>

<style lang="scss">
.showAlert {
	position: absolute;
	top: 70px;
	right: 20px;
}
</style>