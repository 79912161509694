<template>
  <el-tooltip
    :content="tooltip"
    :open-delay="350"
    :disabled="!enableTooltip || readonly"
    transition="el-zoom-in-bottom"
    effect="dark"
    placement="top"
  >
    <div
      :class="commandButtonClass"
      @mousedown.prevent
      @click="command ? onClick() : null"
    >
      <awes-icon :name="icon"/>
    </div>
  </el-tooltip>
</template>

<script>
import { Tooltip } from 'element-ui';

import Icon from 'vue-awesome/components/Icon.vue';
import 'vue-awesome/icons/image';
import 'vue-awesome/icons/regular/image';
import 'vue-awesome/icons/link';

export default {
	components: {
		'awes-icon': Icon,
		[Tooltip.name]: Tooltip
	},
	props: ['icon', 'isActive', 'tooltip', 'enableTooltip', 'command', 'readonly'],
	computed: {
		commandButtonClass() {
			return {
				'el-tiptap-editor__command-button': true,
				'el-tiptap-editor__command-button--active': this.isActive,
				'el-tiptap-editor__command-button--readonly': this.readonly,
			}
		}
	},
	methods: {
		onClick() {
			if (!this.readonly) this.command();
		}
	}
}
</script>