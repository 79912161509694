<template>
	<el-tiptap
		v-model="_value"
		:extensions="extensions"
		lang="ru"
		:spellcheck="false"
		:charCounterCount="false"
		:placeholder="placeholder"
		:readonly="readonly"
		:showMenubar="!readonly"
		class="elTipTap"
	/>
</template>

<script>
import CustomImage from './extensions/customImage/index.js'
import CustomLink from './extensions/customLink/index.js'

import {
  ElementTiptap, Doc, Text,
  Paragraph, TextAlign, Heading,
	Bold, Underline, Blockquote,
	Italic, Strike, ListItem,
	BulletList, OrderedList, TextColor,
  TextHighlight, History, FontSize,
	TableCell, TableHeader,
  Table, TableRow
} from "element-tiptap";
import "element-tiptap/lib/index.css";
import "element-ui/lib/theme-chalk/index.css";

export default {
		name: 'TextEditor',
    components: {
        "el-tiptap": ElementTiptap
    },
    props: {
			value: String,
			placeholder: {
				type: String,
				default: () => "Напишите что-нибудь..."
			},
			readonly: {
				type: Boolean,
				default: () => false
			}
    },
    data() {
			return {
				extensions: [
					new Doc(),
					new Text(),
					new Paragraph(),
					new Heading({ level: 3 }),
					new Bold(),
					new Underline(),
					new Blockquote(),
					new Italic(),
					new Strike(),
					new ListItem(),
					new BulletList(),
					new OrderedList(),
					new TextAlign(),
					new TextColor(),
					new TextHighlight(),
					new TableCell(),
					new TableHeader(),
					new TableRow(),
					new Table(),
					new CustomLink({ bubble: true, menubar: false }),
					new CustomImage(),
					new History()
				]
			};
    },
		created() {
			console.log('editor tut')
		},
    computed: {
        _value: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    }
};
</script>

<style lang="scss">
.elTipTap {
		.el-tiptap-editor__menu-bar {
			position: sticky;
			top: 0;
			background-color: white;
			z-index: 10;
		}

		.el-tiptap-editor__content {
			padding: 8px;
			.image-view__body {
				&:hover {
					outline-color: #fff0;
				}

				.v-image__image {
					border-radius: 7px;
				}
			}
		}

    .el-tiptap-editor {
        &__content {
            border-bottom: 1px solid #ebeef5;
        }

        &__menu-bubble {
            // display: none !important;
						padding: 0;
						.link-bubble-menu {
							div {
								display: none;
								margin: 5px !important;
							}
							.el-tiptap-editor__command-button {
								display: flex;
							}
						}
        }
    }
}
</style>
