<template>
  <div class="page brig" :class="{ 'pa-3': !$isMobile }">
    <v-card :tile="$isMobile">
			<div v-if="$isMobile">
        <v-row class="page_filterTable ma-0 px-1 pt-4">
          <v-col cols="8">
            <v-text-field
              dense
              v-model="searchClients"
              color="#e0a677"
              label="Клиент/телефон клиента"
              placeholder="Иван/79884317765"
            />
          </v-col>
          <v-col cols="2" class="pt-4">
              <v-btn icon @click="openFilters = !openFilters">
                <v-badge
                  v-if="filtersSelected"
                  color="orange"
                  overlap
                  :content="filtersSelected"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-badge>
                <v-icon v-else>mdi-tune</v-icon>
              </v-btn>
          </v-col>
          <v-col cols="2" class="pt-5">
						<v-btn icon small @click="createClient()">
							<v-icon size="21">mdi-account-plus-outline</v-icon>
						</v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1 pt-4">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
									dense
									color="#e0a677"
									v-model="filterClientByManager"
									@change="$store.dispatch('clients/loadClients')"
									label="Менеджер"
									:items="managers"
									item-text="displayName"
									return-object
									clearable
								/>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable">
          <v-col cols="3">
            <v-text-field
              dense
              v-model="searchClients"
              color="#e0a677"
              label="Клиент/телефон клиента"
              placeholder="Иван/79884317765"
            />
          </v-col>
					<v-col cols="2">
						<v-autocomplete
              dense
              color="#e0a677"
              v-model="filterClientByManager"
              @change="$store.dispatch('clients/loadClients')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
					</v-col>
					<v-col cols="2">
						<v-combobox
							dense
							v-model="filterClientByActivitys"
							:items="companyActivitys"
							label="Виды деятельности"
							multiple
							color="#e0a677"
							item-color="#e0a677"
							clearable
							@change="$store.dispatch('clients/loadClients')"
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index < 1">
									<span>{{ item }}</span>
									<span 
										v-if="filterClientByActivitys && index < filterClientByActivitys.length - 1"
										class="mr-1"
									>,</span>
								</span>
								<span v-if="index === 1">
									(+{{ filterClientByActivitys.length - 1 }})
								</span>
							</template>
							<template v-slot:item="{item, attrs, on}">
								<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
									<v-list-item-action>
										<v-checkbox color="#e0a677" :ripple="false" :input-value="active" />
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>
											<v-row no-gutters align="center">
												{{ item }}
											</v-row>
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>
						</v-combobox>
					</v-col>
					<v-col cols="2">
						<v-autocomplete
              dense
              v-model="filterClientByWhereFrom"
              @change="$store.dispatch('clients/loadClients')"
              label="Откуда пришёл клиент"
              :items="whereFromCompany"
							color="#e0a677"
							item-color="#e0a677"
              clearable
            />
					</v-col>
        </v-row>
        <v-btn icon small @click="createClient()">
          <v-icon size="21">mdi-account-plus-outline</v-icon>
        </v-btn>
      </div>
      <v-divider />
			<v-data-table
				:headers="tableHeadres"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				}"
				:items="clients"
				:items-per-page="30"
 				dense
				@click:row="editClient($event)"
			>
				<template v-slot:[`item.phonenumber`]="{ item }">
					<span v-if="item.contacts && item.contacts.length > 0">
						{{ getMainContact(item.contacts) }}
					</span>
					<span v-else>Не указан</span>
				</template>
				<template v-slot:[`item.activity`]="{ item }">
					<span v-if="item.companyActivity">{{ item.companyActivity }}</span>
					<span v-else>Не указана</span>
				</template>
				<template v-slot:[`item.whereFrom`]="{ item }">
					<span v-if="item.whereFrom">{{ item.whereFrom }}</span>
					<span v-else>Не указано</span>
				</template>
				<template v-slot:[`item.statistics`]="{ item }">
					<span>{{ item.orders ? item.orders.length : 0 }}</span>/<!--
					--><span>{{ item.montages ? item.montages.length : 0 }}</span>/<!--
					--><span>{{ item.manufactory ? item.manufactory.length : 0 }}</span>
				</template>
				<template v-slot:[`item.creatorData`]="{ item }">
					<span v-if="item.creatorData">{{ item.creatorData.displayName }}</span>
					<span v-else>Не задан</span>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<v-btn small v-if="user.role === 'admin'" @click.stop="deleteClient(item)" icon color="#bf4037">
						<v-icon size="18">mdi-trash-can-outline</v-icon>
					</v-btn>
				</template>
			</v-data-table>
    </v-card>
  </div>
</template>

<script>
import Client from  '@/components/Modals/Clients/Index'
import Confirm from  '@/components/Modals/Confirm'
import { RoleText } from '@/helpers'

export default {
  name: 'Clients',
  components: {},
  data: () => ({
    roleText: RoleText,
    error: null,
		openFilters: false
  }),
	async beforeRouteLeave(to, from, next) {
    this.searchClients = ''
		this.filterClientByManager = null
    next()
  },
	created() {
		this.$store.dispatch('clients/loadClients')

		if (!this.managers || this.managers.length === 0) {
      this.$store.dispatch('users/loadManagers')
    }
	},
  computed: {
		tableHeadres() {
			return [
				{
					text: 'Клиент',
					align: 'start',
					sortable: false,
					value: 'name',
				},
				{
					text: 'Контакт',
					align: 'start',
					sortable: false,
					value: 'phonenumber',
				},
				{
					text: 'Деятельность',
					align: 'start',
					sortable: false,
					value: 'activity',
				},
				{
					text: 'Откуда',
					align: 'start',
					sortable: false,
					value: 'whereFrom',
				},
				{
					text: 'Зак./Монт./Произ.',
					align: 'start',
					sortable: false,
					value: 'statistics',
				},
				{
					text: 'Создал',
					align: 'start',
					sortable: false,
					value: 'creatorData',
				},
				{
					text: 'Действия',
					align: 'center',
					sortable: false,
					value: 'action',
				},
			]
		},
		companyActivitys() {
			let items = this.$store.getters['settings/getCompanyActivitys']
			items = items.map(x => x.title)
			return items
		},
		whereFromCompany() {
			let items = this.$store.getters['settings/getWhereFromCompany']
			items = items.map(x => x.title)
			return items
		},
    searchClients: {
      get() { return this.$store.getters['clients/getSearchClients'] },
      set(val) { this.$store.commit('clients/setSearchClients', val) }
    },
		filterClientByActivitys: {
      get() { return this.$store.getters['clients/getFilterClientByActivitys'] },
      set(val) { this.$store.commit('clients/setFilterClientByActivitys', val) }
    },
		filterClientByWhereFrom: {
      get() { return this.$store.getters['clients/getFilterClientByWhereFrom'] },
      set(val) { this.$store.commit('clients/setFilterClientByWhereFrom', val) }
    },
		filterClientByManager: {
      get() { return this.$store.getters['clients/getFilterClientByManager'] },
      set(val) { this.$store.commit('clients/setFilterClientByManager', val) }
    },
		filtersSelected() {
      let filters = [this.searchClients, this.filterClientByManager]
      filters = filters.filter(x => !!x)
      return filters.length
    },
    clients() {
      return this.$store.getters['clients/getClients']
    },
		managers() {
      return this.$store.getters['users/getManagers']
    },
    user() {
      return this.$store.getters['user/getUser']
    }
  },
  methods: {
		getMainContact(item) {
			if (item && item.length > 0) {
				let main = item.find(x => x.main)
				if (main) {
					return `${main.name}: ${main.phonenumber}`
				}
				return null
			}
			return null
		},
    async editClient(client) {
      console.log(client.id)
      const editClient = await this.$showModal({
        component: Client,
        isPersistent: true,
        props: {
          edit: true,
          data: client
        }
      })
      if (editClient) {
				this.$store.dispatch('clients/loadClients')
        console.log('Клиент обновлён')
      }
    },
    async createClient() {
      const createClient = await this.$showModal({
        component: Client,
        isPersistent: true
      })

      if (createClient) {
				this.$store.dispatch('clients/loadClients')
				console.log('Клиент создан')
      }
    },
    async deleteClient(client) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление клиента`,
          text: `Действительно хотите удалить клиента "${client.name}"?`
        }
      })

      if (res) {
        this.$store.dispatch('clients/deleteClient', client)
      }
    }
  }
}
</script>
