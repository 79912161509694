<template>
  <!-- красный rl #bf4037 -->
  <!-- темный серый фон в таб #626b70 -->
  <!-- новый морской #e0a677 -->
  <!-- новый горчичный #eec374 -->
  <v-app-bar 
    v-if="stateNavbar"
    flat
    app
    :dense="!isMobile"
    color="#e0a677"
    extension-height="0"
    class="header"
    :class="{ 'header__min-padding': isMobile }"
  >
    <v-app-bar-nav-icon icon="mdi-trach" v-if="$vuetify.breakpoint.mobile" @click.stop="backBtn ? $router.push(backBtn) : drawer = !drawer">
      <template v-if="backBtn" v-slot:default>
        <v-icon>mdi-arrow-left</v-icon>
      </template>
    </v-app-bar-nav-icon>

    <v-row v-if="tabs && isMobile && selectPage">
      <v-col cols="10">
        <v-select
          class="selectClean"
          color="white"
          :value="selectPage"
          @change="goToPage"
          :items="tabs"
          return-object
        ></v-select>
      </v-col>
    </v-row>

    <v-tabs
      v-else-if="tabs && !isMobile"
      color="white"
      background-color="#e0a677"
      class="header__tabs"
    >
      <v-tab
        v-for="item in tabs"
        :key="item.path"
        :to="`${item.path}`"
        exact
        :show-arrows="false"
        :style="isMobile ? 'min-width: 70px; font-size: 0.78em; padding: 0 10px;' : 'font-size: 0.78em;'"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>

    <v-toolbar-title
      v-else
      :style="`
				color: rgba(0,0,0,.54);
				${isMobile ? 'padding-left: 0px; font-size: 1.25em;' : 'font-size: 1.1em;'}
			`"
    >
      {{ pageTitle }}
    </v-toolbar-title>

    <v-spacer></v-spacer>
		<NotificationsDropList />
		<DropDownUserMenu />

    <ProgressBar
      :loading="loading"
      color="#ace2fc"
      slot="extension"
    />
  </v-app-bar>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import DropDownUserMenu from "@/components/Templ/DropDownUserMenu"
import NotificationsDropList from "@/components/Notifications/DropList"
import ProgressBar from "@/components/Templ/ProgressBar"

export default {
  name: 'Navbar',
  components: {
		DropDownUserMenu,
		NotificationsDropList,
    ProgressBar
  },
  data: () => ({
    isMobile: isMobile
  }),
  computed: {
    backBtn() {
      let route = this.$route
      if (this.user.role === 'admin' && route.name === 'User') {
        return { path: '/users' }
      }
      return null
    },
    selectPage() {
      let route = this.$route
      let findTab = this.tabs.find(x => {
        return route.path === x.path ? x : null
      })
      return findTab
    },
    tabs() {
      let route = this.$route
      if (route && route.matched && route.matched.length > 1) {
        if (route.matched[0] && route.matched[0].meta.tabs) {
          return route.matched[0].meta.tabs
        }
        return null
      }
      return null
    },
    pageTitle() {
      const route = this.$route
      if (route.name === 'User' && route.params.uid && this.users.length > 0) {
        let findUser = this.users.find(x => x.uid === route.params.uid)
        if (findUser) {
          return findUser.displayName
        }
        return 'Сотрудник'
      } else if (route.meta && route.meta.text) {
        return route.meta.text
      } else if (route && route.matched && route.matched.length > 1) {
        if (route.matched[0] && route.matched[0].meta.text) {
          return route.matched[0].meta.text
        }
        return ''
      }
      return ''
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    rightMenuShow() {
      return this.$route.name != 'Home' && !['master'].includes(this.user.role) && !isMobile
    },
    stateNavbar() {
      return this.$route.name != 'Login' && this.user
    },
    drawer: {
      get() {
        return this.$store.getters['templ/getDrawer']
      },
      set(val) {
        this.$store.commit('templ/setDrawer', val)
      }
    },
    miniDrawer: {
      get() {
        return this.$store.getters['templ/getMiniDrawer']
      },
      set(val) {
        this.$store.commit('templ/setMiniDrawer', val)
      }
    },
    loading() {
      return this.$store.getters['templ/getLoading']
    }
  },
  methods: {
    goToPage(tab) {
      this.$router.push({ path: tab.path })
    },
    changeDrawer () {
      this.drawer != this.drawer
    }
  }
}
</script>

<style lang="scss">
.header {
  &__tabs {
    .v-slide-group__prev, .v-slide-group__next {
      display: none !important;
    }
  }

  &__min-padding {
    .v-toolbar__content {
      padding-left: 8px;
      padding-right: 2px;
    }
  }
}
.v-toolbar__extension {
  /* height: 0px !important; */
  padding: 0px !important;
}
</style>