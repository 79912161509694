var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formNewTask",staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-text-field',{ref:"title",attrs:{"dense":"","color":"#e0a677","label":_vm.design ? 'Задача дизайнеру' : 'Задача от меня',"rules":_vm.$fieldRules.field},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save()}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-select',{ref:"whoTo",attrs:{"dense":"","value":_vm.whoTo,"items":_vm.users,"label":"Для кого задача","rules":_vm.$fieldRules.select,"multiple":"","item-value":"uid","item-color":"#e0a677"},on:{"change":_vm.changeWhoTo},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 3)?_c('my-mini-avatar',{attrs:{"user":item,"size":23}}):_vm._e(),(index === 3 && _vm.whoTo.length > 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.whoTo.length - 3)+" чел.) ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-avatar',{staticClass:"my-0"},[_c('my-mini-avatar',{attrs:{"user":item}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.displayName)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"#e0a677","input-value":active}})],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-text-field',{ref:"deadline",attrs:{"dense":"","color":"#e0a677","value":_vm.deadlineText,"label":"Дедлайн","clearable":"","rules":_vm.$fieldRules.date},on:{"click":function($event){return _vm.selectDate()},"click:clear":function($event){return _vm.clearDate()}}})],1)],1),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.save()}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-keyboard-return")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }