export default {
  state: {
    filterClientByManager: null,
    filterClientByActivitys: null,
    filterClientByWhereFrom: null,
  },
  getters: {
		getFilterClientByWhereFrom: state => {
      return state.filterClientByWhereFrom
    },
		getFilterClientByActivitys: state => {
      return state.filterClientByActivitys
    },
    getFilterClientByManager: state => {
      return state.filterClientByManager
    }
  },
  mutations: {
    resetAllFilterClient(state) {
			state.filterClientByWhereFrom = null
			state.filterClientByActivitys = null
      state.filterClientByManager = null
    },
    setFilterClientByWhereFrom(state, payload) {
      state.filterClientByWhereFrom = payload;
    },
		setFilterClientByActivitys(state, payload) {
      state.filterClientByActivitys = payload;
    },
		setFilterClientByManager(state, payload) {
      state.filterClientByManager = payload;
    }
  }
}