<template>
  <div class="pageAllMontages" :class="{ 'pa-3': !isMobile }">
    <v-card :tile="isMobile">
      <div class="d-flex align-center px-4">
        <v-row class="page_filterTable">
          <v-col cols="2">
            <v-text-field
              dense
              color="#e0a677"
              v-model="filterOrdersByMonthText"
              @click="selectMonth()"
              label="Месяц"
							clearable
            />
          </v-col>
					<v-col cols="1">
            <v-text-field
              dense
              color="#e0a677"
              v-model="filterOrdersByNumberOrder"
							@change="$store.dispatch('orders/loadOrders')"
              label="Номер заказа"
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterOrdersByClient"
              @change="$store.dispatch('orders/loadOrders')"
              label="Клиент"
              :items="clients"
              item-text="name"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="3">
            <!-- <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterOrdersByStatus"
              @change="$store.dispatch('orders/loadOrders')"
              label="Статус"
              :items="currentOrderStatusArr"
              clearable
            /> -->
						<v-select
							dense
							v-model="filterOrdersByStatus"
							:items="currentOrderStatusArr"
							label="Статус"
							multiple
							color="#e0a677"
							item-color="#e0a677"
							clearable
							@change="$store.dispatch('orders/loadOrders')"
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index < 1">
									<span>{{ item.text }}</span>
									<span 
										v-if="filterOrdersByStatus && index < filterOrdersByStatus.length - 1"
										class="mr-1"
									>,</span>
								</span>
								<span v-if="index === 1">
									(+{{ filterOrdersByStatus.length - 1 }})
								</span>
							</template>
							<template v-slot:item="{item, attrs, on}">
								<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
									<v-list-item-action>
										<v-checkbox color="#e0a677" :ripple="false" :input-value="active" />
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>
											<v-row no-gutters align="center">
												{{ item.text }}
											</v-row>
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>
						</v-select>
          </v-col>
          <v-col v-if="currentUser && currentUser.role != 'manager'" cols="2">
            <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterOrdersByManager"
              @change="$store.dispatch('orders/loadOrders')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
        </v-row>
				<v-spacer />
				<v-btn icon small @click="createOrder()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!ordersLoaded && (!orders || orders.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="ordersLoaded && (!orders || orders.length === 0)"
        class="pa-3 d-flex justify-center"
      >
        Нет заказов
      </div>
			<v-data-table
				v-if="orders && orders.length > 0"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				}"
				:headers="tableHeadres"
				:items="orders"
				:items-per-page="30"
 				:dense="!isMobile"
			>
				<template v-slot:item="{ item }">
					<tr 
						:style="
							item.color && !disabledColorBtn(item) ?
								 `background-color: ${item.color}` : ''"
						@click="editOrder(item)"
					>
						<td>
							<span v-if="item.client">
								{{ item.client.name }}
								<!-- <v-menu bottom right :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
											<v-icon>mdi-information-outline</v-icon>
										</v-btn>
									</template>
									<my-info-client :id="item.client.id" />
								</v-menu> -->
							</span>
							<span v-else>Не указан</span>
						</td>
						<td>
							{{ item.number }}
						</td>
						<td>
							{{ currentOrderStatus[item.status] }}
						</td>
						<td>
							<span
								:style="
									`${$colorDeadline(item.deadline.toDate())}`
								"
							>
								{{ $formatDateTime(item.deadline.toDate()) }}
							</span>
						</td>
						<td>
							<span v-if="item.description">{{ $sliceStr(item.description, 60) }}</span>
							<span v-else>Не задано</span>
						</td>
						<td>
							<span>{{ fullCost(item) }}р.</span>
						</td>
						<td>
							<span>{{ debt(item) }}р.</span>
						</td>
						<td v-if="currentUser.role === 'admin'">
							{{ item.creator.displayName }}
						</td>
						<td class="text-center">
							<v-menu left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										icon
										:disabled="disabledColorBtn(item)"
									>
										<v-icon>mdi-format-color-fill</v-icon>
									</v-btn>
								</template>

								<div style="background-color: white;">
									<div class="pt-2 px-5">
										<v-btn 
											v-if="item.color"
											:color="item.color || null"
											block
											@click="setColorItem('', item)"
										>
											Сбросить цвет
										</v-btn>
									</div>
									<v-color-picker
										@input="setColorItem($event, item)"
										:value="item.color || null"
										hide-canvas
										hide-inputs
										show-swatches
										swatches-max-height="230"
										mode="hexa"
										class="rl-table-color-picker"
									/>
								</div>
							</v-menu>
						</td>
					</tr>
				</template>
			</v-data-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { currentOrderStatus, currentOrderStatusArr } from '@/helpers'
import OrderModal from  '@/components/Modals/Order/Index'
import SelectMonth from '@/components/Modals/SelectMonth'

export default {
  name: 'AllOrders',
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    currentOrderStatus: currentOrderStatus,
    currentOrderStatusArr: currentOrderStatusArr,
    filterMontagesByDateText: '',
    openFilters: false
  }),
  async beforeRouteLeave(to, from, next) {
		this.$store.commit('orders/resetAllFilterOrders')
		if (this.currentUser.role === 'admin') {
			this.filterOrdersByManager = this.currentUser
		}
    this.$store.dispatch('orders/loadOrders')
    next()
  },
  created() {
		if (this.currentUser.role === 'admin') {
			this.filterOrdersByManager = this.currentUser
		}
    if (!this.orders || this.orders.length === 0) {
      this.$store.dispatch('orders/loadOrders')
    }
    if (!this.clients || this.clients.length === 0) {
      this.$store.dispatch('clients/loadClients')
    }
    if (!this.managers || this.managers.length === 0) {
      this.$store.dispatch('users/loadManagers')
    }
  },
  computed: {
		tableHeadres() {
			let headers = [
				{
					text: 'Клиент',
					align: 'start',
					sortable: false,
					value: 'client',
					order: 0
				},
				{
					text: 'Номер',
					align: 'start',
					sortable: false,
					value: 'number',
					order: 1
				},
				{
					text: 'Статус',
					align: 'start',
					sortable: false,
					value: 'status',
					order: 2
				},
				{
					text: 'Дедлайн',
					align: 'start',
					sortable: false,
					value: 'deadline',
					order: 3
				},
				{
					text: 'Описание',
					align: 'start',
					sortable: false,
					value: 'description',
					order: 4
				},
				{
					text: 'Стоимость',
					align: 'start',
					sortable: false,
					value: 'cost',
					order: 5
				},
				{
					text: 'Долг',
					align: 'start',
					sortable: false,
					value: 'debt',
					order: 6
				},
				{
					text: 'Цвет',
					align: 'center',
					sortable: false,
					value: 'color',
					order: 8
				},
			]
			
			if (this.currentUser.role === 'admin') {
				headers = [
					...headers,
					{
						text: 'Менеджер',
						align: 'start',
						sortable: false,
						value: 'creator',
						order: 7
					}
				]
			}

			headers = headers.sort(function(a,b){
				return a.order - b.order;
			})

			return headers
		},
		currentUser() {
			return this.$store.getters['user/getUser']
		},
    managers() {
      return this.$store.getters['users/getManagers']
    },
    ordersLoaded() {
      return this.$store.getters['orders/getOrdersLoaded']
    },
    orders() {
      return this.$store.getters['orders/getOrders']
    },
    clients() {
      return this.$store.getters['clients/getClients']
    },
		filterOrdersByMonthText: {
			get() {
				let month = this.filterOrdersByMonth
				if (month) {
					return String(new Date(month).toLocaleString('ru', {month: 'long', year: 'numeric'}))
				}
				return null
			},
			set(val) {
				this.filterOrdersByMonth = val
			}
    },
    filterOrdersByMonth: {
      get() { return this.$store.getters['orders/getFilterOrdersByMonth'] },
      set(val) { this.$store.commit('orders/setFilterOrdersByMonth', val) }
    },
    filterOrdersByClient: {
      get() { return this.$store.getters['orders/getFilterOrdersByClient'] },
      set(val) { this.$store.commit('orders/setFilterOrdersByClient', val) }
    },
    filterOrdersByStatus: {
      get() { return this.$store.getters['orders/getFilterOrdersByStatus'] },
      set(val) { this.$store.commit('orders/setFilterOrdersByStatus', val) }
    },
    filterOrdersByManager: {
      get() { return this.$store.getters['orders/getFilterOrdersByManager'] },
      set(val) { this.$store.commit('orders/setFilterOrdersByManager', val) }
    },
		filterOrdersByNumberOrder: {
      get() { return this.$store.getters['orders/getFilterOrdersByNumberOrder'] },
      set(val) { this.$store.commit('orders/setFilterOrdersByNumberOrder', val) }
    },
  },
  methods: {
		fullCost(item) {
			let works = item.works || []
			let summ = 0
			works.forEach(work => {
				summ = summ + Number(work.cost)
			});
			return summ
		},
		debt(item) {
			let cost = this.fullCost(item)

			let paid = item.paid
			let summ = 0
			paid.forEach(pay => {
				summ = summ + Number(pay.amount)
			});

			return cost - summ
		},
		async setColorItem(color, item) {
			if (color === '#FF0000FF' || (color && color.hexa === '#FF0000FF')) {
				color = null
			}
			await this.$store.dispatch('orders/updateOrder', {
				id: item.id,
				data: { color: color && color.hexa ? color.hexa : color }
			})
		},
		disabledColorBtn(item) {
			let itemCreatorId = item.creator.uid || item.creator.id
			if (itemCreatorId != this.currentUser.uid) {
				return true
			}
			return false
		},
		async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterOrdersByMonth
        }
      })
      if (modalMonth) {
        this.filterOrdersByMonth = modalMonth
				this.$store.dispatch('orders/loadOrders')
      }
    },
		async createOrder() {
			let order = await this.$store.dispatch('orders/createOrder')
			this.editOrder(order)
    },
		async editOrder(order) {
      await this.$showModal({
        component: OrderModal,
        isPersistent: true,
        props: {
          edit: true,
          data: order
        }
      })
    }
  }
}
</script>
