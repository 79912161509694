import { func, usersCollection, brigsCollection, locationsCollection } from '@/plugins/firebase'

export default {
    namespaced: true,
    state: {
        brigs: []
    },
    getters: {
        getBrigs: state => {
            const sortedBrigs = state.brigs.sort(function(a,b){
                return new Date(b.created) - new Date(a.created);
            })
            return sortedBrigs
        },
    },
    mutations: {
        setBrigs(state, payload) {
            state.brigs = payload;
        },
        pushBrig(state, payload) {
            state.brigs.push(payload)
        },
        updateBrig(state, brig) {
            const brigIndex = state.brigs.findIndex(x => x.id === brig.id)
            if (brigIndex != -1) {
                Vue.set(state.brigs, brigIndex, brig)
            }
        },
        deleteBrigs(state, id) {
            const indexBrig = state.brigs.findIndex(x => x.id === id)
            if (indexBrig != -1) {
                state.brigs.splice(indexBrig, 1)
            }
        }
    },
    actions: {
        loadBrigs({ commit, dispatch }) {
            commit('templ/setLoading', true, { root: true })
            brigsCollection.where('del', '!=', true).onSnapshot(async snapshot => {
                let brigsArr = []
                for (let doc of snapshot.docs) {
                    let brig = await dispatch('brigSerializer', { ...doc.data(), id: doc.id })
                    brigsArr.push(brig)
                }
            
                commit('setBrigs', brigsArr)
                commit('templ/setLoading', false, { root: true })
            })
        },
        async createBrig({ commit, dispatch }, payload) {
            commit('templ/setLoading', true, { root: true })
            console.log(payload)

            const data = { ...payload, del: false }

            if (data.users && data.users.length > 0) {
                let refs = []
                for (let user of data.users) {
                    let userRef = await usersCollection.doc(user.uid)
                    refs.push(userRef)
                }
                data.users = refs
            }

            await brigsCollection.doc()
                .set(data)
                .catch(console.error);
            commit('templ/setLoading', false, { root: true })
            dispatch('users/loadUsers', null, { root: true })
        },
        async updateBrig({ commit, dispatch }, payload) {
            commit('templ/setLoading', true, { root: true })
            const data = { ...payload.data }

            if (Object.keys(data).length > 0) {
                if (data.users) {
                    let refs = []
                    for (let user of data.users) {
                        let userRef = await usersCollection.doc(user.uid)
                        refs.push(userRef)
                    }
                    data.users = refs
                }

                brigsCollection.doc(payload.id).update(data)
                    .then(() => {
                        console.log('Бригада обновлена')
                    }).catch((err) => {
                        commit('templ/setError', 'Ошибка обновления бригады, попробуйте позже.')
                        console.log(err)
                    }).finally(() => {
                        commit('templ/setLoading', false, { root: true })
                        dispatch('users/loadUsers', null, { root: true })
                    })
            }
        },
        async deleteBrig({ commit, dispatch }, payload) {
            commit('templ/setLoading', true, { root: true })
            await brigsCollection.doc(payload.id).update({ del: true })
                .then(() => {
                    console.log('Бригада удалена')
                }).catch((err) => {
                    commit('templ/setError', 'Ошибка удаления бригады, попробуйте позже.')
                    console.log(err)
                }).finally(() => {
                    commit('templ/setLoading', false, { root: true })
                    dispatch('users/loadUsers', null, { root: true })
                })
        },
        async brigSerializer({ state, dispatch }, ref) {
            let brig = {}
            if (ref.id) {
              brig = ref
            } else {
              let res = await ref.get()
              brig = { ...res.data(), id: res.id }
            }

            let users = [ ...brig.users ]
            if (!brig.location) {
                brig = { ...brig, location: 'Не указан' }
            }

            brig.users = []

            for (let ref of users) {
                let user = await dispatch('users/userSerializer', ref, { root: true })
                if (user.del) {
                    user = { ...user, displayName: `${user.displayName}(Удалён)` }
                }
                brig.users.push(user)
            }

            return brig
        }
    }
}