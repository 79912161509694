import { 
	metaInfoCollection, montagesCollection,
	usersCollection, clientsCollection,
	brigsCollection, sendNotification
} from '@/plugins/firebase'
import { montagesStatus } from '@/helpers'
import loadedMontages from './loaded'
import filterMontages from './filter'

const snapshots = {
  loadUnassignedMontages: null,
  loadMontages: null,
  maxMontagesPaginationCount: null,
  loadMontagesForMontagerProfile: null,
  loadMontagesForMontager: null,
  loadAssignedMontages: null,
  loadManagerUnassignedMontages: null
}

export default {
  namespaced: true,
  state: {
    // montages
    montages: [],
    unassignedMontages: [],
    managerUnassignedMontages: [],
    assignedMontages: [],
    montagesForMontager: [],
    montagesForMontagerProfile: [],
    // loaded montages
    ...loadedMontages.state,
    // filters
    ...filterMontages.state,
    // pagination all montages
    montagesPage: 1,
    montagesPrev: null,
    montagesNext: null,
    maxMontagesCount: 0,
    // pagination user montages
    montagesForMontagerPage: 1,
    montagesForMontagerLastPage: 1
  },
  getters: {
    // montages
    getMontages: state => {
      const sortedMontages = state.montages.sort(function(a,b){
        return b.date.toDate() - a.date.toDate();
      })
      return sortedMontages
    },
    getUnassignedMontages: state => {
      const sortedMontages = state.unassignedMontages.sort(function(a,b){
        return a.date.toDate() - b.date.toDate();
      })
      return sortedMontages
    },
    getUnassignedManagerMontages: state => {
      const sortedMontages = state.managerUnassignedMontages.sort(function(a,b){
        return a.date.toDate() - b.date.toDate();
      })
      return sortedMontages
    },
    getAssignedMontages: state => {
      let montages = state.assignedMontages
      if (montages) {
        for (let keyForBrig in montages) {
          let sortedMontages = []
          let montagesWithOrder = montages[keyForBrig].montages.filter(x => x.order != undefined)
          let montagesWithoutOrder = montages[keyForBrig].montages.filter(x => x.order === undefined)
          montagesWithOrder = montagesWithOrder.sort(function(a,b){
            return a.order - b.order
          })
          sortedMontages = [ ...montagesWithOrder, ...montagesWithoutOrder ]
          montages[keyForBrig].montages = sortedMontages
        }
        return montages
      }
      return []
    },
    getMontagesForMontager: state => {
      const sortedMontages = state.montagesForMontager.sort(function(a,b){
        return b.date.toDate() - a.date.toDate();
      })
      return sortedMontages
    },
    getListMontagesPageForMontager: (state, getters) => {
      let montages = getters.getMontagesForMontager
      let pageMontages = state.montagesForMontagerPage

      let montagesPages = [];
      for (let i = 0; i < montages.length; i += 10) {
        montagesPages.push(montages.slice(i, i + 10));
      }

      let montagesArr = montagesPages[pageMontages - 1]

      return montagesArr ? montagesArr : []
    },
    getMontagesForMontagerProfile: state => {
      let montages = state.montagesForMontagerProfile
      let sortedMontages = []
      let montagesWithOrder = montages.filter(x => x.order != undefined)
      let montagesWithoutOrder = montages.filter(x => x.order === undefined)
      montagesWithOrder = montagesWithOrder.sort(function(a,b){
        return a.order - b.order
      })
      sortedMontages = [ ...montagesWithOrder, ...montagesWithoutOrder ]
      return sortedMontages
    },
    // filters
    ...filterMontages.getters,
    // loaded montages
    ...loadedMontages.getters,
    // pagination all montages
    getMontagesPage: state => { return state.montagesPage },
    getMontagesPrev: state => { return state.montagesPrev },
    getMontagesNext: state => { return state.montagesNext },
    getMaxMontagesCount: state => { return state.maxMontagesCount },
    // pagination user montages
    getMontagesForMontagerPage: state => { return state.montagesForMontagerPage },
    getMontagesForMontagerLastPage: state => { return state.montagesForMontagerLastPage }
  },
  mutations: {
    // montages
    setMontages(state, payload) {
		  state.montages = payload
    },
    setUnassignedMontages(state, payload) {
      state.unassignedMontages = payload;
    },
    setManagerUnassignedMontages(state, payload) {
      state.managerUnassignedMontages = payload;
    },
    setAssignedMontages(state, payload) {
      state.assignedMontages = payload;
    },
    setMontagesForMontager(state, payload) {
      state.montagesForMontager = payload;
    },
    setMontagesForMontagerProfile(state, payload) {
      state.montagesForMontagerProfile = payload;
    },
    updAssignedMontagesForOrder(state, payload) {
      if (payload.brig) {
        let montagesForBrig = state.assignedMontages.find(item => item.brig.id === payload.brig)
        let montages = montagesForBrig.montages
        if (payload.montage && payload.montage.id) {
          let item = montages.find(item => item.id === payload.montage.id)
          Object.assign(item, payload.montage)
        }
      }
    },
    // filters
    ...filterMontages.mutations,
    // loaded montages
    ...loadedMontages.mutations,
    // pagination all montages
    setMontagesPaginationPage(state, payload) {
      state.montagesPage = payload
    },
    setMontagesPagination(state, payload) {
      state[`montages${payload.action}`] = payload.cursor
    },
    setMaxMontagesPaginationCount(state, payload) {
      state.maxMontagesCount = payload
    },
    // pagination user montages
    setMontagesForMontagerPaginationPage(state, payload) {
      state.montagesForMontagerPage = payload
    },
    setMontagesForMontagerPaginationLastPage(state, payload) {
      state.montagesForMontagerLastPage = payload
    },
  },
  actions: {
    async loadMontageById({ commit, dispatch, getters }, id) {
      commit('templ/setLoading', true, { root: true })

      let queryStore = montagesCollection.doc(id)
			let doc = await queryStore.get()
			doc = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })

			commit('templ/setLoading', false, { root: true })
			return doc
    },
		async loadMontagesForMontagerProfile({ commit, dispatch, getters }, data) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadMontagesForMontagerProfile) {
        snapshots.loadMontagesForMontagerProfile()
        snapshots.loadMontagesForMontagerProfile = null
      }

      let queryStore = montagesCollection.where('del', '!=', true)

      if (data.uid) {
        queryStore = queryStore.where('montagers', 'array-contains', data.uid)
      }
      
      if (data.date) {
        queryStore = queryStore.where('date', '==', data.date)
      }

      snapshots.loadMontagesForMontagerProfile = queryStore.onSnapshot(async snapshot => {
        let montagesArr = []
        for (let doc of snapshot.docs) {
          let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
          montagesArr.push(montage)
        }
      
        commit('setMontagesForMontagerProfile', montagesArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    async loadMontagesForMontager({ commit, dispatch, getters }, data) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadMontagesForMontager) {
        snapshots.loadMontagesForMontager()
        snapshots.loadMontagesForMontager = null
      }

      let queryStore = montagesCollection.where('status', '==', 'ready')

      if (data.uid) {
        queryStore = queryStore.where('montagers', 'array-contains', data.uid)
      }

      if (data.month) {
        let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)

        queryStore = queryStore.orderBy("date").startAt(firstDay).endAt(lastDay)
      }

      snapshots.loadMontagesForMontager = await queryStore.onSnapshot(async snapshot => {
        let montagesArr = []
        for (let doc of snapshot.docs) {
          let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
          montagesArr.push(montage)
        }
        montagesArr = montagesArr.filter(x => !x.del)

        let newMontagesArr = [];
        for (let i = 0; i < montagesArr.length; i += 10) {
          newMontagesArr.push(montagesArr.slice(i, i + 10));
        }
        commit('setMontagesForMontagerPaginationLastPage', newMontagesArr.length)

        commit('setMontagesForMontager', montagesArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    async loadAssignedMontages({ commit, dispatch, getters }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadAssignedMontages) {
        snapshots.loadAssignedMontages()
        snapshots.loadAssignedMontages = null
      }

      let queryStore = montagesCollection.where('del', '!=', true).where('status', 'in', ['assigned', 'work', 'no_work', 'ready'])

      let filterByDate = getters.getFilterAssignedMontagesByDate
      if (filterByDate) {
        queryStore = queryStore.where('date', '==', filterByDate)
      }

      snapshots.loadAssignedMontages = queryStore.onSnapshot(async snapshot => {
        let brigsArr = []
        let brigs = await brigsCollection.where('del', '!=', true).get()
        for (let brigDoc of brigs.docs) {
          // let brig = await dispatch('brigs/brigSerializer', { ...brigDoc.data(), id: brigDoc.id }, { root: true })
          let brig = { ...brigDoc.data(), id: brigDoc.id }
          let obj = { brig: brig, montages: [] }
          brigsArr.push(obj)
        }
        for (let montageDoc of snapshot.docs) {
          let montage = await dispatch('montageSerializer', { ...montageDoc.data(), id: montageDoc.id })
          let brigIndex = brigsArr.findIndex(x => x.brig && x.brig.id === montage.brig.id)
          if (brigIndex != -1) {
            brigsArr[brigIndex].montages.push(montage)
          }
        }

        commit('setAssignedMontages', brigsArr)
        commit('templ/setLoading', false, { root: true })
        commit('setAssignedMontagesLoaded', true)
      })
    },
    loadMontages({ commit, dispatch, getters }, pagination) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadMontages) {
        snapshots.loadMontages()
        snapshots.loadMontages = null
      }
      
      if (snapshots.maxMontagesPaginationCount) {
        snapshots.maxMontagesPaginationCount()
        snapshots.maxMontagesPaginationCount = null
      }

      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate()+1)
      let toDay = new Date(tomorrow.toISOString().substr(0, 10))

      let queryStore = montagesCollection

      let filterByDate = getters.getFilterMontagesByDate
      if (filterByDate) {
        queryStore = queryStore.where('date', '==', filterByDate)
      } else {
        // queryStore = queryStore.where('date', '<=', toDay).orderBy("date", "desc")
      }

      queryStore = queryStore.where('del', '==', false)

      let filterByClient = getters.getFilterMontagesByClient
      if (filterByClient && filterByClient.id) {
        let clientRef = clientsCollection.doc(filterByClient.id)
        queryStore = queryStore.where('client', '==', clientRef)
      }
      
      let filterByStatus = getters.getFilterMontagesByStatus
      if (filterByStatus) {
        queryStore = queryStore.where('status', '==', filterByStatus)
      }
      
      let filterByManager = getters.getFilterMontagesByManager
      if (filterByManager && filterByManager.uid) {
        let managerRef = usersCollection.doc(filterByManager.uid)
        queryStore = queryStore.where('manager', '==', managerRef)
      }

      if (pagination) {
        let page = getters.getMontagesPage
        let prev = getters.getMontagesPrev
        let next = getters.getMontagesNext

        if (pagination === 'prev' && prev) {
          commit('setMontagesPaginationPage', page - 1)
          queryStore = queryStore.endBefore(prev).limitToLast(20)
        }
        if (pagination === 'next' && next) {
          commit('setMontagesPaginationPage', page + 1)
          queryStore = queryStore.startAfter(next).limit(20)
        }
      } else {
        commit('setMontagesPaginationPage', 1)
        queryStore = queryStore.limit(20)
      }

      snapshots.loadMontages = queryStore.onSnapshot(async snapshot => {
        // pagination
        if (snapshot.docs.length > 0 && snapshot.docs[0]) {
          commit('setMontagesPagination', { action: 'Prev', cursor: snapshot.docs[0] })
        }
        if (snapshot.docs.length === 20 && snapshot.docs[snapshot.docs.length-1]) {
          commit('setMontagesPagination', { action: 'Next', cursor: snapshot.docs[snapshot.docs.length-1] })
        }

        let montagesArr = []

        for (let doc of snapshot.docs) {
          let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
          montagesArr.push(montage)
        }
      
        commit('setMontages', montagesArr)
        commit('templ/setLoading', false, { root: true })
        commit('setMontagesLoaded', true)
      })

      snapshots.maxMontagesPaginationCount = metaInfoCollection.doc('montages').onSnapshot(async snapshot => {
        let metaInfo = snapshot.data()
        commit('setMaxMontagesPaginationCount', metaInfo.countDoc)
      })
    },
    loadUnassignedMontages({ commit, dispatch, getters }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadUnassignedMontages) {
        snapshots.loadUnassignedMontages()
        snapshots.loadUnassignedMontages = null
      }

      let queryStore = montagesCollection.where('del', '!=', true).where('status', '==', 'unassigned')

      let filterByClient = getters.getFilterUnassignedMontagesByClient
      if (filterByClient && filterByClient.id) {
        let clientRef = clientsCollection.doc(filterByClient.id)
        queryStore = queryStore.where('client', '==', clientRef)
      }
      
      let filterByManager = getters.getFilterUnassignedMontagesByManager
      if (filterByManager && filterByManager.uid) {
        let managerRef = usersCollection.doc(filterByManager.uid)
        queryStore = queryStore.where('manager', '==', managerRef)
      }
      
      let filterByDate = getters.getFilterUnassignedMontagesByDate
      if (filterByDate) {
        queryStore = queryStore.where('date', '==', filterByDate)
      }

      snapshots.loadUnassignedMontages = queryStore.onSnapshot(async snapshot => {
        let montagesArr = []

        for (let doc of snapshot.docs) {
          let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
          montagesArr.push(montage)
        }
      
        commit('setUnassignedMontages', montagesArr)
        commit('templ/setLoading', false, { root: true })
        commit('setUnassignedMontagesLoaded', true)
      })
    },
    loadManagerUnassignedMontages({ commit, dispatch }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadManagerUnassignedMontages) {
        snapshots.loadManagerUnassignedMontages()
        snapshots.loadManagerUnassignedMontages = null
      }

      let manager = this.getters['user/getUser']
      let managerRef = usersCollection.doc(manager.uid)

      let queryStore = montagesCollection.where('del', '!=', true).where('manager', '==', managerRef).where('status', '==', 'unassigned')

      snapshots.loadManagerUnassignedMontages = queryStore.onSnapshot(async snapshot => {
        let montagesArr = []

        for (let doc of snapshot.docs) {
          let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
          montagesArr.push(montage)
        }
      
        commit('setManagerUnassignedMontages', montagesArr)
        commit('templ/setLoading', false, { root: true })
        commit('setManagerMontagesLoaded', true)
      })
    },
    async createMontage({ commit, rootGetters, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
			let user = rootGetters['user/getUser']
      let obj = { ...data, del: false }

			// теперь в вотчере отслеживается и создаётся
			// if (!obj.numberOrder) {
			// 	let number = await dispatch('getNewNumberMontage')
			// 	obj.numberOrder = `m${number}`
			// }

			// client
      if (obj.client && obj.client.id) {
        obj.clientData = obj.client
				obj.client = clientsCollection.doc(obj.client.id)
      }

      // manager
      if (obj.manager) {
        let manager = await obj.manager.get()
        let managerData = manager.data()
        obj.managerData = {
          uid: managerData.uid,
          del: managerData.del,
          displayName: managerData.displayName,
          email: managerData.email,
          location: managerData.location
        }
      }

      // brig
      if (obj.brig) {
        let brig = await obj.brig.get()
        let brigData = brig.data()
        obj.brigData = {
          id: brig.id,
          displayName: brigData.displayName,
          del: brigData.del,
          location: brigData.location
        }
      }

      let newMontage = await montagesCollection.add(obj)

			if (obj.brig) {
				// отправляем монтажникам инфу о том что на них назначен монтаж
				let date = obj.date.toLocaleString('ru', {day: 'numeric', month: 'short' })
				let title = `Назначен монтаж на ${date}`
				let text = `Клиент: ${obj.clientData.name}`

				if (obj.montagers && obj.montagers.length > 0) {
					for (let toUid of obj.montagers) {
						await sendNotification(toUid, user.uid, {
							title: title,
							text: text,
							action: 'assignedMontage',
							payload: {
								montageId: newMontage.id
							}
						})
					}
				}
			}
      commit('templ/setLoading', false, { root: true })
    },
    async updateMontage({ commit, rootGetters }, obj,) {
      commit('templ/setLoading', true, { root: true })
			console.log(obj)
			let user = rootGetters['user/getUser']
      let id = obj.id
			let action = obj.action || null
			let manager = obj.manager || null
			let oldData = obj.oldData || null
      obj = obj.data

      // client
      if (obj.client && obj.client.id) {
        obj.clientData = obj.client
				obj.client = clientsCollection.doc(obj.client.id)
      }

      // manager
      if (obj.manager) {
        let manager = await obj.manager.get()
        let managerData = manager.data()
        obj.managerData = {
          uid: managerData.uid,
          del: managerData.del,
          displayName: managerData.displayName,
          email: managerData.email,
          location: managerData.location
        }
      }

      // brig
      if (obj.brig && !obj.brig.displayName) {
        let brig = await obj.brig.get()
        let brigData = brig.data()
        obj.brigData = {
          id: brig.id,
          displayName: brigData.displayName,
          del: brigData.del,
          location: brigData.location
        }
      }

      await montagesCollection.doc(id).update(obj)

			if (action === 'assigned' || action === 'unassigned') {
				// отправляем монтажникам и менеджеру инфу о том что монтаж назначен или отменён
				let whoTo = []
				if (action === 'assigned') {
					whoTo = [...obj.montagers, manager.uid]
				} else if (action === 'unassigned' && oldData && oldData.montagers) {
					whoTo = [...oldData.montagers, manager.uid]
				}
				let indexIID = whoTo.findIndex(x => x === user.uid)
				if (indexIID != -1) {
					whoTo.splice(indexIID, 1)
				}

				let date = obj.date ? obj.date : oldData.date.toDate()
				date = date.toLocaleString('ru', {day: 'numeric', month: 'short' })
				let title = ''
				let text = ''
				if (action === 'assigned') {
					title = `Назначен монтаж на ${date}`
					text = `Клиент: ${obj.clientData.name}`
				} else if (action === 'unassigned') {
					title = `Монтаж на ${date}, отменён`
					text = `Клиент: ${oldData.client.name}`
				}

				if (whoTo && whoTo.length > 0) {
					for (let toUid of whoTo) {
						await sendNotification(toUid, user.uid, {
							title: title,
							text: text,
							action: `montage`,
							payload: {
								montageId: id
							}
						})
					}
				}
			}

			if (['no_work', 'work', 'ready'].includes(obj.status)) {
				let date = oldData.date.toDate()
				date = date.toLocaleString('ru', {day: 'numeric', month: 'short' })
				let title = `Монтаж для ${oldData.client.name} на ${date}`
				let text = `Обновился статус: ${montagesStatus[obj.status]}`
				await sendNotification(manager.uid, user.uid, {
					title: title,
					text: text,
					action: `montage`,
					payload: {
						montageId: id
					}
				})
			}

      commit('templ/setLoading', false, { root: true })
    },
    async deleteMontage({ commit, rootState }, data) {
      commit('templ/setLoading', true, { root: true })
      await montagesCollection.doc(data.id).update({ del: true })
        .then(() => {
            console.log('Монтаж удален')
        }).catch((err) => {
            commit('templ/setError', 'Ошибка удаления монтажа, попробуйте позже.')
            console.log(err)
        }).finally(() => {
            commit('templ/setLoading', false, { root: true })
        })
    },
    async montageSerializer({ state, dispatch }, ref) {
      let montage = {}
      if (ref.id) {
        montage = ref
      } else {
        let res = await ref.get()
        montage = { ...res.data(), id: res.id }
      }

      if (montage.managerData) {
        montage = { ...montage, manager: montage.managerData }
        delete montage.managerData
      }

      if (montage.brigData) {
        montage = { ...montage, brig: montage.brigData }
        delete montage.brigData
      }

      if (montage.clientData) {
        montage = { ...montage, client: montage.clientData }
        delete montage.clientData
      }

      return montage
    },
		async getNewNumberMontage() {
			let toDay = new Date()
			let year = String(toDay.getFullYear()).substring(2)
			let month = toDay.getMonth() + 1

			if (month < 10) {
				month = `0${month}`
			}

			let date = new Date(), y = date.getFullYear(), m = date.getMonth()
			let firstDay = new Date(y, m, 1, 0)
			let lastDay = new Date(y, m + 1, 0, 23, 59)

			// console.log(`Дата по UTC: ${date}`)
			// console.log(`Первый день(${firstDay}), последний день(${lastDay})`)

			let numberInMonth = '01'
			let QueryStore = await montagesCollection.orderBy('date').startAt(firstDay).endAt(lastDay).get()
			let lengthList = QueryStore.docs
			lengthList = lengthList.length + 1

			if (lengthList < 10) {
				numberInMonth = `0${lengthList}`
			} else {
				numberInMonth = lengthList
			}

			return `${year}${month}${numberInMonth}`
		}
  }
}