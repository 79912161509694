<template>
  <div class="page pageManufactoryTasks" :class="{ 'pa-3': !isMobile }">
    <v-card :tile="isMobile">
      <div v-if="isMobile">
        <v-row class="page_filterTable ma-0 px-1">
          <v-col cols="8">
            <v-text-field
              color="#e0a677"
              v-model="filterTasksByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="2" class="pt-6">
              <v-btn icon @click="openFilters = !openFilters">
                <v-badge
                  v-if="filtersSelected"
                  color="orange"
                  overlap
                  :content="filtersSelected"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-badge>
                <v-icon v-else>mdi-tune</v-icon>
              </v-btn>
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="createTask()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
                  color="#e0a677"
                  v-model="filterTasksByClient"
                  @change="$store.dispatch('manufactory/loadTasks')"
                  label="Клиент"
                  :items="clients"
                  item-text="name"
                  return-object
                  clearable
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  color="#e0a677"
                  v-model="filterTasksByManager"
                  @change="$store.dispatch('manufactory/loadTasks')"
                  label="Менеджер"
                  :items="managers"
                  item-text="displayName"
                  return-object
                  clearable
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable">
          <v-col cols="2">
            <v-text-field
              dense
              color="#e0a677"
              v-model="filterTasksByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterTasksByClient"
              @change="$store.dispatch('manufactory/loadTasks')"
              label="Клиент"
              :items="clients"
              item-text="name"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="#e0a677"
              v-model="filterTasksByManager"
              @change="$store.dispatch('manufactory/loadTasks')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-center mt-1">
            <v-btn 
              icon
              small
              @click="tasksPage > 1 ? $store.dispatch('manufactory/loadTasks', 'prev') : null"
              :disabled="tasksPage === 1"
            >
              <v-icon size="22">mdi-chevron-left</v-icon>
            </v-btn>
            <span style="font-size: 15px;">{{ tasksPage }} стр.</span>
            <v-btn 
              icon
              small
              @click="tasks.length > 0 && (20 * tasksPage - (20 - tasks.length)) < maxTaskCount ? $store.dispatch('manufactory/loadTasks', 'next') : null"
              :disabled="(20 * tasksPage - (20 - tasks.length)) >= maxTaskCount"
            >
              <v-icon size="22">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn icon small @click="createTask()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!tasksLoaded && (!tasks || tasks.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="tasksLoaded && (!tasks || tasks.length === 0)"
        class="infoNoItems"
      >
        Нет заданий
      </div>
      <v-simple-table :dense="!isMobile"  v-if="tasks && tasks.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дедлайн</th>
              <th class="text-left">Клиент</th>
              <th class="text-left">Комментарий</th>
              <th class="text-left">Тех. задание</th>
              <th class="text-left">Статус</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="task in tasks"
              :key="task.id"
              @click="editTask(task)"
              class="tableItemList"
            >
              <td style="min-width: 80px;">{{ $formatDate(task.date.toDate()) }}</td>
              <td style="min-width: 125px;">
								{{ task.client.name }}
								<!-- <v-menu bottom right :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
											<v-icon>mdi-information-outline</v-icon>
										</v-btn>
									</template>
									<my-info-client :id="task.client.id" />
								</v-menu> -->
							</td>
              <td style="min-width: 125px; max-width: 280px;">
                <span v-if="task.comment">
                  {{ $sliceStr(task.comment, 60) }}
                </span>
                <span v-else>Нет комментария</span>
              </td>
              <td>
                <a v-if="task.techTask" @click.stop="goUrl(task.techTask)" href="#">Открыть</a>
                <span v-else>Нет тех. задания</span>
              </td>
              <td>{{ manufactoryTaskStatus[task.status] }}</td>
              <td>{{ task.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider v-if="isMobile && tasks.length > 0" />
      <v-row v-if="isMobile && tasks.length > 0" class="ma-0">
        <v-col cols="12" class="d-flex align-center justify-center mt-1">
          <v-btn 
            icon
            large
            @click="tasksPage > 1 ? $store.dispatch('manufactory/loadTasks', 'prev') : null"
            :disabled="tasksPage === 1"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span>{{ tasksPage }} стр.</span>
          <v-btn 
            icon
            large
            @click="tasks.length > 0 && (20 * tasksPage - (20 - tasks.length)) < maxTaskCount ? $store.dispatch('manufactory/loadTasks', 'next') : null"
            :disabled="(20 * tasksPage - (20 - tasks.length)) >= maxTaskCount"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { manufactoryTaskStatus, manufactoryTaskStatusArr } from '@/helpers'
import ManufactoryTasksModal from  '@/components/Modals/Manufactory/Task'
import SelectDate from '@/components/Modals/SelectDate'

  export default {
    name: 'ConfirmationManufactoryTask',
    data: () => ({
      isMobile: isMobile,
      skeletonTypesIframe: {
        'table-thead': 'heading@4',
        'table-row': 'table-cell@4'
      },
      manufactoryTaskStatus: manufactoryTaskStatus,
      manufactoryTaskStatusArr: manufactoryTaskStatusArr,
      filterTasksByDateText: '',
      openFilters: false
    }),
    async beforeRouteLeave(to, from, next) {
      this.$store.commit('manufactory/resetAllFilterTasks')
      this.$store.dispatch('manufactory/loadTasks')
      next()
    },
    created() {
      if (!this.tasks || this.tasks.length === 0) {
        this.$store.dispatch('manufactory/loadTasks')
      }
      if (!this.clients || this.clients.length === 0) {
        this.$store.dispatch('clients/loadClients')
      }
      if (!this.managers || this.managers.length === 0) {
        this.$store.dispatch('users/loadManagers')
      }
    },
    computed: {
      maxTaskCount() {
        return this.$store.getters['manufactory/getMaxTaskCount']
      },
      tasksPage() {
        return this.$store.getters['manufactory/getTasksPage']
      },
      filtersSelected() {
        let filters = [this.filterTasksByClient, this.filterTasksByManager]
        filters = filters.filter(x => !!x)
        return filters.length
      },
      managers() {
        return this.$store.getters['users/getManagers']
      },
      tasksLoaded() {
        return this.$store.getters['manufactory/getTasksLoaded']
      },
      tasks() {
        return this.$store.getters['manufactory/getTasks']
      },
      clients() {
        return this.$store.getters['clients/getClients']
      },
      filterTasksByDate: {
        get() { return this.$store.getters['manufactory/getFilterTasksByDate'] },
        set(val) { this.$store.commit('manufactory/setFilterTasksByDate', val) }
      },
      filterTasksByClient: {
        get() { return this.$store.getters['manufactory/getFilterTasksByClient'] },
        set(val) { this.$store.commit('manufactory/setFilterTasksByClient', val) }
      },
      filterTasksByManager: {
        get() { return this.$store.getters['manufactory/getFilterTasksByManager'] },
        set(val) { this.$store.commit('manufactory/setFilterTasksByManager', val) }
      },
    },
    methods: {
      clearDate() {
        this.filterTasksByDate = null
        this.filterTasksByDateText = ''
        this.$store.dispatch('manufactory/loadTasks')
      },
      async selectDate() {
        let modalDate = await this.$showModal({
          component: SelectDate,
          isPersistent: true,
          props: {
            title: 'Дата заказа',
            selectDate: this.filterTasksByDate ? this.filterTasksByDate.toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
          }
        })
        if (modalDate) {
          this.filterTasksByDate = new Date(modalDate)
          this.filterTasksByDateText = new Date(modalDate).toLocaleString('ru', {weekday: 'short', day: 'numeric', month: 'short'})
          this.$store.dispatch('manufactory/loadTasks')
        }
      },
      goUrl(url) {
        window.open(url, '_blank').focus();
      },
      async createTask() {
        await this.$showModal({
          component: ManufactoryTasksModal,
          isPersistent: true,
          props: {
            edit: false
          }
        })
      },
      async editTask(task) {
        await this.$showModal({
          component: ManufactoryTasksModal,
          isPersistent: true,
          props: {
            edit: true,
            data: task
          }
        })
      }
    }
  }
</script>
