import { 
	ordersCollection,
	usersCollection,
	clientsCollection,
	manufactoryCollection,
	montagesCollection,
	tasksCollection
} from '@/plugins/firebase'

import { currentOrderStatus } from '@/helpers'

import loadedOrders from './loaded'
import filterOrders from './filter'

const snapshots = {
  loadMyOrders: null,
	loadMyDraftOrders: null,
	loadOrdersForManager: null,
	loadOrders: null,
	loadDraftOrders: null,
	loadArchiveOrders: null,
}

export default {
  namespaced: true,
  state: {
    myOrders: [],
    myDraftOrders: [],
		// pagination user orders
		ordersForManager: [],
		// loaded
    ...loadedOrders.state,
		// filters
    ...filterOrders.state,
		orders: [],
		draftOrders: [],
		archiveOrders: [],
  },
  getters: {
    getMyOrders: state => {
      const sortedArr = state.myOrders.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedArr
    },
		getMyDraftOrders: state => {
      const sortedArr = state.myDraftOrders.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedArr
    },
		getOrdersForManager: (state, getters) => {
      const sortedOrders = state.ordersForManager.sort(function(a,b){
        return b.deadline.toDate() - a.deadline.toDate();
      })
      return sortedOrders
    },
		// loaded
    ...loadedOrders.getters,
		// filters
    ...filterOrders.getters,
		getOrders: state => {
			let orders = state.orders
			let search = state.filterOrdersByNumberOrder ? state.filterOrdersByNumberOrder.toLowerCase() : null

			if (search) {
				orders = orders.filter(x => {
					if (x.number) {
							let number = x.number.toLowerCase()
							if (number.includes(search)) { return true }
					}
					return false
				})
			}

      const sortedArr = orders.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedArr
    },
		getDraftOrders: state => {
			let orders = state.draftOrders
			let search = state.filterDraftOrdersByNumberOrder ? state.filterDraftOrdersByNumberOrder.toLowerCase() : null

			if (search) {
				orders = orders.filter(x => {
					if (x.number) {
							let number = x.number.toLowerCase()
							if (number.includes(search)) { return true }
					}
					return false
				})
			}

      const sortedArr = orders.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedArr
    },
		getArchiveOrders: state => {
			let orders = state.archiveOrders
			let search = state.filterArchiveOrdersByNumberOrder ? state.filterArchiveOrdersByNumberOrder.toLowerCase() : null

			if (search) {
				orders = orders.filter(x => {
					if (x.number) {
							let number = x.number.toLowerCase()
							if (number.includes(search)) { return true }
					}
					return false
				})
			}

      const sortedArr = orders.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedArr
    },
  },
  mutations: {
    setMyOrders(state, payload) {
      state.myOrders = payload
    },
		setMyDraftOrders(state, payload) {
      state.myDraftOrders = payload
    },
		setOrdersForManager(state, payload) {
      state.ordersForManager = payload;
    },
		// loaded
    ...loadedOrders.mutations,
		// filters
    ...filterOrders.mutations,
		setOrders(state, payload) {
      state.orders = payload
    },
		setDraftOrders(state, payload) {
      state.draftOrders = payload
    },
		setArchiveOrders(state, payload) {
      state.archiveOrders = payload
    },
  },
  actions: {
		loadArchiveOrders({ commit, dispatch, getters, rootGetters }, pagination) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadArchiveOrders) {
        snapshots.loadArchiveOrders()
        snapshots.loadArchiveOrders = null
      }

			let user = rootGetters['user/getUser']

      let queryStore = ordersCollection

			let filterByMonth = getters.getFilterArchiveOrdersByMonth
			if (filterByMonth) {
        let date = new Date(filterByMonth), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)

        queryStore = queryStore.orderBy("deadline").startAt(firstDay).endAt(lastDay)
      }

      queryStore = queryStore.where('del', '==', false)
				.where('archive', '==', true).where('client', '!=', null)

      let filterByClient = getters.getFilterArchiveOrdersByClient
      if (filterByClient && filterByClient.id) {
        let clientRef = clientsCollection.doc(filterByClient.id)
        queryStore = queryStore.where('client', '==', clientRef)
      }

      let filterByStatus = getters.getFilterArchiveOrdersByStatus
      if (filterByStatus) {
        queryStore = queryStore.where('status', '==', filterByStatus)
      }

			if (user.role === 'manager') {
				let managerRef = usersCollection.doc(user.uid)
				queryStore = queryStore.where('creator', '==', managerRef)
			} else {
				let filterByManager = getters.getFilterArchiveOrdersByManager
				if (filterByManager && filterByManager.uid) {
					let managerRef = usersCollection.doc(filterByManager.uid)
					queryStore = queryStore.where('creator', '==', managerRef)
				}
			}

      snapshots.loadArchiveOrders = queryStore.onSnapshot(async snapshot => {
        let ordersArr = []

        for (let doc of snapshot.docs) {
          let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
          ordersArr.push(order)
        }
      
        commit('setArchiveOrders', ordersArr)
        commit('templ/setLoading', false, { root: true })
				commit('setArchiveOrdersLoaded', true)
      })
    },
		loadDraftOrders({ commit, dispatch, getters, rootGetters }, pagination) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadDraftOrders) {
        snapshots.loadDraftOrders()
        snapshots.loadDraftOrders = null
      }

			let user = rootGetters['user/getUser']

      let queryStore = ordersCollection

			let filterByMonth = getters.getFilterDraftOrdersByMonth
			if (filterByMonth) {
        let date = new Date(filterByMonth), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)

        queryStore = queryStore.orderBy("deadline").startAt(firstDay).endAt(lastDay)
      }

      queryStore = queryStore.where('del', '==', false)
				.where('status', '==', 'draft').where('archive', '==', false)
				.where('client', '!=', null)

      let filterByClient = getters.getFilterDraftOrdersByClient
      if (filterByClient && filterByClient.id) {
        let clientRef = clientsCollection.doc(filterByClient.id)
        queryStore = queryStore.where('client', '==', clientRef)
      }

			if (user.role === 'manager') {
				let managerRef = usersCollection.doc(user.uid)
				queryStore = queryStore.where('creator', '==', managerRef)
			} else {
				let filterByManager = getters.getFilterDraftOrdersByManager
				if (filterByManager && filterByManager.uid) {
					let managerRef = usersCollection.doc(filterByManager.uid)
					queryStore = queryStore.where('creator', '==', managerRef)
				}
			}

      snapshots.loadDraftOrders = queryStore.onSnapshot(async snapshot => {
        let ordersArr = []

        for (let doc of snapshot.docs) {
          let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
          ordersArr.push(order)
        }
      
        commit('setDraftOrders', ordersArr)
        commit('templ/setLoading', false, { root: true })
				commit('setDraftOrdersLoaded', true)
      })
    },
		loadOrders({ commit, dispatch, getters, rootGetters }, pagination) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadOrders) {
        snapshots.loadOrders()
        snapshots.loadOrders = null
      }

			let user = rootGetters['user/getUser']

      let queryStore = ordersCollection

			let filterByMonth = getters.getFilterOrdersByMonth
			if (filterByMonth) {
        let date = new Date(filterByMonth), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)

        queryStore = queryStore.orderBy("deadline").startAt(firstDay).endAt(lastDay)
      }

      queryStore = queryStore.where('del', '==', false).where('archive', '==', false)

      let filterByClient = getters.getFilterOrdersByClient
      if (filterByClient && filterByClient.id) {
        let clientRef = clientsCollection.doc(filterByClient.id)
        queryStore = queryStore.where('client', '==', clientRef)
      }

      let filterByStatus = getters.getFilterOrdersByStatus
      if (filterByStatus && filterByStatus.length > 0) {
        // queryStore = queryStore.where('status', '==', filterByStatus)
        queryStore = queryStore.where('status', 'in', filterByStatus)
      } else {
				queryStore = queryStore.where('status', 'in', [...Object.keys(currentOrderStatus)])
			}

			if (user.role === 'manager') {
				let managerRef = usersCollection.doc(user.uid)
				queryStore = queryStore.where('creator', '==', managerRef)
			} else {
				let filterByManager = getters.getFilterOrdersByManager
				if (filterByManager && filterByManager.uid) {
					let managerRef = usersCollection.doc(filterByManager.uid)
					queryStore = queryStore.where('creator', '==', managerRef)
				}
			}

      snapshots.loadOrders = queryStore.onSnapshot(async snapshot => {
        let ordersArr = []

        for (let doc of snapshot.docs) {
          let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
          ordersArr.push(order)
        }
      
        commit('setOrders', ordersArr)
        commit('templ/setLoading', false, { root: true })
				commit('setOrdersLoaded', true)
      })
    },
		async loadOrdersForManager({ commit, dispatch, getters }, data) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadOrdersForManager) {
        snapshots.loadOrdersForManager()
        snapshots.loadOrdersForManager = null
      }

      let queryStore = ordersCollection.where('archive', '==', true).where('status', '==', 'ready')

      if (data.uid) {
				let userRef = usersCollection.doc(data.uid)
       	queryStore = queryStore.where('creator', '==', userRef)
      }

      if (data.month) {
        let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)

        queryStore = queryStore.orderBy("archiveDate").startAt(firstDay).endAt(lastDay)
      }

      snapshots.loadOrdersForManager = await queryStore.onSnapshot(async snapshot => {
        let ordersArr = []
        for (let doc of snapshot.docs) {
          let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
          ordersArr.push(order)
        }
        ordersArr = ordersArr.filter(x => !x.del)

        commit('setOrdersForManager', ordersArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    async loadMyDraftOrders({ commit, dispatch, rootState }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadMyDraftOrders) {
        snapshots.loadMyDraftOrders()
        snapshots.loadMyDraftOrders = null
      }

      let user = rootState.user.user
			let userRef = usersCollection.doc(user.uid)

      let queryStore = ordersCollection
				.where('del', '==', false)
				.where('archive', '==', false)
        .where('creator', '==', userRef)
        .where('status', '==', 'draft')
				.where('client', '!=', null)

      snapshots.loadMyDraftOrders = queryStore.onSnapshot(async snapshot => {
        let ordersArr = []

        for (let doc of snapshot.docs) {
          let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
          ordersArr.push(order)
        }
      
        commit('setMyDraftOrders', ordersArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
		async loadMyOrders({ commit, dispatch, rootState }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadMyOrders) {
        snapshots.loadMyOrders()
        snapshots.loadMyOrders = null
      }

      let user = rootState.user.user
			let userRef = usersCollection.doc(user.uid)

      let queryStore = ordersCollection
				.where('del', '==', false)
				.where('archive', '==', false)
        .where('creator', '==', userRef)
        .where('status', '!=', 'draft')

      snapshots.loadMyOrders = queryStore.onSnapshot(async snapshot => {
        let ordersArr = []

        for (let doc of snapshot.docs) {
          let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
          ordersArr.push(order)
        }
      
        commit('setMyOrders', ordersArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
		async loadOrder({ commit, dispatch, rootState }, number) {
      commit('templ/setLoading', true, { root: true })
      let queryStore = ordersCollection.where('number', '==', number)
			let result = await queryStore.get()

			let ordersArr = []

			for (let doc of result.docs) {
				let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
				ordersArr.push(order)
			}
			commit('templ/setLoading', false, { root: true })
			// позже надо слелать вывод ошибки если вдруг заказ не будет найден
			return ordersArr[0]
    },
		async loadOrderById({ commit, dispatch, getters }, id) {
      commit('templ/setLoading', true, { root: true })

      let queryStore = ordersCollection.doc(id)
			let doc = await queryStore.get()
			doc = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })

			commit('templ/setLoading', false, { root: true })
			return doc
    },
		async loadOrderLink({ commit, dispatch, rootState }, number) {
      commit('templ/setLoading', true, { root: true })

			let links = []

			// load Montages by number
			let queryStoreMontages = montagesCollection.where('del', '==', false)
				.where('numberOrder', '==', number)
			let resultMontages = await queryStoreMontages.get()
			for (let doc of resultMontages.docs) {
				let montage = await dispatch('montages/montageSerializer', { ...doc.data(), id: doc.id }, {root:true})
				links.push({
					type: 'montage',
					id: montage.id,
					title: montage.client.name,
					status: montage.status
				})
			}

			// load Manufactory by number
			let queryStoreManufactory = manufactoryCollection.where('del', '==', false)
				.where('numberOrder', '==', number)
			let resultManufactory = await queryStoreManufactory.get()
			for (let doc of resultManufactory.docs) {
				let manufactory = await dispatch('manufactory/taskSerializer', { ...doc.data(), id: doc.id }, {root:true})
				links.push({
					type: 'manufactory',
					id: manufactory.id,
					title: manufactory.client.name,
					status: manufactory.status
				})
			}

			// load Task by number
			let queryStoreTask = tasksCollection.where('del', '==', false)
				.where('numberOrder', '==', number)
			let resultTask = await queryStoreTask.get()
			for (let doc of resultTask.docs) {
				let task = { ...doc.data(), id: doc.id }
				links.push({
					type: 'task',
					id: task.id,
					title: task.title,
					status: task.archive ? 'В архиве' : task.ready ? 'Готово' : 'В работе'
				})
			}

			commit('templ/setLoading', false, { root: true })
			return links
    },
    async createOrder({ commit, rootState, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
      let user = rootState.user.user
      let creator = usersCollection.doc(user.uid)
			let numberOrder = await dispatch('getNewNumberOrder')

      const obj = { 
        creator: creator,
				creatorData: {
					id: user.uid,
					avatar: user.avatar,
					del: user.del,
					displayName: user.displayName,
					email: user.email,
					location: user.location
				},
        archive: false,
        del: false,
				client: null,
				date: new Date(),
				deadline: new Date(),
				paid: [],
				works: [],
				status: 'draft',
				number: numberOrder
      }

      let newOrder = await ordersCollection.add(obj)

			if (newOrder && newOrder.id) {
				let obj = {
					text: 'Создал(а) этот заказ.',
					files: null,
					date: new Date,
					from: usersCollection.doc(user.uid),
					program: true
				}
	
				await ordersCollection.doc(newOrder.id).collection('comments').add(obj)
			}

      commit('templ/setLoading', false, { root: true })

			return await dispatch('orderSerializer', newOrder)
    },
		async updateOrder({ commit, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
      let doc = ordersCollection.doc(data.id)
			let obj = data.data

			if (obj.client && obj.client.id) {
				obj.clientData = obj.client
				obj.client = clientsCollection.doc(obj.client.id)
			} else if (obj.client && !obj.client.id) {
				obj.clientData = null
				obj.client = null
			}

			console.log(obj)

			await doc.update(obj)
      commit('templ/setLoading', false, { root: true })
			return await dispatch('orderSerializer', doc)
    },
		async orderSerializer({ state, dispatch }, ref) {
      let order = {}
      if (!ref.path) {
        order = ref
      } else {
        let res = await ref.get()
        order = { ...res.data(), id: res.id }
      }

      if (order.clientData) {
        order = { 
					...order,
					client: {
						...order.clientData,
						id: order.client.id
					}
				}
        delete order.clientData
      }
			
			if (order.creator) {
				let creator = {}
				if (order.creatorData) {
					creator = order.creatorData
				} else {
					creator = await order.creator.get()
					creator = creator.data()
				}
        order = {
					...order,
					creator: {
						...creator,
						id: order.creator.id
					}
				}
        delete order.creatorData
      }

      return order
    },
		async getNewNumberOrder() {
			let toDay = new Date()
			let year = String(toDay.getFullYear()).substring(2)
			let month = toDay.getMonth() + 1

			if (month < 10) {
				month = `0${month}`
			}

			let date = new Date(), y = date.getFullYear(), m = date.getMonth()
			let firstDay = new Date(y, m, 1, 0)
			let lastDay = new Date(y, m + 1, 0, 23, 59)

			// console.log(`Дата по UTC: ${date}`)
			// console.log(`Первый день(${firstDay}), последний день(${lastDay})`)

			let numberInMonth = '01'
			let orderQueryStore = await ordersCollection.orderBy('date').startAt(firstDay).endAt(lastDay).get()
			let lengthList = orderQueryStore.docs
			lengthList = lengthList.length + 1

			if (lengthList < 10) {
				numberInMonth = `0${lengthList}`
			} else {
				numberInMonth = lengthList
			}

			return `${year}${month}${numberInMonth}`
		}
  }
}