import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './stores/store'
import vuetify from './plugins/vuetify'
import ModalPromise from 'vue-modal-promise';
import { VueMaskDirective } from 'v-mask'
import { fb, db, auth, func, storage, setFcmToken, sendNotification } from './plugins/firebase'
import { AddUserToStore } from '@/helpers'
import './assets/styles/main.scss'
import './registerServiceWorker'
import { isMobile } from 'mobile-device-detect';

import MyMiniAvatar from '@/components/MiniAvatar'
import PreviewImage from '@/components/PreviewImage'
import InfoClient from  '@/components/Clients/Widgets/Info'

import TaskModal from  '@/components/Modals/Task/Index'
import ManufactoryTaskModal from  '@/components/Modals/Manufactory/Task'
import MontageModal from  '@/components/Modals/Montage/Index'
import OrderModal from  '@/components/Modals/Order/Index'

Vue.component('my-mini-avatar', MyMiniAvatar)
Vue.component('my-preview-image', PreviewImage)
Vue.component('my-info-client', InfoClient)

Vue.use(ModalPromise)
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false

Vue.prototype.$fb = fb
Vue.prototype.$db = db
Vue.prototype.$auth = auth
Vue.prototype.$func = func
Vue.prototype.$storage = storage

// { to: uid, from: uid, data: { title: string, text: string, action: string } }
Vue.prototype.$sendNotification = sendNotification


Vue.prototype.$isMobile = isMobile

Vue.prototype.$getFbFileUrl = async function (path) {
  const url = await storage.ref(path).getDownloadURL()
  return url
}

Vue.prototype.$delFbFile = async function(path) {
  try {
    await storage.ref(path).delete()
    return true
  } catch {
    return false
  }
}

Vue.prototype.$formatDate = function (date) {
  if (date) {
    return date.toLocaleString('ru', {day: 'numeric', month: 'short' })
  }
  return null
}

Vue.prototype.$openOrder = async function (number) {
	let orderData = await this.$store.dispatch('orders/loadOrder', number)
	await this.$showModal({
		component: OrderModal,
		isPersistent: true,
		props: {
			edit: true,
			data: orderData
		}
	})
}

Vue.prototype.$openModalFromNotif = async function (item) {
	const action = item.action
	const payload = item.payload || null
	let modalComponent = null
	let modalData = null
	let customProps = {}

	if (this.$isMobile) { return }

	switch (action) {
		case 'newTask':
		case 'updateTask':
		case 'newCommentToTask':
			if (payload && payload.taskId) {
				modalData = await this.$store.dispatch('tasks/loadTaskById', payload.taskId)
				customProps = { ...customProps, design: modalData.design }
				modalComponent = TaskModal
			}
			break;
		// case 'workManufactoryTask': // отправка уведомлений Мастерам когда на них назначили
		case 'newCommentToTask':
		case 'readyManufactoryTask':
		case 'confirmProdManufactoryTask':
		case 'newCommentToManufactory':
			if (payload && payload.taskId) {
				modalData = await this.$store.dispatch('manufactory/loadTaskById', payload.taskId)
				modalComponent = ManufactoryTaskModal
			}
			break;
		case 'newCommentToOrder':
			if (payload && payload.orderId) {
				modalData = await this.$store.dispatch('orders/loadOrderById', payload.orderId)
				modalComponent = OrderModal
			}
			break;
		case 'montage':
			if (payload && payload.montageId) {
				modalData = await this.$store.dispatch('montages/loadMontageById', payload.montageId)
				modalComponent = MontageModal
			}
			break;
	}

	if (modalComponent && modalData) {
		await this.$showModal({
			component: modalComponent,
			isPersistent: true,
			props: {
				edit: true,
				data: modalData,
				...customProps
			}
		})
	}
}

Vue.prototype.$formatDateTime = function (date) {
  if (date) {
    return date.toLocaleString('ru', {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' })
  }
  return null
}

Vue.prototype.$colorDeadline = function (date) {
  function fDiffDays(date1, date2) {
    let diffTime = Math.abs(date2 - date1);
    let diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    if (date2.getTime() < date1.getTime()) {
      return -diffDay
    }
    return diffDay;
  }

  let diffDays = fDiffDays(new Date, date)
  if (diffDays <= 0) {
    return 'color: red;'
  }
  if (diffDays <= 3) {
    return 'color: #9a5b0d;'
  }
  return ''
}

Vue.prototype.$getStrTasks = function (tasks) {
  if (tasks && tasks.length > 0) {
    let strTasks = ''
    for(let taskKey in tasks) {
      strTasks = `${strTasks} ${Number(taskKey) + 1}.${tasks[taskKey].value}. `
    }
    return strTasks
  }
  return 'Нет задач'
}

Vue.prototype.$sliceStr = function (str, count) {
  var sliced = str.slice(0, count);
  if (sliced.length < str.length) {
    sliced += '...';
  }
  return sliced
}

Vue.prototype.$numWord = function (value, words){  
	value = Math.abs(value) % 100; 
	var num = value % 10;
	if(value > 10 && value < 20) return words[2]; 
	if(num > 1 && num < 5) return words[1];
	if(num == 1) return words[0]; 
	return words[2];
}

// text field rules
function isURL(str) {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  let isHttp = url.protocol === "http:" || url.protocol === "https:"
  let noSpace = !str.includes(' ')
  return noSpace && isHttp
}

Vue.prototype.$fieldRules =  {
  field: [
    v => (v === 0 || !!v) || 'Надо что то написать',
  ],
	cost: [
    v => (v === 0 || !!v) || 'Укажите',
  ],
  select: [
    v => (!v || v.length > 0) || 'Надо выбрать',
  ],
  date: [
    v => (v === 0 || !!v) || 'Надо указать',
  ],
  phonenumber: [
    v => (!!v && v.length === 17) || 'Номер не коректен',
  ],
  email: [
    v => !!v || 'E-mail обязателен',
    v => /.+@.+\..+/.test(v) || 'E-mail не валиден',
  ],
  isUrl: [
    v => v.length === 0 || isURL(v) || "URL не валидный"
  ]
}
Vue.prototype.$ifHourPayWorkMasters = ((task, user) => {
  let date = task.date.toDate()
  let dayWeek = date.getDay()
  let dayOff = dayWeek === 0 || dayWeek === 6
  let rateOnMontage = task.rateOnMontage !== undefined ? Number(task.rateOnMontage || 0) : Number(user.rateOnMontage || 0)
  let rateOnManufactory = task.rateOnManufactory !== undefined ? Number(task.rateOnManufactory || 0) : Number(user.rateOnManufactory || 0)
  let rateOverwork = task.rateOverwork !== undefined ? Number(task.rateOverwork || 0) : Number(user.rateOverwork || 0)

  let obj = {
    numberOfHoursOnMontage: Number(task.numberOfHoursOnMontage || 0),
    numberOfHoursOnManufactory: Number(task.numberOfHoursOnManufactory || 0),
    allHour: 0,
    overHour: 0,
    cost: 0
  }

  obj.allHour = obj.numberOfHoursOnMontage + obj.numberOfHoursOnManufactory
  if (dayOff) {
    obj.overHour = obj.allHour
  } else {
    obj.overHour = obj.allHour > 9 ? obj.allHour - 9 : 0
  }

  if (obj.overHour > 0) {
    obj.cost = obj.cost + (rateOverwork * obj.overHour)
  }

  if (!dayOff && obj.numberOfHoursOnMontage > 0) {
    let numberOfHoursOnMontage =  obj.numberOfHoursOnMontage
    if (obj.numberOfHoursOnManufactory < obj.overHour) {
      numberOfHoursOnMontage = numberOfHoursOnMontage - obj.overHour
    }
    obj.cost = obj.cost + (rateOnMontage * numberOfHoursOnMontage)
  }
  
  if (!dayOff && obj.numberOfHoursOnManufactory > 0) {
    let numberOfHoursOnManufactory = obj.numberOfHoursOnManufactory
    if (obj.overHour > 0 && obj.numberOfHoursOnManufactory > obj.overHour) {
      numberOfHoursOnManufactory = numberOfHoursOnManufactory - obj.overHour
    }
    obj.cost = obj.cost + (rateOnManufactory * numberOfHoursOnManufactory)
  }

  return obj
})

let app
auth.onAuthStateChanged(async (user) => {
  if (user) {
    await AddUserToStore(user)
		await setFcmToken(user)
  }
  app = new Vue({
    router,
    store,
    vuetify,
    render: function (h) { return h(App) }
  }).$mount('#app')
})
