<template>
	<v-container>
		<TextEditor
			class="viewMaterial"
			:value="material.material"
			:readonly="true"
		/>
	</v-container>
</template>

<script>
import TextEditor from '@/components/Faq/Pages/components/TextEditor/Index.vue'
export default {
	components: {
		TextEditor
	},
	props: ['material']
}
</script>

<style lang="scss">
.viewMaterial {
	.el-tiptap-editor__content {
		padding: 0px 3px !important;
		border: none;
	}
	.el-tiptap-editor {
		&__content {
			padding: 0;
			border: none;
		}

		&__menu-bubble {
			display: none !important;
		}
  }
}
</style>