var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageAllMontages",class:{ 'pa-3': !_vm.isMobile }},[_c('v-card',{attrs:{"tile":_vm.isMobile}},[_c('div',{staticClass:"d-flex align-center px-4"},[_c('v-row',{staticClass:"page_filterTable"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"dense":"","color":"#e0a677","label":"Месяц","clearable":""},on:{"click":function($event){return _vm.selectMonth()}},model:{value:(_vm.filterOrdersByMonthText),callback:function ($$v) {_vm.filterOrdersByMonthText=$$v},expression:"filterOrdersByMonthText"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"dense":"","color":"#e0a677","label":"Номер заказа"},on:{"change":function($event){return _vm.$store.dispatch('orders/loadOrders')}},model:{value:(_vm.filterOrdersByNumberOrder),callback:function ($$v) {_vm.filterOrdersByNumberOrder=$$v},expression:"filterOrdersByNumberOrder"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"dense":"","color":"#e0a677","label":"Клиент","items":_vm.clients,"item-text":"name","return-object":"","clearable":""},on:{"change":function($event){return _vm.$store.dispatch('orders/loadOrders')}},model:{value:(_vm.filterOrdersByClient),callback:function ($$v) {_vm.filterOrdersByClient=$$v},expression:"filterOrdersByClient"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"dense":"","items":_vm.currentOrderStatusArr,"label":"Статус","multiple":"","color":"#e0a677","item-color":"#e0a677","clearable":""},on:{"change":function($event){return _vm.$store.dispatch('orders/loadOrders')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 1)?_c('span',[_c('span',[_vm._v(_vm._s(item.text))]),(_vm.filterOrdersByStatus && index < _vm.filterOrdersByStatus.length - 1)?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()]):_vm._e(),(index === 1)?_c('span',[_vm._v(" (+"+_vm._s(_vm.filterOrdersByStatus.length - 1)+") ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"#e0a677","ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.filterOrdersByStatus),callback:function ($$v) {_vm.filterOrdersByStatus=$$v},expression:"filterOrdersByStatus"}})],1),(_vm.currentUser && _vm.currentUser.role != 'manager')?_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"dense":"","color":"#e0a677","label":"Менеджер","items":_vm.managers,"item-text":"displayName","return-object":"","clearable":""},on:{"change":function($event){return _vm.$store.dispatch('orders/loadOrders')}},model:{value:(_vm.filterOrdersByManager),callback:function ($$v) {_vm.filterOrdersByManager=$$v},expression:"filterOrdersByManager"}})],1):_vm._e()],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.createOrder()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-divider'),(!_vm.ordersLoaded && (!_vm.orders || _vm.orders.length === 0))?_c('v-skeleton-loader',{staticClass:"pl-3 pr-3",attrs:{"type":"table-thead, table-row-divider@3","types":_vm.skeletonTypesIframe}}):_vm._e(),(_vm.ordersLoaded && (!_vm.orders || _vm.orders.length === 0))?_c('div',{staticClass:"pa-3 d-flex justify-center"},[_vm._v(" Нет заказов ")]):_vm._e(),(_vm.orders && _vm.orders.length > 0)?_c('v-data-table',{attrs:{"footer-props":{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				},"headers":_vm.tableHeadres,"items":_vm.orders,"items-per-page":30,"dense":!_vm.isMobile},scopedSlots:_vm._u([{key:"item",fn:function(ref){
				var item = ref.item;
return [_c('tr',{style:(item.color && !_vm.disabledColorBtn(item) ?
								 ("background-color: " + (item.color)) : ''),on:{"click":function($event){return _vm.editOrder(item)}}},[_c('td',[(item.client)?_c('span',[_vm._v(" "+_vm._s(item.client.name)+" ")]):_c('span',[_vm._v("Не указан")])]),_c('td',[_vm._v(" "+_vm._s(item.number)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.currentOrderStatus[item.status])+" ")]),_c('td',[_c('span',{style:(("" + (_vm.$colorDeadline(item.deadline.toDate()))))},[_vm._v(" "+_vm._s(_vm.$formatDateTime(item.deadline.toDate()))+" ")])]),_c('td',[(item.description)?_c('span',[_vm._v(_vm._s(_vm.$sliceStr(item.description, 60)))]):_c('span',[_vm._v("Не задано")])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.fullCost(item))+"р.")])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.debt(item))+"р.")])]),(_vm.currentUser.role === 'admin')?_c('td',[_vm._v(" "+_vm._s(item.creator.displayName)+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								 var on = ref.on;
								 var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabledColorBtn(item)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-color-fill")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white"}},[_c('div',{staticClass:"pt-2 px-5"},[(item.color)?_c('v-btn',{attrs:{"color":item.color || null,"block":""},on:{"click":function($event){return _vm.setColorItem('', item)}}},[_vm._v(" Сбросить цвет ")]):_vm._e()],1),_c('v-color-picker',{staticClass:"rl-table-color-picker",attrs:{"value":item.color || null,"hide-canvas":"","hide-inputs":"","show-swatches":"","swatches-max-height":"230","mode":"hexa"},on:{"input":function($event){return _vm.setColorItem($event, item)}}})],1)])],1)])]}}],null,false,1432921788)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }