import firebase from "firebase/app";
import 'firebase/firebase-messaging'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: process.env.VUE_APP_FB_APIKEY,
    authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
    projectId: process.env.VUE_APP_FB_PROJECTID,
    storageBucket: process.env.VUE_APP_FB_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FB_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_FB_APPID
}

const fb = firebase.initializeApp(firebaseConfig)
const db = fb.firestore()
const auth = fb.auth()
const func = fb.functions("europe-west2")
const storage = fb.storage()
const fcm = firebase.messaging.isSupported() ? fb.messaging() : null

const metaInfoCollection = db.collection('metaInfo')
const montagesCollection = db.collection('montages')
const usersCollection = db.collection('users')
const brigsCollection = db.collection('brigs')
const locationsCollection = db.collection('locations')
const clientsCollection = db.collection('clients')
const manufactoryCollection = db.collection('manufactory')
const prepaymentsCollection = db.collection('prepayments')
const deductionsCollection = db.collection('deductions')
const premiumsCollection = db.collection('premiums')
const hourlyWorksCollection = db.collection('hourlyWorks')
const tasksCollection = db.collection('tasks')
const ordersCollection = db.collection('orders')
const faqCategoriesCollection = db.collection('faq-categories')
const faqMaterialsCollection = db.collection('faq-materials')
const fcmTokensCollection = db.collection('fcm-tokens')
const notificationsCollection = db.collection('notifications')
const companyActivitysCollection = db.collection('company-activitys')
const companyWhereFromCollection = db.collection('company-where-from')


const sendNotification = async (to, from, data) => {
	let date = new Date()
	let toRef = usersCollection.doc(to)
	let fromRef = from ? usersCollection.doc(from) : null

	notificationsCollection.add({
		to: toRef,
		from: fromRef,
		title: data.title ? data.title : null,
		text: data.text,
		action: data.action ? data.action : null,
		payload: data.payload ? data.payload : null,
		accepted: false,
		showAlert: true,
		date: date
	})
}

const deleteFcmToken = async () => {
	const uid = localStorage.getItem('uid')
	const token = localStorage.getItem('fcmToken')

	if (uid && token) {
		let fcmTokens = []

		let getFCMTokens = await fcmTokensCollection.doc(uid).get()
		if (getFCMTokens) {
			let dataFCMTokens = getFCMTokens.data()
			if (dataFCMTokens && dataFCMTokens.tokens) {
				fcmTokens = [ ...dataFCMTokens.tokens ]
			}
		}

		let indexFcmToken = fcmTokens.findIndex(x => x === token)
		fcmTokens.splice(indexFcmToken, 1)

		await fcmTokensCollection.doc(uid).set({
			tokens: [ ...fcmTokens ]
		})

		localStorage.removeItem('fcmToken')
		localStorage.removeItem('uid')
	}
}

const setFcmToken = async (user) => {
	if (user && fcm) {
		const uid = user.uid
		let requestPermission = await Notification.requestPermission()
		if (requestPermission === "granted") {
			fcm.getToken().then(async (token) => {
				let fcmTokens = []

				let getFCMToken = await fcmTokensCollection.doc(uid).get()
				if (getFCMToken) {
					let dataFCMToken = getFCMToken.data()
					if (dataFCMToken && dataFCMToken.tokens) {
						fcmTokens = [ ...dataFCMToken.tokens ]
					}
				}

				if (!fcmTokens.includes(token)) {
					await fcmTokensCollection.doc(uid).set({
						tokens: [ ...fcmTokens, token ]
					})
				}
				localStorage.setItem('fcmToken', token)
				localStorage.setItem('uid', uid)
			})
		} else {
			console.log("Unable to get permission to notify.");
		}

		fcm.onMessage((payload) => {
			console.log(`Новое уведомление: \n ${payload.notification.title} \n ${payload.notification.body}`)
			navigator.serviceWorker.register('firebase-messaging-sw.js');

			if (requestPermission === 'granted') {
				navigator.serviceWorker.ready.then(function(registration) {
					const data = { ...payload.notification, ...payload.data }
					const notificationTitle = data.title
					const notificationOptions = {
						body: data.body,
						// click_action: data.click_action,
						requireInteraction: true,
					};
					if (!document || document && document.hidden) {
						return registration.showNotification(
							notificationTitle,
							notificationOptions
						);
					}
					// return registration.showNotification(payload.notification.title, payload.notification).then(() => {}registration.getNotifications())
					// .then(notifications => {
					// 	console.log(notifications)
					// 	alert('Ткнули по нотифу')
					// })
				}).catch(function(error) {
						console.log('ServiceWorker registration failed', error)
				})
			}
		})
	}
	return false
}

var now = new Date();
var millisTill10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 7, 50, 0, 0) - now;
if (millisTill10 < 0) {
    millisTill10 += 86400000;
}
setTimeout(() => {
    location.reload()
}, millisTill10)

export {
    fb,
    db,
    auth,
    func,
    storage,
    fcm,
    metaInfoCollection,
    montagesCollection,
    usersCollection,
    brigsCollection,
    locationsCollection,
    clientsCollection,
    manufactoryCollection,
    prepaymentsCollection,
    deductionsCollection,
    premiumsCollection,
    hourlyWorksCollection,
    tasksCollection,
		ordersCollection,
		faqCategoriesCollection,
		faqMaterialsCollection,
		notificationsCollection,
		companyActivitysCollection,
		companyWhereFromCollection,
		setFcmToken,
		deleteFcmToken,
		sendNotification
}
