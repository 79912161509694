<template>
  <div v-if="user">
    <MobPage v-if="isMobile" />
    <PcPage v-else />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
const MobPage = () => import('./MobPage.vue')
const PcPage = () => import('./PcPage.vue')

export default {
  name: 'User',
  data: () => ({
    isMobile: isMobile
  }),
  components: {
    MobPage,
    PcPage
  },
  created() {
    // if (!this.user || (this.user && this.currentUser.role === 'admin') && (!this.users || this.users.length === 0)) {
    //   this.$store.dispatch('users/loadUsers')
		// 	this.loadData()
    // }
		this.$store.dispatch('users/loadUsers')
		this.loadData()
  },
	async beforeRouteLeave(to, from, next) {
    this.setDefVal()
    next()
  },
  methods: {
    loadData() {
			this.$store.dispatch('orders/loadOrdersForManager', { uid: this.uid, month: this.filterUserDataByMonth })

			this.$store.dispatch('montages/loadMontagesForMontager', { uid: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('manufactory/loadTasksForMaster', { uid: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('hourlyWorks/loadTasks', { uid: this.uid, month: this.filterUserDataByMonth })

      this.$store.dispatch('prepayments/loadPrepayments', { uid: this.uid, month: this.filterUserDataByMonth })
      this.$store.dispatch('deductions/loadDeductions', { uid: this.uid, month: this.filterUserDataByMonth })
      this.$store.dispatch('premiums/loadPremiums', { uid: this.uid, month: this.filterUserDataByMonth })
    },
		setDefVal() {
			this.$store.commit('orders/setOrdersForManager', [])
			this.$store.commit('montages/setMontagesForMontager', [])
			this.$store.commit('manufactory/setTasksForMaster', [])
			this.$store.commit('hourlyWorks/setTasks', [])

			this.$store.commit('prepayments/setPrepayments', [])
			this.$store.commit('deductions/setDeductions', [])
			this.$store.commit('premiums/setPremiums', [])
    }
  },
  computed: {
    filterUserDataByMonth() {
      return this.$store.getters['users/getFilterUserDataByMonth']
    },
    uid() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser.uid
      }
      return this.$route.params.uid
    },
    user() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser
      }
      return this.$store.getters['users/getUser'](this.uid)
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    currentUser() {
      return this.$store.getters['user/getUser']
    }
  },
  watch: {
    filterUserDataByMonth(newVal, oldVal) {
      this.loadData()
    }
  }
}
</script>
