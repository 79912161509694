<template>
	<v-container class="pr-1">
		<v-card style="min-height: 4em;" class="d-flex align-center justify-center">
			<div style="width: 100%">
				<template v-if="currentUser.role === 'admin'">
					<v-card-title class="py-2 pl-3 pr-2">
						<v-list-item-title style="max-width: calc(100% - 130px);">
							<span v-if="selectedCategory">{{ selectedCategory.title }}</span>
							<span v-else >Категории</span>
						</v-list-item-title>
						<v-spacer />
						<v-btn
							class="mr-1"
							small icon
							:disabled="!selectedCategory"
							@click="closeCategory()"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn> 
						<v-btn
							class="mr-1"
							small icon
							:disabled="!selectedCategory || isEditOrder"
							@click="editCategory()"
						>
							<v-icon size="20">mdi-pencil</v-icon>
						</v-btn>

						<v-btn
							v-if="!isEditOrder"
							:disabled="selectedCategory && !selectedCategory.categories"
							class="mr-1"
							small icon
							@click="editOrderCategory()"
						>
							<v-icon size="22">mdi-sort</v-icon>
						</v-btn>
						<v-btn
							v-else
							class="mr-1"
							small icon
							color="green"
							@click="saveOrderCategory()"
						>
							<v-icon size="22">mdi-check</v-icon>
						</v-btn>

						<v-menu bottom left>
							<template v-slot:activator="{ on, attrs }">
								<v-btn small icon v-bind="attrs" v-on="on">
									<v-icon size="22">mdi-dots-vertical</v-icon>
								</v-btn>
							</template>

							<v-list>
								<v-list-item @click="addCategory()" :disabled="isEditOrder">
									<v-list-item-icon>
										<v-icon :disabled="isEditOrder">mdi-plus</v-icon>
									</v-list-item-icon>
									<v-list-item-title>Новая категория</v-list-item-title>
								</v-list-item>
								<v-list-item @click="deleteCategory()" :disabled="!selectedCategory || isEditOrder">
									<v-list-item-icon>
										<v-icon 
											:disabled="!selectedCategory || isEditOrder"
											color="red"
										>mdi-trash-can-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-title>Удалить</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						
					</v-card-title>
					<v-divider />
				</template>
				<template v-if="items && items.length > 0">
					<v-list
						v-show="!isEditOrder"
						:key="redrowItems"
						style="border-radius: inherit;"
					>
						<ItemMenu 
							v-for="item in items"
							:key="`${item.id}_${redrowItems}`"
							:item="item"
							:selectedCategory="selectedCategory"
							:depth="0"
							@selectCategory="selectCategory($event, $event)"
						/>
					</v-list>
					<draggable
						v-if="isEditOrder"
						v-bind="dragOptions"
						v-model="orderList"
						class="py-2"
					>
						<transition-group type="transition" name="flip-list">
							<v-list-item
								class="menuDraggableItem"
								v-for="item in orderList"
								:key="item.id"
							>
								<v-list-item-content>
									<v-list-item-title>{{ item.title }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-icon class="my-3">
									<v-icon>mdi-drag</v-icon>
								</v-list-item-icon>
							</v-list-item>
						</transition-group>
					</draggable>
				</template>
				<div
					v-else
					style="color: grey; font-size: 1.2em;"
					class="d-flex justify-center py-3"
				>
					<span v-if="loadItems">Загрузка...</span>
					<span v-else>Нет элементов</span>
				</div>
			</div>
		</v-card>
	</v-container>
</template>

<script>
import { faqCategoriesCollection } from '@/plugins/firebase'
import ItemMenu from './ItemMenu.vue'
import ModalCategory from './modals/Category.vue'
import draggable from 'vuedraggable'
import Confirm from  '@/components/Modals/Confirm'

export default {
	components: {
		ItemMenu,
		draggable
	},
	data: () => ({
		loadItems: true,
		categorys: {},
		items: [],
		selectedCategory: null,
		redrowItems: 0,
		isEditOrder: false,
    orderList: [],
		drag: false
  }),
	async created() {
		await this.loadMenuItems()
	},
	computed: {
		dragOptions() {
      return {
        animation: 200,
        disabled: !this.isEditOrder,
      }
    },
		currentUser() {
			return this.$store.getters['user/getUser']
		},
	},
	methods: {
		async loadMenuItems() {
			let queryStore = faqCategoriesCollection.where('del', '==', false)
			if (this.currentUser && this.currentUser.role != 'admin') {
				queryStore = queryStore.where('available', 'array-contains', this.currentUser.role)
			}
			let res = await queryStore.get()

			let items = []
			for(let ref of res.docs) {
				let doc = await this.getItemMenu(ref, [])
				if (doc) {
					items.push(doc)
				}
			}
			items = await this.sortItemsMenu(items)
			this.items = items
			this.loadItems = false
		},
		async setCategory(item, obj, newItem = null) {
			let queryStore = faqCategoriesCollection
			if (item && item.path) {
				for(let index_id in item.path) {
					let length = item.path.length - 1
					queryStore = queryStore.doc(item.path[index_id])
					if (length > index_id || newItem) {
						queryStore = queryStore.collection('categories')
					}
				}
				if (newItem) {
					await queryStore.doc(newItem.id).set({
						...newItem.data,
						del: false
					})
				} else {
					await queryStore.update(obj)
				}
				return true
			} else if (!item && newItem) {
				await queryStore.doc(newItem.id).set({
					...newItem.data,
					del: false
				})
				return true
			}
			return false
		},
		async saveOrderCategory() {
			this.isEditOrder = false
			if (this.selectedCategory && this.selectedCategory.categories) {
				this.selectedCategory.categories = this.orderList
			} else {
				this.items = this.orderList
			}
			for (let index_item in this.orderList) {
				let item = this.orderList[index_item]
				let obj = { order: index_item }
				await this.setCategory(item, obj)
			}
			this.orderList = []
		},
		editOrderCategory() {
			this.isEditOrder = true
			if (this.selectedCategory && this.selectedCategory.categories) {
				this.orderList = this.selectedCategory.categories
			} else {
				this.orderList = this.items
			}
		},
		async deleteCategory() {
			const resConfirm = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление категории`,
          text: `Действительно хотите удалить категорию "${this.selectedCategory.title}"?`
        }
      })

      if (resConfirm) {
				this.$store.commit('templ/setLoading', true)
				await this.setCategory(this.selectedCategory, { del: true })
				await this.loadMenuItems()
				this.$store.commit('templ/setLoading', false)
      }
		},
		async addCategory() {
			let res = await this.$showModal({
        component: ModalCategory,
        isPersistent: false,
        props: {
          edit: false,
					data: { available: this.selectedCategory ? this.selectedCategory.available : null }
        }
      })
			if (res) {
				let id = String(Date.now())
				let order = 0

				if (this.selectedCategory && this.selectedCategory.categories) {
					order = this.selectedCategory.categories.length // в order хранятся индексы а length на 1 больше
				} else if (!this.selectedCategory) {
					order = this.items.length // в order хранятся индексы а length на 1 больше
				}

				let obj = {
					...res,
					order: order
				}
				if (this.selectedCategory) {
					if (this.selectedCategory.categories) {
						this.selectedCategory.categories.push({
							...obj,
							id: id
						})
					} else {
						this.selectedCategory.categories = [{
							...obj,
							id: id
						}]
					}
				} else {
					this.items.push({
						...obj,
						id: id
					})
				}
				await this.setCategory(this.selectedCategory, res, {
					id: id,
					data: obj
				})
			}
		},
		async editCategory() {
			let res = await this.$showModal({
        component: ModalCategory,
        isPersistent: false,
        props: {
          edit: true,
					data: this.selectedCategory
        }
      })
			if (res) {
				for (let key in res) {
					this.selectedCategory[key] = res[key]
				}
				this.$emit('selectCategory', { ...this.selectedCategory, ...res })
				await this.setCategory(this.selectedCategory, res)
			}
		},
		closeCategory() {
			this.orderList = []
			this.isEditOrder = false
			this.selectedCategory = null
			this.$emit('selectCategory', null)
			this.redrowItems += 1
			this.items = this.items.map((item) => {
				return { ...item, active: false }
			})
		},
		selectCategory(item) {
			this.selectedCategory = item
			this.$emit('selectCategory', item)
		},
		async getCategoriesItemMenu(categoriesId = []) {
			let queryStore = faqCategoriesCollection
			for(let id of categoriesId) {
				queryStore = queryStore.doc(id).collection('categories')
			}
			queryStore = queryStore.where('del', '==', false)
			if (this.currentUser && this.currentUser.role != 'admin') {
				queryStore = queryStore.where('available', 'array-contains', this.currentUser.role)
			}
			let res = await queryStore.get()
			let items = []
			for(let ref of res.docs) {
				let doc = await this.getItemMenu(ref, [ ...categoriesId ])
				if (doc) {
					items.push(doc)
				}
			}
			items = await this.sortItemsMenu(items)
			return items.length > 0 ? items : null
		},
		async getItemMenu(ref, categoriesId = []) {
			if (!categoriesId.includes(ref.id)) {
				let categories = await this.getCategoriesItemMenu([ ...categoriesId, ref.id ])
				let doc = {
					...ref.data(),
					id: ref.id,
					categories: categories,
					active: false,
					path: [ ...categoriesId, ref.id ]
				}
				this.categorys[ref.id] = categories
				return doc
			}
			return null
		},
		async sortItemsMenu(items) {
			let sortedItems = await items.sort(function(a,b){
				return a.order - b.order;
			})
			return sortedItems
		}
	}
}
</script>

<style lang="scss">
.menuDraggableItem {
	padding-left: 12px !important;
	padding: 0 12px !important;

	&:hover {
		cursor: grab;
		background-color: rgba(114, 114, 114, 0.39);
	}

	.v-list-item__title {
		color: #473f3f;
	}
}
</style>
