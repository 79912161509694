<template>
	<v-list-item
		class="itemDropListNotif"
		:class="{ 'itemDropListNotif--noAccepted': !item.accepted }"
		:three-line="item.text.length > 49"
		@click="$openModalFromNotif(item)"
	>
		<v-list-item-content>
			<v-list-item-title v-text="item.title" />
			<v-list-item-subtitle v-text="item.text" />
		</v-list-item-content>

		<v-list-item-action>
			<v-list-item-action-text v-text="getLocalDate(item.date)" />

			<v-btn
				icon 
				style="right: -8px;" color="grey"
				@click.stop="$store.dispatch('notifications/acceptedNotification', item.id)"
			>
				<v-icon>
					mdi-hand-okay
				</v-icon>
			</v-btn>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
export default {
	props: ['item'],
	created() {
		console.log(this.item)
	},
	methods: {
		getLocalDate(date) {
			return new Date(date).toLocaleString('ru', {
				day: 'numeric',
				month: 'short',
				hour: 'numeric',
				minute: 'numeric'
			})
		}
	}
}
</script>

<style lang="scss">
// .itemDropListNotif {
// 	transition: background-color 800ms linear;

// 	&--noAccepted {
// 		background-color: #6696ad67;
// 	}
// }
</style>