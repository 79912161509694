import {
	tasksCollection,
	usersCollection,
	clientsCollection,
	sendNotification
} from '@/plugins/firebase'

import loadedTasks from './loaded'
import filterTasks from './filter'

const snapshots = {
  loadArchiveTasks: null,
  loadAllTasks: null
}

export default {
  namespaced: true,
  state: {
		allTasks: [],
		archiveTasks: [],
		// loaded
    ...loadedTasks.state,
		// filters
    ...filterTasks.state
	},
  getters: {
		getAllTasks: state => {
			let tasks = state.allTasks
			let search = state.filterTasksByNumberOrder ? state.filterTasksByNumberOrder.toLowerCase() : null

			if (search) {
				tasks = tasks.filter(x => {
					if (x.numberOrder) {
							let numberOrder = x.numberOrder.toLowerCase()
							if (numberOrder.includes(search)) { return true }
					}
					return false
				})
			}

      const sortedTasks = tasks.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedTasks
    },
		getArchiveTasks: state => {
			let tasks = state.archiveTasks
			let search = state.filterArchiveTasksByNumberOrder ? state.filterArchiveTasksByNumberOrder.toLowerCase() : null

			if (search) {
				tasks = tasks.filter(x => {
					if (x.numberOrder) {
							let numberOrder = x.numberOrder.toLowerCase()
							if (numberOrder.includes(search)) { return true }
					}
					return false
				})
			}

      const sortedTasks = tasks.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedTasks
    },
		// loaded
    ...loadedTasks.getters,
		// filters
    ...filterTasks.getters,
	},
  mutations: {
		setTasks(state, payload) {
      state.allTasks = payload
    },
		setArchiveTasks(state, payload) {
      state.archiveTasks = payload
    },
		// loaded
    ...loadedTasks.mutations,
		// filters
    ...filterTasks.mutations,
	},
  actions: {
		async loadTaskById({ commit, dispatch, getters }, id) {
      commit('templ/setLoading', true, { root: true })

      let queryStore = tasksCollection.doc(id)
			let doc = await queryStore.get()
			doc = { ...doc.data(), id: doc.id }

			commit('templ/setLoading', false, { root: true })
			return doc
    },
    async updateTask({ commit, dispatch, rootGetters }, obj) {
      commit('templ/setLoading', true, { root: true })
			let user = rootGetters['user/getUser']
			console.log(obj)
      let doc = tasksCollection.doc(obj.id)
			await doc.update(obj.data)

      let updatedTask = await dispatch('loadTaskById', obj.id)

			console.log(updatedTask)

			// отправляем уведомление
			if (updatedTask && !updatedTask.archive && !obj.data.color) {
				let whoTo = [...updatedTask.whoTo]
				if (!whoTo.includes(updatedTask.from.id)) {
					whoTo = [ ...whoTo, updatedTask.from.id ]
				}
				let indexIID = whoTo.findIndex(x => x === user.uid)
				if (indexIID != -1) {
					whoTo.splice(indexIID, 1)
				}

				let title = `${user.displayName} обновил(а) задачу ${updatedTask.title}`
				let text = 'Проверьте изменения'

				if (obj.data.ready != undefined) {
					if (obj.data.print && obj.data.ready === false) {
						text = `Статус: в печать.`
					} else {
						text = `Статус: ${obj.data.ready ? 'готово' : 'в работе'}.`
					}
				}

				if (obj.data.description != undefined) {
					text = `Изменилось описание`
				}

				if (whoTo && whoTo.length > 0) {
					for (let toUid of whoTo) {
						await sendNotification(toUid, user.uid, {
							title: title,
							text: text,
							action: 'updateTask',
							payload: {
								taskId: obj.id
							}
						})
					}
				}
			}
      commit('templ/setLoading', false, { root: true })
    },
		async loadArchiveTasks({ commit, dispatch, getters, rootGetters }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadArchiveTasks) {
        snapshots.loadArchiveTasks()
        snapshots.loadArchiveTasks = null
      }

			let user = rootGetters['user/getUser']

			let queryStore = tasksCollection
			let queryStore2 = null

			let filterByMonth = getters.getFilterArchiveTasksByMonth
			if (filterByMonth) {
        let date = new Date(filterByMonth), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)

        queryStore = queryStore.orderBy("deadline").startAt(firstDay).endAt(lastDay)
      }

			queryStore = queryStore.where('del', '==', false).where('archive', '==', true)

			if (user.role === 'designer') {
				queryStore = queryStore.where('whoTo', 'array-contains', user.uid)
			} else {
				let filterByTypeTask = getters.getFilterArchiveTasksByTypeTask
				if (filterByTypeTask) {
					if (filterByTypeTask.value === 'me') {
						queryStore = queryStore.where('whoTo', 'array-contains', user.uid)
					}
					if (filterByTypeTask.value === 'fromMe') {
						queryStore = queryStore.where('design', '==', false).where('meTask', '==', false)
						if (user.role != 'admin') {
							let managerRef = usersCollection.doc(user.uid)
							queryStore = queryStore.where('from', '==', managerRef)
						}
					}
					if (filterByTypeTask.value === 'designer') {
						queryStore = queryStore.where('design', '==', true)
						if (user.role != 'admin') {
							let managerRef = usersCollection.doc(user.uid)
							queryStore = queryStore.where('from', '==', managerRef)
						}
						let filterByDesigner = getters.getFilterArchiveTasksByDesigner
						if (filterByDesigner && filterByDesigner.uid) {
							queryStore = queryStore.where('whoTo', 'array-contains', filterByDesigner.uid)
						}
					}
				} else if (user.role != 'admin') {
					let managerRef = usersCollection.doc(user.uid)
					queryStore2 = queryStore.where('whoTo', 'array-contains', user.uid)
					queryStore = queryStore.where('from', '==', managerRef)
				}
			}

			let filterByManager = getters.getFilterArchiveTasksByManager
			if (filterByManager && filterByManager.uid) {
				let managerRef = usersCollection.doc(filterByManager.uid)
				queryStore = queryStore.where('from', '==', managerRef)
			}

			let filterByClient = getters.getFilterArchiveTasksByClient
      if (filterByClient && filterByClient.id) {
        let clientRef = clientsCollection.doc(filterByClient.id)
        queryStore = queryStore.where('client', '==', clientRef)
      }

      snapshots.loadArchiveTasks = queryStore.onSnapshot(async snapshot => {
        let tasksArr = []

        for (let doc of snapshot.docs) {
          let task = { ...doc.data(), id: doc.id }
          tasksArr.push(task)
        }

				if (queryStore2) {
					queryStore2.get().then(async snapshot2 => {
						for (let doc2 of snapshot2.docs) {
							let checkTask = tasksArr.findIndex(x => x.id === doc2.id)
							if (checkTask === -1) {
								let task2 = { ...doc2.data(), id: doc2.id }
								tasksArr.push(task2)
							}
						}
					})
				}
      
        commit('setArchiveTasks', tasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
		async loadAllTasks({ commit, dispatch, getters, rootGetters }, currentTasks) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadAllTasks) {
        snapshots.loadAllTasks()
        snapshots.loadAllTasks = null
      }

			let user = rootGetters['user/getUser']

			let queryStore = tasksCollection

			let filterByMonth = getters.getFilterTasksByMonth
			if (filterByMonth) {
        let date = new Date(filterByMonth), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)

        queryStore = queryStore.orderBy("deadline").startAt(firstDay).endAt(lastDay)
      }

			queryStore = queryStore.where('del', '==', false).where('archive', '==', false)

			let filterByStatus = getters.getFilterTasksByStatus
      if (filterByStatus) {
        queryStore = queryStore.where('ready', '==', filterByStatus === 'ready' ? true : false)
      }

			let filterByClient = getters.getFilterTasksByClient
			if (filterByClient && filterByClient.id) {
				let clientRef = clientsCollection.doc(filterByClient.id)
				queryStore = queryStore.where('client', '==', clientRef)
			}

			let filterByManager = getters.getFilterTasksByManager

			if (user.role === 'designer') {
				queryStore = queryStore.where('whoTo', 'array-contains', user.uid)
			} else {
				if (currentTasks === 'TasksDesigner') {
					queryStore = queryStore.where('design', '==', true)

					if (filterByManager && filterByManager.uid) {
						let managerRef = usersCollection.doc(filterByManager.uid)
						queryStore = queryStore.where('from', '==', managerRef)
					}
	
					let filterByDesigner = getters.getFilterTasksByDesigner
					if (filterByDesigner && filterByDesigner.uid) {
						queryStore = queryStore.where('whoTo', 'array-contains', filterByDesigner.uid)
					}
				}
				
				if (currentTasks === 'TasksFromMe') {
					queryStore = queryStore.where('design', '==', false).where('meTask', '==', false)
					if (filterByManager && filterByManager.uid) {
						let managerRef = usersCollection.doc(filterByManager.uid)
						queryStore = queryStore.where('from', '==', managerRef)
					}
				}
	
				if (currentTasks === 'TasksMe') {
					if (filterByManager && filterByManager.uid) {
						queryStore = queryStore.where('whoTo', 'array-contains', filterByManager.uid)
					}
				}
			}

      snapshots.loadAllTasks = queryStore.onSnapshot(async snapshot => {
        let tasksArr = []

        for (let doc of snapshot.docs) {
          let task = { ...doc.data(), id: doc.id }
          tasksArr.push(task)
        }
      
        commit('setTasks', tasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
		async getNewNumberTask() {
			let toDay = new Date()
			let year = String(toDay.getFullYear()).substring(2)
			let month = toDay.getMonth() + 1

			if (month < 10) {
				month = `0${month}`
			}

			let date = new Date(), y = date.getFullYear(), m = date.getMonth()
			let firstDay = new Date(y, m, 1, 0)
			let lastDay = new Date(y, m + 1, 0, 23, 59)

			// console.log(`Дата по UTC: ${date}`)
			// console.log(`Первый день(${firstDay}), последний день(${lastDay})`)

			let numberInMonth = '01'
			let taskQueryStore = await tasksCollection.orderBy('date').startAt(firstDay).endAt(lastDay).get()
			let lengthList = taskQueryStore.docs
			lengthList = lengthList.length + 1

			if (lengthList < 10) {
				numberInMonth = `0${lengthList}`
			} else {
				numberInMonth = lengthList
			}

			return `${year}${month}${numberInMonth}`
		}
  }
}