import { prepaymentsCollection, usersCollection } from '@/plugins/firebase'

export default {
    namespaced: true,
    state: {
        prepayments: []
    },
    getters: {
        getPrepayments: state => {
            const sortedPrepayments = state.prepayments.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            })
            return sortedPrepayments
        }
    },
    mutations: {
        setPrepayments(state, payload) {
            state.prepayments = payload;
        }
    },
    actions: {
        async deletePrepayments({ commit, rootState }, data) {
            commit('templ/setLoading', true, { root: true })
            await prepaymentsCollection.doc(data.id).update({ del: true })
                .then(() => {
                    console.log('Выплата удалена')
                }).catch((err) => {
                    commit('templ/setError', 'Ошибка удаления выплаты, попробуйте позже.')
                    console.log(err)
                }).finally(() => {
                    commit('templ/setLoading', false, { root: true })
                })
        },
        async createPrepayments({ commit, dispatch, rootGetters }, data) {
            commit('templ/setLoading', true, { root: true })
            let filterDate = rootGetters['users/getFilterUserDataByMonth']
            let date = new Date(filterDate), y = date.getFullYear(), m = date.getMonth()
            filterDate = new Date(y, m + 1, 0, 23, 59)
            let toDay = new Date()

            if (filterDate.getMonth() != toDay.getMonth()) {
                date = new Date(filterDate.toISOString().substr(0, 10))
            } else {
                date = new Date(toDay.toISOString().substr(0, 10))
            }
            const obj = { 
                ...data,
                date: date
            }
            await prepaymentsCollection.add(obj)
            commit('templ/setLoading', false, { root: true })
        },
        async loadPrepayments({ commit, dispatch }, data) {
            commit('templ/setLoading', true, { root: true })

            let userRef = await usersCollection.doc(data.uid)

            let queryStore = prepaymentsCollection.where('user', '==', userRef)

            if (data.month) {
                let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
                let firstDay = new Date(y, m, 1, 0)
                let lastDay = new Date(y, m + 1, 0, 23, 59)
                queryStore = queryStore.orderBy('date').startAt(firstDay).endAt(lastDay);
            }

            queryStore.onSnapshot(async snapshot => {
                let prepaymentsArr = []
                for (let prepaymentDoc of snapshot.docs) {
                    let prepayment = { ...prepaymentDoc.data(), id: prepaymentDoc.id }
                    if (prepayment.user) {
                        let user = await dispatch('users/userSerializer', prepayment.user, { root: true })
                        prepayment.user = user
                    }
                    prepaymentsArr.push(prepayment)
                }
                prepaymentsArr = prepaymentsArr.filter(x => !x.del)
                
                commit('setPrepayments', prepaymentsArr)
                commit('templ/setLoading', false, { root: true })
            })
        },
    }
}