<template>
	<v-menu v-if="user" offset-y>
		<template v-slot:activator="scope">
			<v-btn text large v-bind="scope.attrs" v-on="scope.on" class="pa-1">
				<my-mini-avatar :user="user"/>
				<v-icon v-if="!scope.value" color="rgba(0,0,0,.54)">mdi-chevron-down</v-icon>
				<v-icon v-else color="rgba(0,0,0,.54)">mdi-chevron-up</v-icon>
			</v-btn>
		</template>

		<v-card max-width="300">
			<v-list dense>
				<v-list-item>
					<v-list-item-avatar>
						<my-mini-avatar :user="user"/>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title style="font-size: 1em;">
							{{ user.displayName }}
						</v-list-item-title>
						<v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider />
			<!-- Настройки аккаунта сотрудника -->
			<!-- <v-list dense class="pt-0" v-if="supportPush">
				<v-subheader style="height: 30px;">Настройки</v-subheader>
				<v-list-item-group v-model="userSettings" @change="changeSettings" multiple>
					<v-list-item>
						<template v-slot:default="{ active }">
							<v-list-item-action>
								<v-checkbox :input-value="active"></v-checkbox>
							</v-list-item-action>

							<v-list-item-content>
								<v-list-item-title>Push-уведомления</v-list-item-title>
							</v-list-item-content>
						</template>
					</v-list-item>
				</v-list-item-group>
			</v-list> -->
			<!-- <v-divider v-if="supportPush" /> -->
			<v-list dense>
				<v-list-item @click="logout">
					<v-list-item-title>Выход</v-list-item-title>
					<v-list-item-icon>
						<v-icon>mdi-logout</v-icon>
					</v-list-item-icon>
				</v-list-item>
			</v-list>
		</v-card>
	</v-menu>
</template>

<script>
import { deleteFcmToken } from '@/plugins/firebase'

export default {
	data: () => ({
		userSettings: []
	}),
	computed: {
		user() {
      return this.$store.getters['user/getUser']
    },
		supportPush() {
      return 'Notification' in window
    }
	},
	methods: {
		changeSettings(val) {
      console.log(val)
    },
    // onPush() {},
		async logout () {
			await deleteFcmToken()
      this.$auth.signOut().then(() => {
        this.$store.commit('user/setUser', null)
        this.$router.push({ path: '/login' })
        location.reload()
      })
    }
	}
}
</script>