import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../stores/store'
import { auth } from '../plugins/firebase'
import baseRoutes from './routes'
import { isMobile } from 'mobile-device-detect'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: baseRoutes
})

auth.onAuthStateChanged(async (stateUser) => {
  if (stateUser) {
    let unsubscribe = store.subscribe(async mutation => {
      if (mutation.type === 'user/setUser') {
        let user = mutation.payload
        let routes = await require(`./routesByRole/${user.role}.js`).default
        for (let route of routes) {
          await router.addRoute(route)
        }

        if (user.role === 'admin') {
          if (isMobile) {
            routes = await require(`./routesByRole/adminMob.js`).default
            for (let route of routes) {
              await router.addRoute(route)
            }
          } else {
            routes = await require(`./routesByRole/adminPc.js`).default
            for (let route of routes) {
              await router.addRoute(route)
            }
          }
        }

        // if (user.role === 'prodDir' && user.canAssignMontage) {
        //   routes = await require(`./routesByRole/prodDirMontages.js`).default
        //   for (let route of routes) {
        //     await router.addRoute(route)
        //   }
        // }

        let allRoutes = await router.getRoutes()
        store.commit('templ/setRoutes', allRoutes)
        unsubscribe()
      }
    })
  }
})

router.beforeEach((to, from, next) => {
  const user = store.getters['user/getUser']

  if (user && to.name === 'Login') {
    return next({ path: '/' });
  }

  if (!user && to.name != 'Login') {
    return next({ path: '/login', query: { returnUrl: to.path } })
  }

  next();
})

export default router
