import { 
	metaInfoCollection, manufactoryCollection,
	usersCollection, clientsCollection,
	sendNotification
} from '@/plugins/firebase'

import loadedTasks from './loaded'
import filterTasks from './filter'

const snapshots = {
  loadTasksForMaster: null,
  loadTasksProducedForMaster: null,
  loadTasksArchiveForMaster: null,
  loadTasksWorkForMaster: null,
  loadConfirmationTasks: null,
  loadManagerTasks: null,
  loadTasks: null,
  maxTaskPaginationCount: null,
  loadInProcessTasks: null
}

export default {
  namespaced: true,
  state: {
    // tasks
    tasks: [],
    confirmationTasks: [],
    managerTasks: [],
    tasksConfirmProd: [],
    tasksWorkForMaster: [],
    tasksProducedForMaster: [],
    tasksArchiveForMaster: [],
    tasksForMaster: [],
    inProcessTasks: [],
    // filters
    ...filterTasks.state,
    // loaded tasks
    ...loadedTasks.state,
    // pagination
    tasksPage: 1,
    tasksPrev: null,
    tasksNext: null,
    maxTaskCount: 0,
    // pagination user tasks
    tasksForMasterPage: 1,
    tasksForMasterLastPage: 1
  },
  getters: {
    // tasks
    getTasks: state => {
      const sortedTasks = state.tasks.sort(function(a,b){
        return b.date.toDate() - a.date.toDate()
      })
      return sortedTasks
    },
    getConfirmationTasks: state => {
      const sortedTasks = state.confirmationTasks.sort(function(a,b){
        return a.date.toDate() - b.date.toDate()
      })
      return sortedTasks
    },
    getManagerTasks: state => {
      const sortedTasks = state.managerTasks.sort(function(a,b){
        return a.date.toDate() - b.date.toDate()
      })
      return sortedTasks
    },
    getTasksWorkForMaster: state => {
      const sortedTasks = state.tasksWorkForMaster.sort(function(a,b){
        return a.date.toDate() - b.date.toDate()
      })
      return sortedTasks
    },
    getTasksProducedForMaster: state => {
      const sortedTasks = state.tasksProducedForMaster.sort(function(a,b){
        return a.date.toDate() - b.date.toDate()
      })
      return sortedTasks
    },
    getTasksArchiveForMaster: state => {
      const sortedTasks = state.tasksArchiveForMaster.sort(function(a,b){
        return a.date.toDate() - b.date.toDate()
      })
      return sortedTasks
    },
    getTasksForMaster: state => {
      const sortedTasks = state.tasksForMaster.sort(function(a,b){
        return b.date.toDate() - a.date.toDate()
      })
      return sortedTasks
    },
    getListTasksPageForMaster: (state, getters) => {
      let tasks = getters.getTasksForMaster
      let pageTasks = state.tasksForMasterPage

      let tasksPages = [];
      for (let i = 0; i < tasks.length; i += 10) {
        tasksPages.push(tasks.slice(i, i + 10));
      }

      let tasksArr = tasksPages[pageTasks - 1]

      return tasksArr ? tasksArr : []
    },
    getInProcessTasks: state => {
      const sortedTasks = state.inProcessTasks.sort(function(a,b){
        return a.date.toDate() - b.date.toDate()
      })
      return sortedTasks
    },
    // filters
    ...filterTasks.getters,
    // loaded tasks
	  ...loadedTasks.getters,
    // pagination archive tasks
    getTasksPage: state => { return state.tasksPage },
    getTasksPrev: state => { return state.tasksPrev },
    getTasksNext: state => { return state.tasksNext },
    getMaxTaskCount: state => { return state.maxTaskCount },
    // pagination user tasks
    getTasksForMasterPage: state => { return state.tasksForMasterPage },
    getTasksForMasterLastPage: state => { return state.tasksForMasterLastPage }
  },
  mutations: {
    // tasks
    setTasks(state, payload) {
      state.tasks = payload;
    },
    setInProcessTasks(state, payload) {
      state.inProcessTasks = payload
    },
    setConfirmationTasks(state, payload) {
      state.confirmationTasks = payload;
    },
    setManagerTasks(state, payload) {
      state.managerTasks = payload;
    },
    setTasksWorkForMaster(state, payload) {
      state.tasksWorkForMaster = payload;
    },
    setTasksProducedForMaster(state, payload) {
      state.tasksProducedForMaster = payload;
    },
    setTasksArchiveForMaster(state, payload) {
      state.tasksArchiveForMaster = payload;
    },
    setTasksForMaster(state, payload) {
      state.tasksForMaster = payload;
    },
    updTask(state, payload) {
      if (payload.doc && payload.doc.id && payload.updState) {
        const item = state[payload.updState].find(item => item.id === payload.doc.id)
        Object.assign(item, payload.doc)
      }
    },
    pushTask(state, payload) {
      if (payload.doc && payload.doc.id && payload.pushTo) {
        if (!state[payload.pushTo].find(item => item.id === payload.doc.id)) {
          state[payload.pushTo].push(payload.doc)
        }
      }
    },
    removeTask(state, payload) {
      if (payload.id && payload.removeFrom) {
        let index = state[payload.removeFrom].findIndex(item => item.id === payload.id)
        if (index != -1) {
          state[payload.removeFrom].splice(index, 1)
        }
      }
    },
    // filters
    ...filterTasks.mutations,
    // loaded tasks
    ...loadedTasks.mutations,
    // pagination archive tasks
    setTasksPaginationPage(state, payload) {
      state.tasksPage = payload
    },
    setTasksPagination(state, payload) {
      state[`tasks${payload.action}`] = payload.cursor
    },
    setMaxTaskPaginationCount(state, payload) {
      state.maxTaskCount = payload
    },
    // pagination user tasks
    setTasksForMasterPaginationPage(state, payload) {
      state.tasksForMasterPage = payload
    },
    setTasksForMasterPaginationLastPage(state, payload) {
      state.tasksForMasterLastPage = payload
    },
  },
  actions: {
		async loadTaskById({ commit, dispatch, getters }, id) {
      commit('templ/setLoading', true, { root: true })

      let queryStore = manufactoryCollection.doc(id)
			let doc = await queryStore.get()
			doc = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })

			commit('templ/setLoading', false, { root: true })
			return doc
    },
    async loadTasksForMaster({ commit, dispatch, getters }, data) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadTasksForMaster) {
        snapshots.loadTasksForMaster()
        snapshots.loadTasksForMaster = null
      }

      let queryStore = manufactoryCollection.where('status', 'in', ['archive', 'ready'])

      if (data.month) {
        let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)
        queryStore = queryStore.orderBy('readyDate').startAt(firstDay).endAt(lastDay);
      }

      if (data.uid) {
        queryStore = queryStore.where('montagers', 'array-contains', data.uid)
      }

      snapshots.loadTasksForMaster = await queryStore.onSnapshot(async snapshot => {
        let manufactoryTasksArr = []
        for (let doc of snapshot.docs) {
          let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
          manufactoryTasksArr.push(task)
        }
        manufactoryTasksArr = manufactoryTasksArr.filter(x => !x.del)

        let newTasksArr = [];
        for (let i = 0; i < manufactoryTasksArr.length; i += 10) {
          newTasksArr.push(manufactoryTasksArr.slice(i, i + 10));
        }
        commit('setTasksForMasterPaginationLastPage', newTasksArr.length)

        commit('setTasksForMasterLoaded', true)
        commit('setTasksForMaster', manufactoryTasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    loadTasksProducedForMaster({ commit, dispatch, getters }, data) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadTasksProducedForMaster) {
        snapshots.loadTasksProducedForMaster()
        snapshots.loadTasksProducedForMaster = null
      }

      let queryStore = manufactoryCollection.where('del', '!=', true).where('status', 'in', ['produced', 'ready'])

      if (data.uid) {
        queryStore = queryStore.where('montagers', 'array-contains', data.uid)
      }

      snapshots.loadTasksProducedForMaster = queryStore.onSnapshot(async snapshot => {
        let manufactoryTasksArr = []

        for (let doc of snapshot.docs) {
          let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
          manufactoryTasksArr.push(task)
        }

        commit('setTasksProducedForMasterLoaded', true)
        commit('setTasksProducedForMaster', manufactoryTasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    loadTasksArchiveForMaster({ commit, dispatch, getters }, data) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadTasksArchiveForMaster) {
        snapshots.loadTasksArchiveForMaster()
        snapshots.loadTasksArchiveForMaster = null
      }

      let queryStore = manufactoryCollection.where('del', '!=', true).where('status', '==', 'archive')

      if (data.uid) {
        queryStore = queryStore.where('montagers', 'array-contains', data.uid)
      }

      snapshots.loadTasksArchiveForMaster = queryStore.onSnapshot(async snapshot => {
        let manufactoryTasksArr = []

        for (let doc of snapshot.docs) {
          let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
          manufactoryTasksArr.push(task)
        }

        // commit('setTasksArchiveForMasterLoaded', true)
        commit('setTasksArchiveForMaster', manufactoryTasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    loadTasksWorkForMaster({ commit, dispatch, getters }, data) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadTasksWorkForMaster) {
        snapshots.loadTasksWorkForMaster()
        snapshots.loadTasksWorkForMaster = null
      }

      let queryStore = manufactoryCollection.where('del', '!=', true).where('status', '==', 'work')

      if (data.uid) {
        queryStore = queryStore.where('montagers', 'array-contains', data.uid)
      }

      snapshots.loadTasksWorkForMaster = queryStore.onSnapshot(async snapshot => {
        let manufactoryTasksArr = []

        for (let doc of snapshot.docs) {
          let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
          manufactoryTasksArr.push(task)
        }

        commit('setTasksWorkForMasterLoaded', true)
        commit('setTasksWorkForMaster', manufactoryTasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    loadInProcessTasks({ commit, getters, dispatch }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadInProcessTasks) {
        snapshots.loadInProcessTasks()
        snapshots.loadInProcessTasks = null
      }

			const statusArr = ['confirmation', 'confirmationProd', 'work', 'produced', 'ready']
      let queryStore = manufactoryCollection.where('del', '!=', true)
				.where('status', 'in', statusArr)

      let filterByClient = getters.getFilterWorkTasksByClient
      if (filterByClient && filterByClient.id) {
        let clientRef = clientsCollection.doc(filterByClient.id)
        queryStore = queryStore.where('client', '==', clientRef)
      }
      
      let filterByManager = getters.getFilterWorkTasksByManager
      if (filterByManager && filterByManager.uid) {
        let managerRef = usersCollection.doc(filterByManager.uid)
        queryStore = queryStore.where('manager', '==', managerRef)
      }
      
      let filterByDate = getters.getFilterWorkTasksByDate
      if (filterByDate) {
        queryStore = queryStore.where('date', '==', filterByDate)
      }

      snapshots.loadInProcessTasks = queryStore.onSnapshot(async snapshot => {
        let manufactoryTasksArr = []

        for (let doc of snapshot.docs) {
          let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
          manufactoryTasksArr.push(task)
        }

        commit('setInProcessTasksLoaded', true)
        commit('setInProcessTasks', manufactoryTasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    loadConfirmationTasks({ commit, dispatch }, obj) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadConfirmationTasks) {
        snapshots.loadConfirmationTasks()
        snapshots.loadConfirmationTasks = null
      }

      let queryStore = manufactoryCollection.where('del', '!=', true).where('status', '==', 'confirmation')

      if (obj && obj.manager) {
        let userRef = usersCollection.doc(obj.manager)
        queryStore = queryStore.where('manager', '==', userRef)
      }

      snapshots.loadConfirmationTasks = queryStore.onSnapshot(async snapshot => {
        let manufactoryTasksArr = []

        for (let doc of snapshot.docs) {
          let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
          manufactoryTasksArr.push(task)
        }

        commit('setConfirmationTasksLoaded', true)
        commit('setConfirmationTasks', manufactoryTasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    loadManagerTasks({ commit, dispatch, getters }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadManagerTasks) {
        snapshots.loadManagerTasks()
        snapshots.loadManagerTasks = null
      }

      let manager = this.getters['user/getUser']
      let managerRef = usersCollection.doc(manager.uid)
      let queryStore = manufactoryCollection.where('del', '!=', true)
      queryStore = queryStore.where('manager', '==', managerRef)
      queryStore = queryStore.where('status', 'in', ['confirmation', 'confirmationProd', 'work', 'produced', 'ready'])
      snapshots.loadManagerTasks = queryStore.onSnapshot(async snapshot => {
        let manufactoryTasksArr = []

        for (let doc of snapshot.docs) {
          let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
          manufactoryTasksArr.push(task)
        }

        commit('setManagerTasksLoaded', true)
        commit('setManagerTasks', manufactoryTasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    loadTasks({ commit, dispatch, getters }, pagination) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadTasks) {
        snapshots.loadTasks()
        snapshots.loadTasks = null
      }
      if (snapshots.maxTaskPaginationCount) {
        snapshots.maxTaskPaginationCount()
        snapshots.maxTaskPaginationCount = null
      }

      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate()+1)
      let toDay = new Date(tomorrow.toISOString().substr(0, 10))

      let queryStore = manufactoryCollection

      let filterByDate = getters.getFilterTasksByDate
      if (filterByDate) {
        queryStore = queryStore.where('date', '==', filterByDate)
      } else {
        queryStore = queryStore.where('date', '<=', toDay).orderBy("date", "desc")
      }

      queryStore = queryStore.where('del', '==', false).where('status', '==', 'archive')

      let filterByClient = getters.getFilterTasksByClient
      if (filterByClient && filterByClient.id) {
        let clientRef = clientsCollection.doc(filterByClient.id)
        queryStore = queryStore.where('client', '==', clientRef)
      }
      
      let filterByManager = getters.getFilterTasksByManager
      if (filterByManager && filterByManager.uid) {
        let managerRef = usersCollection.doc(filterByManager.uid)
        queryStore = queryStore.where('manager', '==', managerRef)
      }

      if (pagination) {
        let page = getters.getTasksPage
        let prev = getters.getTasksPrev
        let next = getters.getTasksNext

        if (pagination === 'prev' && prev) {
          commit('setTasksPaginationPage', page - 1)
          queryStore = queryStore.endBefore(prev).limitToLast(20)
        }
        if (pagination === 'next' && next) {
          commit('setTasksPaginationPage', page + 1)
          queryStore = queryStore.startAfter(next).limit(20)
        }
      } else {
        commit('setTasksPaginationPage', 1)
        queryStore = queryStore.limit(20)
      }

      snapshots.loadTasks = queryStore.onSnapshot(async snapshot => {
        // pagination
        if (snapshot.docs.length > 0 && snapshot.docs[0]) {
          commit('setTasksPagination', { action: 'Prev', cursor: snapshot.docs[0] })
        }
        if (snapshot.docs.length === 20 && snapshot.docs[snapshot.docs.length-1]) {
          commit('setTasksPagination', { action: 'Next', cursor: snapshot.docs[snapshot.docs.length-1] })
        }

        let manufactoryTasksArr = []

        for (let doc of snapshot.docs) {
          let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
          manufactoryTasksArr.push(task)
        }

        commit('setTasksLoaded', true)
        commit('setTasks', manufactoryTasksArr)
        commit('templ/setLoading', false, { root: true })
      })

      snapshots.maxTaskPaginationCount = metaInfoCollection.doc('manufactory').onSnapshot(async snapshot => {
        let metaInfo = snapshot.data()
        commit('setMaxTaskPaginationCount', metaInfo.countDocArchive)
      })
    },
    async createTask({ commit, rootGetters, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
			let user = rootGetters['user/getUser']
      const obj = { ...data, del: false }

			if (!obj.numberOrder) {
				let number = await dispatch('getNewNumberManufactory')
				obj.numberOrder = `c${number}`
			}

			// client
      if (obj.client && obj.client.id) {
        obj.clientData = obj.client
				obj.client = clientsCollection.doc(obj.client.id)
      }

      // manager
      if (obj.manager) {
        let manager = await obj.manager.get()
        obj.managerData = { ...manager.data() }
      }

      let newTask = await manufactoryCollection.add(obj)

			if (newTask && newTask.id) {
				let obj = {
					text: 'Создал(а) этот заказ.',
					files: null,
					date: new Date,
					from: usersCollection.doc(user.uid),
					program: true
				}
	
				await manufactoryCollection.doc(newTask.id).collection('comments').add(obj)
			}

			// отправка уведомления Начальнику производства
			if (data.status === 'confirmationProd') {
				let prodDirs = rootGetters['users/getProdDirs']
				if (prodDirs && prodDirs.length > 0) {
					let date = obj.date
					date = date.toLocaleString('ru', {day: 'numeric', month: 'short' })
					let title = `Производство на ${date}`
					let text = `Для ${obj.clientData.name}`
					for (let prodDirId of prodDirs) {
						await sendNotification(prodDirId.uid, user.uid, {
							title: title,
							text: text,
							action: `confirmProdManufactoryTask`,
							payload: {
								taskId: newTask.id
							}
						})
					}
				}
			}
      commit('templ/setLoading', false, { root: true })
    },
    async updateTask({ commit, dispatch, rootGetters }, obj) {
      commit('templ/setLoading', true, { root: true })
			console.log(obj)
			let user = rootGetters['user/getUser']
			let managerTask = obj.manager || null
			let task = obj.task || null

      let doc = manufactoryCollection.doc(obj.id)

      let data = obj.data

      // client
      if (data.client && data.client.id) {
        data.clientData = data.client
				data.client = clientsCollection.doc(data.client.id)
      }

      // manager
      if (data.manager) {
        let manager = await data.manager.get()
        data.managerData = { ...manager.data() }
      }

      await doc.update(data)

      if (obj.removeFrom) {
        commit('removeTask', { id: obj.id, removeFrom: obj.removeFrom })
      }

      if (obj.pushTo || obj.updState) {
        doc = await doc.get()
        doc = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })

        if (obj.pushTo) {
          commit('pushTask', { doc: doc, pushTo: obj.pushTo })
        }

        if (obj.updState) {
          commit('updTask', { doc: doc, updState: obj.updState })
        }
      }

			// отправка уведомления Начальнику производства
			if (data.status === 'confirmationProd') {
				let prodDirs = rootGetters['users/getProdDirs']
				if (prodDirs && prodDirs.length > 0) {
					let date = data.date
					date = date.toLocaleString('ru', {day: 'numeric', month: 'short' })
					let title = `Производство на ${date}`
					let text = `Для ${data.clientData.name}`
					for (let prodDirId of prodDirs) {
						await sendNotification(prodDirId.uid, user.uid, {
							title: title,
							text: text,
							action: `confirmProdManufactoryTask`,
							payload: {
								taskId: obj.id
							}
						})
					}
				}
			}

			// отправка уведомления Менеджеру если вернули на доработку
			if (data.status === 'confirmation') {
				let date = data.date
				date = date.toLocaleString('ru', {day: 'numeric', month: 'short' })
				let title = `Производство на доработку`
				let text = `Для ${data.clientData.name}`
				await sendNotification(managerTask.id || managerTask.uid, user.uid, {
					title: title,
					text: text,
					action: `confirmProdManufactoryTask`,
					payload: {
						taskId: obj.id
					}
				})
			}
			
			// отправка уведомлений Мастерам когда на них назначили
			if (data.status === 'work') {
				let date = data.date
				date = date.toLocaleString('ru', {day: 'numeric', month: 'short' })
				let title = `Новое производство на ${date}`
				let text = `Для ${data.clientData.name}`
				for (let masterId of data.montagers) {
					await sendNotification(masterId, user.uid, {
						title: title,
						text: text,
						action: `workManufactoryTask`,
						payload: {
							taskId: obj.id
						}
					})
				}
			}

			// отправка уведомлений Менеджеру когда задание готово
			if (data.status === 'ready' && task) {
				let title = `Задание для ${task.client.name} `
				let text = `Статус: готво`
				await sendNotification(managerTask.id || managerTask.uid, user.uid, {
					title: title,
					text: text,
					action: `readyManufactoryTask`,
					payload: {
						taskId: obj.id
					}
				})
			}
      commit('templ/setLoading', false, { root: true })
    },
    async deleteTask({ commit, rootState }, data) {
      commit('templ/setLoading', true, { root: true })
      await manufactoryCollection.doc(data.id).update({ del: true })
        .then(async () => {
            console.log('Задание удалено')
        }).catch((err) => {
            commit('templ/setError', 'Ошибка удаления задания, попробуйте позже.')
            console.log(err)
        }).finally(() => {
            commit('templ/setLoading', false, { root: true })
        })
    },
    async taskSerializer({ state, dispatch }, ref) {
      let task = {}
      if (ref.id) {
        task = ref
      } else {
        let res = await ref.get()
        task = { ...res.data(), id: res.id }
      }

      if (task.managerData) {
        task = { ...task, manager: task.managerData }
        delete task.managerData
      }

      if (task.clientData) {
        task = { ...task, client: task.clientData }
        delete task.clientData
      }

      // if (task.montagers && task.montagers.length > 0) {
      //   let montagers = []
      //   for(let uid of task.montagers) {
      //     let ref = await usersCollection.doc(uid)
      //     let montager = await dispatch('users/userSerializer', ref, { root: true })
      //     montagers.push(montager)
      //   }
      //   task = { ...task, montagersLocalInfo: montagers }
      // }

      return task
    },
		async getNewNumberManufactory() {
			let toDay = new Date()
			let year = String(toDay.getFullYear()).substring(2)
			let month = toDay.getMonth() + 1

			if (month < 10) {
				month = `0${month}`
			}

			let date = new Date(), y = date.getFullYear(), m = date.getMonth()
			let firstDay = new Date(y, m, 1, 0)
			let lastDay = new Date(y, m + 1, 0, 23, 59)

			// console.log(`Дата по UTC: ${date}`)
			// console.log(`Первый день(${firstDay}), последний день(${lastDay})`)

			let numberInMonth = '01'
			let QueryStore = await manufactoryCollection.orderBy('date').startAt(firstDay).endAt(lastDay).get()
			let lengthList = QueryStore.docs
			lengthList = lengthList.length + 1

			if (lengthList < 10) {
				numberInMonth = `0${lengthList}`
			} else {
				numberInMonth = lengthList
			}

			return `${year}${month}${numberInMonth}`
		}
  }
}