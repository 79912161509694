import { tasksCollection, usersCollection, sendNotification } from '@/plugins/firebase'

const snapshots = {
  loadTasks: null,
  loadDesignTasks: null,
}

export default {
  namespaced: true,
  state: {
    tasks: [],
    designTasks: []
  },
  getters: {
    getTasks: state => {
      const sortedTasks = state.tasks.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedTasks
    },
    getDesignTasks: state => {
      const sortedTasks = state.designTasks.sort(function(a,b){
        return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedTasks
    },
  },
  mutations: {
    setTasks(state, payload) {
      state.tasks = payload
    },
    setDesignTasks(state, payload) {
      state.designTasks = payload
    }
  },
  actions: {
    async loadDesignTasks({ commit, rootState }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadDesignTasks) {
        snapshots.loadDesignTasks()
        snapshots.loadDesignTasks = null
      }

      let user = rootState.user.user
      let fromRef = await usersCollection.doc(user.uid)

      let queryStore = tasksCollection.where('del', '!=', true)
        .where('archive', '==', false).where('from', '==', fromRef)
        .where('meTask', '==', false).where('design', '==', true)

      snapshots.loadDesignTasks = queryStore.onSnapshot(async snapshot => {
        let tasksArr = []

        for (let doc of snapshot.docs) {
          let task = { ...doc.data(), id: doc.id }
          tasksArr.push(task)
        }
      
        commit('setDesignTasks', tasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    async loadTasks({ commit, rootState }) {
      commit('templ/setLoading', true, { root: true })

      if (snapshots.loadTasks) {
        snapshots.loadTasks()
        snapshots.loadTasks = null
      }

      let user = rootState.user.user
      let fromRef = await usersCollection.doc(user.uid)

      let queryStore = tasksCollection.where('del', '!=', true)
        .where('archive', '==', false).where('from', '==', fromRef)
        .where('meTask', '==', false).where('design', '==', false)

      snapshots.loadTasks = queryStore.onSnapshot(async snapshot => {
        let tasksArr = []

        for (let doc of snapshot.docs) {
          let task = { ...doc.data(), id: doc.id }
          tasksArr.push(task)
        }
      
        commit('setTasks', tasksArr)
        commit('templ/setLoading', false, { root: true })
      })
    },
    async createTask({ commit, rootState, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
      let user = rootState.user.user
      let fromRef = await usersCollection.doc(user.uid)

      const obj = { 
        ...data,
        from: fromRef,
        // accepted: false,
        accepted: true,
        archive: false,
        del: false,
        meTask: false,
        ready: false,
				date: new Date(),
      }

			if (!obj.client) {
				obj.client = null
			}

			if (!obj.numberOrder) {
				let number = await dispatch('tasks/getNewNumberTask', null, { root: true })
				obj.numberOrder = `z${number}`
			}

      let newTask = await tasksCollection.add(obj)

			if (newTask && newTask.id) {
				let obj = {
					text: 'Создал(а) эту задачу.',
					files: null,
					date: new Date,
					from: usersCollection.doc(user.uid),
					program: true
				}
	
				await tasksCollection.doc(newTask.id).collection('comments').add(obj)
			}

			// отправляем уведомление
			if (obj.whoTo && obj.whoTo.length > 0) {
				for (let toUid of obj.whoTo) {
					await sendNotification(toUid, obj.from.id, {
						title: `Задача от ${user.displayName}`,
						text: obj.title,
						action: 'newTask',
						payload: {
							taskId: newTask.id
						}
					})
				}
			}

      commit('templ/setLoading', false, { root: true })
    }
  }
}